import { createAction, props } from "@ngrx/store";
import { OrderResponse, OrderCreateRequest } from "src/app/model/Order";

export const cleanOrders = createAction("[Orders] Clean Orders");

export const getOrders = createAction(
  '[ORDERS] Get Orders',
);
export const getOrdersSuccess = createAction(
  '[ORDERS] Get Orders Success',
  props<{orders: OrderResponse}>()
);
export const getOrdersFailed = createAction(
  '[ORDERS] Get Orders Failed',
  props<{error: Error}>()
);

export const toUpdateOrder = createAction(
  '[ORDERS] To Update Order',
  props<{orderId: string}>()
)

export const getOrdersByOrderStatus = createAction(
  '[ORDERS] Get Orders By Order Status',
  props<{orderStatusId: string | undefined}>()
);
export const getOrdersByOrderStatusSuccess = createAction(
  '[ORDERS] Get Orders By Order Status Success',
  props<{orders: OrderResponse}>()
);
export const getOrdersByOrderStatusFailed = createAction(
  '[ORDERS] Get Orders By Order Status Failed',
  props<{error: Error}>()
);

export const getOrdersByPage = createAction(
  '[ORDERS] Get Orders By Page',
  props<{page: number}>()
);
export const getOrdersByPageSuccess = createAction(
  '[ORDERS] Get Orders By Page Success',
  props<{orders: OrderResponse}>()
);
export const getOrdersByPageFailed = createAction(
  '[ORDERS] Get Orders By Page Failed',
  props<{error: Error}>()
);

export const getOrdersByProductCategory = createAction(
  '[ORDERS] Get Orders By Product Category',
  props<{productCategory: string | undefined}>()
);
export const getOrdersByProductCategorySuccess = createAction(
  '[ORDERS] Get Orders By Product Category Success',
  props<{orders: OrderResponse}>()
);
export const getOrdersByProductCategoryFailed = createAction(
  '[ORDERS] Get Orders By Product Category Failed',
  props<{error: Error}>()
);


export const createOrder = createAction(
  '[ORDERS] Create Order',
  props<{order: OrderCreateRequest, quantity: number}>()
);
export const createOrderSuccess = createAction(
  '[ORDERS] Create Order Success'
);
export const createOrderFailed = createAction(
  '[ORDERS] Create Order Failed',
  props<{error: Error}>()
);

export const updateOrderPaid = createAction(
  '[ORDERS] Update Order Paid',
  props<{orderId: string}>()
);
export const updateOrderPaidSuccess = createAction(
  '[ORDERS] Update Order Paid Success',
  props<{orderId: string}>()
);
export const updateOrderPaidFailed = createAction(
  '[ORDERS] Update Order Paid Failed',
  props<{error: Error}>()
);

export const updateOrderStatus = createAction(
  '[ORDERS] Update Order Status',
  props<{order_ids: string[], statusId: string}>()
);
export const updateOrderStatusSuccess = createAction(
  '[ORDERS] Update Order Status Success',
  props<{order_ids: string[], statusId: string}>()
);
export const updateOrderStatusFailed = createAction(
  '[ORDERS] Update Order Status Failed',
  props<{error: Error}>()
);

export const updateOrderWaitressComment = createAction(
  '[ORDERS] Update Order Waitress Comment',
  props<{orderId: string, waitressComment: string}>()
);
export const updateOrderWaitressCommentSuccess = createAction(
  '[ORDERS] Update Order Waitress Comment Success',
  props<{orderId: string, waitressComment: string}>()
);
export const updateOrderWaitressCommentFailed = createAction(
  '[ORDERS] Update Order Waitress Comment Failed',
  props<{error: Error}>()
);