import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionCardsComponent } from './subscription-cards.component';
import { CardModule } from 'src/app/components/ui-elements/card/card.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubscriptionCardsComponent],
  imports: [CommonModule, CardModule, TranslateModule],
  exports: [SubscriptionCardsComponent],
})
export class SubscriptionCardsModule {}
