<h5>STILE MENU</h5>
<h6>Seleziona i tuoi stili</h6>

<div class="font-container" [(fontPicker)]="font" [fpStyleSelect]="false" [fpSizeSelect]="false" [fpWidth]="'auto'" [fpPosition]="'bottom'" (fontPickerChange)="chooseFont($event, 'title')">
  <div class="font-item">
    <p>Titolo</p>
    <p> - </p>
    <p>{{editingMenu?.font_title ?? titleFont}}</p>
  </div>
</div>

<div class="font-container" [(fontPicker)]="font" [fpStyleSelect]="false" [fpSizeSelect]="false" [fpWidth]="'auto'" [fpPosition]="'bottom'" (fontPickerChange)="chooseFont($event, 'category')">
  <div class="font-item">
    <p>Categoria</p>
    <p> - </p>
    <p>{{editingMenu?.font_category ?? categoryFont}}</p>
  </div>
</div>

<div class="font-container" [(fontPicker)]="font" [fpStyleSelect]="false" [fpSizeSelect]="false" [fpWidth]="'auto'" [fpPosition]="'bottom'" (fontPickerChange)="chooseFont($event, 'price')">
  <div class="font-item">
    <p>Prezzo</p>
    <p> - </p>
    <p>{{editingMenu?.font_price ?? priceFont}}</p>
  </div>
</div>

<div class="font-container" [(fontPicker)]="font" [fpStyleSelect]="false" [fpSizeSelect]="false" [fpWidth]="'auto'" [fpPosition]="'bottom'" (fontPickerChange)="chooseFont($event, 'product')">
  <div class="font-item">
    <p>Prodotto</p>
    <p> - </p>
    <p>{{editingMenu?.font_product ?? productFont}}</p>
  </div>
</div>

<div class="font-container" [(fontPicker)]="font" [fpStyleSelect]="false" [fpSizeSelect]="false" [fpWidth]="'auto'" [fpPosition]="'bottom'" (fontPickerChange)="chooseFont($event, 'ingredients')">
  <div class="font-item">
    <p>Ingredienti</p>
    <p> - </p>
    <p>{{editingMenu?.font_ingredient ?? ingredientsFont}}</p>
  </div>
</div>
