import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap, map, of, withLatestFrom, forkJoin } from 'rxjs';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { environment } from 'src/environments/environment';
import { AppState } from '..';
import { OrderActions, SystemActions } from '../actions';
import { OrdersService } from '../../providers/services/orders.service';

@Injectable()
export class OrdersEffects {
  constructor(private actions$: Actions, private ordersService: OrdersService, private store$: Store<AppState>) {}

  getOrdersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrders),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.ordersService
            .getOrders(storeState.restaurantStore.restaurant.id, storeState.orderStore.currentPage, storeState.orderStore.searchByOrderStatus, undefined)
            .pipe(
              map((orders) => OrderActions.getOrdersSuccess({ orders: {totalOrders: orders.totalOrders, orders: orders.orders.map(o => { return { ...o, selected: false}})} })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[getOrdersEffect$ - getOrdersEffect] error: ${JSON.stringify(err)}`);
                }
                return of(OrderActions.getOrdersFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(OrderActions.getOrdersFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getOrderSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'orders-get-success' }));
      })
    )
  );

  getOrderFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return OrderActions.getOrdersSuccess({ orders: { orders: [], totalOrders: 0 } });
        }
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'orders-get-failed' }));
      })
    )
  );

  getOrdersByPageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByPage),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.ordersService
            .getOrders(
              storeState.restaurantStore.restaurant.id,
              storeState.orderStore.currentPage,
              storeState.orderStore.searchByOrderStatus,
              undefined
            )
            .pipe(
              map((orders) => OrderActions.getOrdersByPageSuccess({ orders })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[getOrdersByPageEffect$ - getOrdersByPageEffect] error: ${JSON.stringify(err)}`);
                }
                return of(OrderActions.getOrdersByPageFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(OrderActions.getOrdersByPageFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getOrderByPageSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByPageSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'orders-get-by-page-success' }));
      })
    )
  );

  getOrderByPageFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByPageFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return OrderActions.getOrdersSuccess({ orders: { orders: [], totalOrders: 0 } });
        }
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'orders-get-by-page-failed' }));
      })
    )
  );

  getOrdersByOrderCategoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByProductCategory),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.ordersService
            .getOrders(
              storeState.restaurantStore.restaurant.id,
              storeState.orderStore.currentPage,
              storeState.orderStore.searchByOrderStatus,
              storeState.orderStore.searchByProductCategory
            )
            .pipe(
              map((orders) => OrderActions.getOrdersByProductCategorySuccess({ orders })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[getOrdersByOrderCategoryEffect$ - getOrdersByOrderCategoryEffect] error: ${JSON.stringify(err)}`);
                }
                return of(OrderActions.getOrdersByProductCategoryFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(OrderActions.getOrdersByProductCategoryFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getOrderByCategorySuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByProductCategorySuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'orders-get-by-product-category-success' }));
      })
    )
  );

  getOrderByCategoryFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByProductCategoryFailed),
      map(({ error }) => {
        // if (error.message === 'NOT_FOUND') {
          return OrderActions.getOrdersSuccess({ orders: { orders: [], totalOrders: 0 } });
        // }
        // return SystemActions.SetNotification(new SystemNotification({ error, message: 'orders-get-by-product-category-failed' }));
      })
    )
  );

  getOrdersByOrderStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByOrderStatus),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.ordersService
            .getOrders(
              storeState.restaurantStore.restaurant.id,
              storeState.orderStore.currentPage,
              storeState.orderStore.searchByOrderStatus,
              undefined
            )
            .pipe(
              map((orders) => OrderActions.getOrdersByOrderStatusSuccess({ orders })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[getOrdersByOrderStatusEffect$ - getOrdersByOrderStatusEffect] error: ${JSON.stringify(err)}`);
                }
                return of(OrderActions.getOrdersByOrderStatusFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(OrderActions.getOrdersByOrderStatusFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getOrderByOrderStatusSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByOrderStatusSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'orders-get-by-order-status-success' }));
      })
    )
  );

  getOrderByOrderStatusFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.getOrdersByOrderStatusFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return OrderActions.getOrdersSuccess({ orders: { orders: [], totalOrders: 0 } });
        }
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'orders-get-by-order-status-failed' }));
      })
    )
  );

  createOrderEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.createOrder),
      withLatestFrom(this.store$),
      switchMap(([{ order, quantity }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          const qty = Array.from(Array(quantity).keys());

          return forkJoin(
            qty.map(() => {
              return this.ordersService.createOrder(storeState.restaurantStore.restaurant!.id, order);
            })
          ).pipe(
            map(() => OrderActions.createOrderSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[createOrderEffect$ - createOrderEffect] error: ${JSON.stringify(err)}`);
              }
              return of(OrderActions.createOrderFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(OrderActions.createOrderFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  createOrderSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.createOrderSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'order-create-success' }));
      })
    )
  );

  createOrderFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.createOrderFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'order-create-failed' }));
      })
    )
  );

  updateOrderStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderStatus),
      withLatestFrom(this.store$),
      switchMap(([{ order_ids, statusId }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.ordersService
            .updateStatusOrders(storeState.restaurantStore.restaurant.id, order_ids, statusId)
            .pipe(
              map((r) => OrderActions.updateOrderStatusSuccess({ order_ids, statusId })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[updateOrderStatusEffect$ - updateOrderStatusEffect] error: ${JSON.stringify(err)}`);
                }
                return of(OrderActions.updateOrderStatusFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(OrderActions.updateOrderStatusFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  updateOrderStatusSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderStatusSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'order-update-status-success' }));
      })
    )
  );

  updateOrderStatusFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderStatusFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'order-update-status-failed' }));
      })
    )
  );

  updateOrderWaitressCommentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderWaitressComment),
      withLatestFrom(this.store$),
      switchMap(([{ orderId, waitressComment }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.ordersService
            .updateWaitressComment(storeState.restaurantStore.restaurant.id, orderId, waitressComment)
            .pipe(
              map((r) => OrderActions.updateOrderWaitressCommentSuccess({ orderId, waitressComment })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[updateOrderWaitressCommentEffect$ - updateOrderWaitressCommentEffect] error: ${JSON.stringify(err)}`);
                }
                return of(OrderActions.updateOrderWaitressCommentFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(OrderActions.updateOrderWaitressCommentFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  updateOrderWaitressCommentSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderWaitressCommentSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'order-update-waitress-comment-success' }));
      })
    )
  );

  updateOrderWaitressCommentFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderWaitressCommentFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'order-update-waitress-comment-failed' }));
      })
    )
  );

  updateOrderPaidEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderPaid),
      withLatestFrom(this.store$),
      switchMap(([{ orderId }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.ordersService.payOrder(storeState.restaurantStore.restaurant.id, orderId).pipe(
            map((r) => OrderActions.updateOrderPaidSuccess({ orderId })),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[updateOrderPaidEffect$ - updateOrderPaidEffect] error: ${JSON.stringify(err)}`);
              }
              return of(OrderActions.updateOrderPaidFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(OrderActions.updateOrderPaidFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  updateOrderPaidSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderPaidSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'order-update-waitress-comment-success' }));
      })
    )
  );

  updateOrderPaidFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.updateOrderWaitressCommentFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'order-update-waitress-comment-failed' }));
      })
    )
  );

  startLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.getOrders,
        OrderActions.createOrder,
        OrderActions.getOrdersByPage,
        OrderActions.getOrdersByProductCategory,
        OrderActions.updateOrderPaid,
        OrderActions.updateOrderStatus,
        OrderActions.updateOrderWaitressComment,
        OrderActions.getOrdersByOrderStatus
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.getOrdersSuccess,
        OrderActions.getOrdersFailed,
        OrderActions.createOrderSuccess,
        OrderActions.createOrderFailed,
        OrderActions.getOrdersByPageSuccess,
        OrderActions.getOrdersByPageFailed,
        OrderActions.getOrdersByProductCategorySuccess,
        OrderActions.getOrdersByProductCategoryFailed,
        OrderActions.updateOrderPaidSuccess,
        OrderActions.updateOrderPaidFailed,
        OrderActions.updateOrderStatusSuccess,
        OrderActions.updateOrderStatusFailed,
        OrderActions.updateOrderWaitressCommentSuccess,
        OrderActions.updateOrderWaitressCommentFailed,
        OrderActions.getOrdersByOrderStatusSuccess,
        OrderActions.getOrdersByOrderStatusFailed
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
