import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { RouterActions } from '../../router/store';
import { getToken } from '../store/auth.selectors';
import { AppState } from 'src/app/store';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AppState>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getToken),
      map(token => token === null || token === undefined ? false : true),
      tap(isLogged => {
        if (!isLogged) {
          this.store.dispatch(RouterActions.go({ path: ['partner-login'] }));
        }
      })
    );
  }
}
