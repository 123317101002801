import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguageModule } from './language.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FooterModule } from './components/footer/footer.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClickOutsideModule } from 'ng-click-outside';

import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AuthService } from './guard/services/auth.service';
import { AuthGuard } from './guard/services/auth.guard';
import { PlaformEnabledGuard } from './guard/services/plaformEnabled.guard';
import { AuthInterceptor } from './guard/services/auth.interceptor';
import { NavbarLightModule } from './components/navbar/navbar-light/navbar-light.module';
import { NotFoundModule } from './screens/static/not-found/not-found.module';
import { MaintenanceComponentModule } from './screens/static/maintenance/maintenance.module';
import { LpRestaurantModule } from './screens/static/lp-restaurant/lp-restaurant.module';
import { LpClientModule } from './screens/static/lp-client/lp-client.module';
import { PartnerLoginModule } from './screens/auth/partner-login/partner-login.module';
import { LoggedInGuard } from './guard/services/loggedIn.guard';
import { MenuBuilderModule } from './screens/menu-builder/menu-builder.module';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/app';
import { NotificationSnackbarModule } from './components/notification-snackbar/notification-snackbar.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import * as store from './store';
import { HeadersInterceptor } from './providers/interceptors/headers.interceptor';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  // Change this to your Google API key
  apiKey: environment.fontGoogleApiKey,
};

firebase.initializeApp({
  apiKey: environment._firebase_apiKey,
  authDomain: environment._firebase_authDomain,
  projectId: environment._firebase_projectId,
  storageBucket: environment._firebase_storageBucket,
  messagingSenderId: environment._firebase_messagingSenderId,
  appId: environment._firebase_appId,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    ClickOutsideModule,
    BrowserModule,
    AppRoutingModule,
    LanguageModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FooterModule,
    StoreModule.forRoot(store.reducers, { runtimeChecks: store.runtimeChecks
      , metaReducers: store.metaReducers
     }),
    StoreDevtoolsModule.instrument(store.devtoolsConfig),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    EffectsModule.forRoot(store.effects),
    NavbarLightModule,
    NotFoundModule,
    MaintenanceComponentModule,
    LpRestaurantModule,
    LpClientModule,
    PartnerLoginModule,
    MenuBuilderModule,
    NotificationSnackbarModule,
    MatProgressSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: FONT_PICKER_CONFIG,
      useValue: DEFAULT_FONT_PICKER_CONFIG,
    },
    AuthService,
    AuthGuard,
    LoggedInGuard,
    PlaformEnabledGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
