import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RestaurantState } from '../reducers/restaurants.reducers';

const selectRestaurantState = createFeatureSelector<RestaurantState>('restaurantStore');

export const isUserLoggedIn = createSelector(selectRestaurantState, (state: RestaurantState) => state.restaurant);

export const getRestaurantStage = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant === undefined ? 'undefined' : state.restaurant.stage_id
);

export const getRestaurantName = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.name !== undefined ? state.restaurant.name : 'NOT_DEFINED'
);

export const getRestaurantPhoneNumber = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.phone_number !== undefined ? state.restaurant?.phone_number : 'NOT_DEFINED'
);
export const getRestaurantEmail = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.email !== undefined ? state.restaurant?.email : ''
);
export const getRestaurantAdminName = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.admin_name !== undefined ? state.restaurant?.admin_name : 'NOT_DEFINED'
);

export const getRestaurantAddress = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.address !== undefined ? state.restaurant?.address : 'NOT_DEFINED'
);

export const getRestaurantCity = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.city !== undefined ? state.restaurant?.city : 'NOT_DEFINED'
);

export const getRestaurantType = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.type_id !== undefined ? state.restaurant.type_id : 'NOT_DEFINED'
);

export const getRestaurantId = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.id !== undefined ? state.restaurant?.id : 'NOT_DEFINED'
);
export const getRestaurantApprovedStatus = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined && state.restaurant.approved !== undefined ? state.restaurant?.approved : false
);
export const getRestaurantCurrencies = createSelector(selectRestaurantState, (state: RestaurantState) =>
  state.restaurant !== undefined ? state.restaurant.currency_ids : []
);
export const getMaxCustomersNumber  = createSelector(selectRestaurantState, (state: RestaurantState) =>
state.restaurant !== undefined && state.restaurant.max_customers_number !== undefined ? state.restaurant.max_customers_number : 0
);
export const selectRestaurantPin = createSelector(selectRestaurantState, (state: RestaurantState): string | undefined => state.restaurant?.pin);
export const selectRestaurantPayments = createSelector(selectRestaurantState, (state: RestaurantState): {sumup: boolean, satispay: boolean, classic: boolean} | undefined => state.restaurant?.payments_accepted ?? {sumup: false, satispay: false, classic: false});