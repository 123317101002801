import {ModuleWithProviders, NgModule} from '@angular/core';
import {ShowIfLoggedDirective} from './if-logged.directive';

@NgModule({
  declarations: [ShowIfLoggedDirective],
  exports: [ShowIfLoggedDirective],
})
export class DirectivesModule {
  static forRoot(): ModuleWithProviders<DirectivesModule> {
    return {
      ngModule: DirectivesModule,
      providers: []
    };
  }
}
