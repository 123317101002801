import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarUniteComponent } from './navbar-unite.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarResModule } from '../../navbar/navbar-res/navbar-res.module';
import { TopNavModule } from '../../navbar/top-nav/top-nav.module';

@NgModule({
  declarations: [NavbarUniteComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    TopNavModule,
    NavbarResModule
  ],
  exports: [NavbarUniteComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavbarUniteModule { }
