import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { GetProductsResponse, ProductRequest } from 'src/app/model/Product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  getProducts(restaurantId: string, limit: number, offset: number, menuId?: string): Observable<GetProductsResponse> {
    let params: HttpParams = new HttpParams().append('limit', limit).append('offset', offset);

    if (menuId) {
      params = params.append('menuid', menuId);
    }

    return this.http.get<GetProductsResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products`, { params });
  }

  createProduct(restaurantId: string, payload: ProductRequest): Observable<{ productid: string }> {
    return this.http.post<{ productid: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products`, payload);
  }

  updateProduct(restaurantId: string, productId: string, payload: ProductRequest): Observable<{ productid: string }> {
    return this.http.patch<{ productid: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products/${productId}`, payload);
  }

  deleteProduct(restaurantId: string, productId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products/${productId}`);
  }

  associateProduct(restaurantId: string, productId: string, payload: { menu_id: string }): Observable<{ productid: string }> {
    return this.http.post<{ productid: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products/${productId}/associate`, payload);
  }
}
