import { createAction, props } from "@ngrx/store";
import { SystemNotification } from "src/app/utils/system-notification.class";

export const Clean = createAction("[System] Clean");

export const StartLoading = createAction(
    '[System] Start loading'
);
export const StopLoading = createAction(
    '[System] Stop loading'
);
export const SetNotification = createAction(
    '[System] Set notification',
    props<SystemNotification>()
);
export const Redirect = createAction(
    '[System] Redirect to', props<{ url: string }>()
);

