import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {catchError, first, switchMap} from 'rxjs/operators';
import {getToken} from '../store/auth.selectors';
import { Observable, throwError } from 'rxjs';

import * as RouterActions from '../../router/store/router.actions';
import { AuthActions } from '../store';
import { AppState } from 'src/app/store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(getToken).pipe(
      // first(),
      // Interceptor requires observable that complete
      // BETTER than first: avoid errors if there is no value
      first(),
      switchMap(token => {
        const authReq = !!token ? req.clone({
          setHeaders: { Authorization: 'Bearer ' + token },
        }) : req;
        return next.handle(authReq)
          .pipe(
            catchError(err => {
              console.error('[INTERCEPT ERROR] '+JSON.stringify(err));
              if (err instanceof HttpErrorResponse) {

                switch (err.status) {
                  // case 401:
                  //   this.store.dispatch(AuthActions.refreshToken());
                  //   const authReqWithTokenRefreshed = req.clone({
                  //     setHeaders: { Authorization: 'Bearer ' + sessionStorage.getItem('accessToken') },
                  //   });
                  //   return next.handle(authReqWithTokenRefreshed)
                  case 401: {
                      this.store.dispatch(AuthActions.logout());
                      this.store.dispatch(RouterActions.go({ path: ['/partner-login']}));
                      break;
                    }
                  case 404: {
                    this.store.dispatch(AuthActions.logout());
                    this.store.dispatch(RouterActions.go({ path: ['/partner-login']}));
                    break;
                  }
                  default:
                    // TO-DO: Trigger banner with generic error
                    break;
                }
              }

             
              return throwError(() => err);
            }),
          );
      }),
    );
  }
}

