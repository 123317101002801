import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuBuilderComponent } from './menu-builder.component';
import { MenuBuilderRoutingModule } from './menu-builder-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuProductModule } from 'src/app/components/menu-builder/menu-product/menu-product.module';
import { MenuPreviewModule } from 'src/app/components/menu-builder/menu-preview/menu-preview.module';
import { MenuColorPickerModule } from 'src/app/components/menu-builder/menu-color-picker/menu-color-picker.module';
import { MenuFontPickerModule } from 'src/app/components/menu-builder/menu-font-picker/menu-font-picker.module';
import { MenuAdditionalInfoModule } from 'src/app/components/menu-builder/menu-additional-info/menu-additional-info.module';


@NgModule({
  declarations: [MenuBuilderComponent],
  imports: [
    CommonModule,
    MenuBuilderRoutingModule,
    TranslateModule,
    NgbModule,
    MenuProductModule,
    MenuPreviewModule,
    MenuColorPickerModule,
    MenuFontPickerModule,
    MenuAdditionalInfoModule,
  ],
  exports: [MenuBuilderComponent]
})
export class MenuBuilderModule { }
