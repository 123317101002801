import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance.component';
import { MaintenanceRoutingModule } from './maintenance-routing.module';

import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [MaintenanceComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaintenanceRoutingModule,
    TranslateModule,
    NgbModule
  ],
  exports: [MaintenanceComponent]
})
export class MaintenanceComponentModule { }
