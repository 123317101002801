import {PageType} from '../../../utils/common-functions';
import { RegistrationText } from '../../../model/LandingPage';

export interface RegistrationSectionConfiguration {
  registrationText: RegistrationText;
}

export const registrationSectionContentConfiguration: Map<PageType, RegistrationSectionConfiguration> = new Map([

  ['RESTAURANT', {
    registrationText: {
      title: 'newsLetterSection.lp-r-registration-section-title',
      par: 'newsLetterSection.lp-r-registration-section-subtitle',
      input: 'newsLetterSection.lp-r-registration-section-input',
      cta: 'newsLetterSection.lp-r-registration-section-cta',
      colorTitle: '#5b8c2a', /* lp-client - '#F25116' */
    }
  }],

  ['CLIENT', {
    registrationText: {
      title: 'newsLetterSection.lp-r-registration-section-title',
      par: 'newsLetterSection.lp-c-registration-section-subtitle',
      input: 'newsLetterSection.lp-r-registration-section-input',
      cta: 'newsLetterSection.lp-r-registration-section-cta',
      colorTitle: '#F25116', /* lp-client - '#F25116' */
    }
  }]

]);

