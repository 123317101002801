import { createReducer, on } from '@ngrx/store';
import { Product } from 'src/app/model/Product';
import { CompleteActions, ProductActions } from '../actions';

export interface ProductState {
    products: Product[];
    totalProducts: number;
    productsOfMenu: Product[];
}

export const initialState: ProductState = {
    products: [],
    totalProducts: 0,
    productsOfMenu: [],
};

export const productReducer = createReducer(
    initialState,
    on(ProductActions.cleanProductStatus, (state: ProductState) => ({
        ...state,
        products: [],
        productsOfMenu: [],
    })),
    on(ProductActions.getProductsSuccess, (state: ProductState, { products, totalProducts }) => ({
        ...state,
        products,
        totalProducts
    })),
    on(CompleteActions.getTablesAndOrdersAndProductsAndBookingsSuccess, (state: ProductState, action) => ({
        ...state,
        products: action.results[2].products,
        totalProducts: action.results[2].totalProducts
    })),
    on(ProductActions.getProductsOfMenuSuccess, (state: ProductState, { products }) => ({
        ...state,
        productsOfMenu: products,
    })),
    on(ProductActions.deleteProductSuccess, (state: ProductState, action) => ({
        ...state,
        products: state.products.filter((product) => product.id !== action.productId),
        productsOfMenu: state.productsOfMenu.filter((product) => product.id !== action.productId),
    })),
    on(ProductActions.saveProductToProductOfMenu, (state: ProductState, action) => ({
        ...state,
        productsOfMenu: action.products,
    })),
    on(ProductActions.clearProductOfMenu, (state: ProductState, action) => ({
        ...state,
        productsOfMenu: [],
    })),
    on(ProductActions.associateProductSuccess, (state: ProductState, action) => ({ ...state, productsOfMenu: state.productsOfMenu.findIndex(p => p.id === action.productId) !== -1 ? [...state.productsOfMenu.filter(p => p.id !== action.productId)] : [...state.productsOfMenu, state.products.find(p => p.id === action.productId) as Product] })),
    on(ProductActions.loadAssociatedProductsOfMenuSuccess, (state: ProductState, action) => ({
        ...state,
        productsOfMenu: action.products,
    })),
    on(ProductActions.removeProductAssociation, (state: ProductState, action) => ({
        ...state,
        productsOfMenu: state.productsOfMenu.filter((product) => product.id !== action.productId),
    }))
);
