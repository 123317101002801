import { createReducer, on } from '@ngrx/store';
import { ProductImageActions } from '../actions';

export interface ProductImageState {
  imagesUrls: {
    productId: string;
    imageUrls: string[];
  }[];
  imagesBuffers: {
    productId: string;
    imageBuffers: string[];
  }[];
}

export const initialState: ProductImageState = {
  imagesBuffers: [],
  imagesUrls: [],
};

export const productImageReducer = createReducer(
  initialState,
  on(ProductImageActions.cleanProductImageStatus, (state, action) => ({
    ...state,
    imagesUrls: [],
    imagesBuffers: [],
  })),
  on(ProductImageActions.getProductImagesSuccess, (state, { productId, productImages }) => ({
    ...state,
    imagesUrls: state.imagesUrls.some((el) => el.productId === productId)
      ? state.imagesUrls.map((el) => {
          if (el.productId === productId) {
            return { productId, imageUrls: productImages.images_urls };
          } else {
            return el;
          }
        })
      : [...state.imagesUrls, { productId, imageUrls: productImages.images_urls }],
  })),
  // on(ProductImageActions.createProductImagesSuccess, (state, { productId, productImages }) => ({
  //   ...state,
  //   imagesUrls: state.imagesUrls.map((images) => (images.productId === productId ? { productId, imageUrls: productImages.images_urls } : images)),
  //   // , { productId: action.productId, imageUrls: action.productImages.images_urls }],
  // })),
  on(ProductImageActions.deleteProductImageSuccess, (state, action) => ({
    ...state,
    imagesUrls: state.imagesUrls.map((p) => {
      if (p.productId === action.productImageId) {
        return { productId: p.productId, imageUrls: p.imageUrls.filter((i) => i !== action.imageid) };
      }
      return p;
    }),
  })),
  on(ProductImageActions.saveProductImagesBuffer, (state, action) => ({
    ...state,
    imagesBuffers: [...state.imagesBuffers, { productId: action.productImageId, imageBuffers: action.imageBuffer }],
  })),
  on(ProductImageActions.cleanProductImagesBuffer, (state, action) => ({
    ...state,
    imagesBuffers: [],
  })),
  on(ProductImageActions.getProductsImagesSuccess, (state, action) => ({
    ...state,
    imagesUrls: action.i.map((p) => {
      return { imageUrls: p[0].images_urls, productId: p[1] };
    }),
  }))
);
