import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Menu } from 'src/app/model/Menu';
import { AppState } from 'src/app/store';
import { MenuActions } from 'src/app/store/actions';

@Component({
  selector: 'app-menu-additional-info',
  templateUrl: './menu-additional-info.component.html',
  styleUrls: ['./menu-additional-info.component.scss']
})
export class MenuAdditionalInfoComponent implements OnInit {

  @Input() editingMenu: Menu | undefined | null;

  public additionalInfoForm!: FormGroup;
  get additionalInfoFormControls(): { [form: string]: AbstractControl } { return this.additionalInfoForm.controls; }

  isFormSucceeded = false;
  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.additionalInfoForm = this.formBuilder.group({
      menuName: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(45)
      ]],
      menuAdditionalInfo: ['', [
        Validators.minLength(3),
        Validators.maxLength(1300)
      ]]
    });
  }


  addAdditionalInfo(): void {
    
    let menuName = this.additionalInfoFormControls['menuName'].value;

    let menuAdditionalInfo = this.additionalInfoFormControls['menuAdditionalInfo'].value !== null ? this.additionalInfoFormControls['menuAdditionalInfo'].value : 'not defined';

    if(menuName === '') {
      menuName = this.editingMenu?.name;
    }
    if(menuAdditionalInfo === '') {
      menuAdditionalInfo = this.editingMenu?.additional_info;
    }
    

    this.store.dispatch(MenuActions.saveAdditionalInfo({
      info: menuAdditionalInfo,
      name: menuName
    }));

    this.isFormSucceeded = true;
  }


}

