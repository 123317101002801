import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { ActionReducer, ActionReducerMap, MetaReducer, RuntimeChecks } from "@ngrx/store";
import { StoreDevtoolsConfig } from "@ngrx/store-devtools";
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';


import { environment } from "src/environments/environment";
import { AuthEffects } from "../guard/store/auth.effects";
import { authReducer, AuthState } from "../guard/store/auth.reducer";
import { RouterEffects } from "../router/store/router.effects";
import { BookingSlotEffects } from "./effects/bookings-slot.effects";
import { BookingsEffects } from "./effects/bookings.effects";
import { CompleteEffects } from "./effects/complete.effects";
import { ConfigurationEffects } from "./effects/configuration.effects";
import { MenuEffects } from "./effects/menu.effects";
import { MessagingEffects } from "./effects/messaging.effects";
import { OrdersEffects } from "./effects/orders.effects";
import { PreferenceEffects } from "./effects/preferences.effects";
import { ProductImageEffects } from "./effects/product-image.effects";
import { ProductEffects } from "./effects/product.effects";
import { RestaurantImageEffects } from "./effects/restaurant-image.effects";
import { RestaurantEffects } from "./effects/restaurant.effects";
import { TablesEffects } from "./effects/tables.effects";
import { bookingSlotReducer, BookingsSlotState } from "./reducers/bookings-slot.reducers";
import { bookingReducer, BookingState } from "./reducers/bookings.reducers";
import { configurationReducer, ConfigurationState } from "./reducers/configuration.reducers";
import { menuReducer, MenuState } from "./reducers/menu.reducers";
import { messagingReducer, MessagingState } from "./reducers/messaging.reducers";
import { ordersReducer, OrderState } from "./reducers/orders.reducers";
import { preferenceReducer, PreferenceState } from "./reducers/preference.reducers";
import { productImageReducer, ProductImageState } from "./reducers/product-image.reducers";
import { productReducer, ProductState } from "./reducers/product.reducers";
import { restaurantImageReducer, RestaurantImageState } from "./reducers/restaurant-image.reducers";
import { restaurantReducer, RestaurantState } from "./reducers/restaurants.reducers";
import { systemReducer, SystemState } from "./reducers/system.reducers";
import { tableReducer, TableState } from "./reducers/tables.reducers";
import { customFlowReducer, CustomFlowState} from './reducers/custom-flow.reducers'
import { CustomFlowEffects } from "./effects/custom-flow.effects";

export interface AppState {
  auth: AuthState;
  router: RouterReducerState;
  messagingStore: MessagingState;
  restaurantStore: RestaurantState;
  menuStore: MenuState;
  tableStore: TableState;
  orderStore: OrderState;
  bookingStore: BookingState;
  systemStore: SystemState;
  preferenceStore: PreferenceState;
  configurationStore: ConfigurationState;
  bookingSlotsStore: BookingsSlotState;
  restaurantImageStore: RestaurantImageState;
  productStore: ProductState;
  productImageStore: ProductImageState;
  customFlowStore: CustomFlowState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  restaurantStore: restaurantReducer,
  menuStore: menuReducer,
  tableStore: tableReducer,
  orderStore: ordersReducer,
  messagingStore: messagingReducer,
  bookingStore: bookingReducer,
  router: routerReducer,
  systemStore: systemReducer,
  preferenceStore: preferenceReducer,
  configurationStore: configurationReducer,
  bookingSlotsStore: bookingSlotReducer,
  restaurantImageStore: restaurantImageReducer,
  productStore: productReducer,
  productImageStore: productImageReducer,
  customFlowStore: customFlowReducer
};
export const effects: Array<any> = [
  AuthEffects,
  RouterEffects,
  RestaurantEffects,
  MenuEffects,
  OrdersEffects,
  TablesEffects,
  MessagingEffects,
  BookingsEffects,
  ConfigurationEffects,
  BookingSlotEffects,
  RestaurantImageEffects,
  PreferenceEffects,
  ProductEffects,
  ProductImageEffects,
  CompleteEffects,
  CustomFlowEffects
];

export const runtimeChecks: RuntimeChecks = {
  strictActionImmutability: true,
  strictActionSerializability: false,
  strictActionWithinNgZone: true,
  strictStateImmutability: true,
  strictStateSerializability: false,
  strictActionTypeUniqueness: true,
};

export const devtoolsConfig: StoreDevtoolsConfig = {
  maxAge: 25,
  logOnly: environment.production,
};


function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync(localStorageConfig)(reducer);
}

const localStorageConfig: LocalStorageConfig = {
  keys: ['configurationStore', 'restaurantStore', 'auth', 'messagingStore'],
  rehydrate: true,
  storageKeySerializer: (key) => `${environment.localStorageKeyPrefix}_${key}`,
};

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
];