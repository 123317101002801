import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthActions } from 'src/app/guard/store';
import { RouterActions } from 'src/app/router/store';
import { AppState } from 'src/app/store';
import { MenuActions, ProductActions } from 'src/app/store/actions';
import * as MenuSelectors from '../../store/selectors/menu.selectors';
import * as ProductSelectors from '../../store/selectors/product.selectors';
@Component({
  selector: 'app-menu-builder',
  templateUrl: './menu-builder.component.html',
  styleUrls: ['./menu-builder.component.scss']
})
export class MenuBuilderComponent implements OnInit{

  @ViewChild('activeButton') activeButton!: ElementRef;
  @ViewChild('optionBlock') optionBlock!: ElementRef;

  @ViewChild('categoryBlock') categoryBlock!: ElementRef;
  @ViewChild('productBlock') productBlock!: ElementRef;
  @ViewChild('colorBlock') colorBlock!: ElementRef;
  @ViewChild('fontBlock') fontBlock!: ElementRef;
  @ViewChild('infoBlock') infoBlock!: ElementRef;

  constructor(private store: Store<AppState>,) { }

  toggleOptionBlock = false;
  tempPosition: number | null = null;
  tempBlockToHandle: ElementRef | null = null;

  editingMenu$ = this.store.select(MenuSelectors.getEditingMenu);
  products$ = this.store.select(ProductSelectors.selectProducts);
  productsOfMenu$ = this.store.select(ProductSelectors.selectProductsOfMenu);

  buttonSize = 67;
  optionBlockSize = 300;

  ngOnInit() {
    this.editingMenu$.subscribe(m => {
      if (m && !m.id) {
        this.store.dispatch(MenuActions.completeEditingMenu());
      }
    })
  }

  completeUpdateMenu(): void {
    this.store.dispatch(MenuActions.completeEditingMenu());
  }

  goToMenuDashboard(): void {
    this.store.dispatch(RouterActions.go({ path: ['dashboard/menu'] }));
  }

  goToDashboard(): void {
    this.store.dispatch(RouterActions.go({ path: ['dashboard'] }));
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }


  activateInfos(): void {
    this.activateButton(0, this.infoBlock);
  }

  activateProducts(): void {
    this.activateButton(1, this.productBlock);
  }

  activateColors(): void {
    this.activateButton(2, this.colorBlock);
  }

  activateFonts(): void {
    this.activateButton(3, this.fontBlock);
  }

  activateButton(positionButton: number, blockToHandle: ElementRef): void {
    if (this.toggleOptionBlock && this.tempPosition === positionButton) {
      this.activeButton.nativeElement.style.display = 'none';
      this.optionBlock.nativeElement.style.width = `0px`;

      this.toggleOptionBlock = false;
    } else {
      this.activeButton.nativeElement.style.display = 'block';
      this.activeButton.nativeElement.style.transform = `translate3d(0px, ${this.buttonSize * positionButton}px, 0px)`;
      this.optionBlock.nativeElement.style.width = `${this.optionBlockSize}px`;
      this.toggleOptionBlock = true;
    }
    if (!blockToHandle.nativeElement.classList.contains('category-block-active')) {
      blockToHandle.nativeElement.classList.add('category-block-active');
    } else {
      blockToHandle.nativeElement.classList.remove('category-block-active');
    }
    if (this.tempBlockToHandle && this.tempBlockToHandle.nativeElement.classList.contains('category-block-active') && this.tempPosition !== positionButton) {
      this.tempBlockToHandle.nativeElement.classList.remove('category-block-active');
    }
    this.tempBlockToHandle = blockToHandle;
    this.tempPosition = positionButton;
  }

}
