import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerLoginComponent } from './partner-login.component';

import { RouterModule } from '@angular/router';
import { PartnerLoginRoutingModule } from './partner-login-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NavModule } from 'src/app/components/nav/nav.module';

@NgModule({
  declarations: [PartnerLoginComponent],
  imports: [
    CommonModule,
    RouterModule,
    PartnerLoginRoutingModule,
    TranslateModule,
    NgbModule,
    NavModule,
    ReactiveFormsModule,
  ],
  exports: [PartnerLoginComponent]
})
export class PartnerLoginModule { }
