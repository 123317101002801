<nav class="navbar navbar-expand-lg bg-white">
    <div class="nav-row row">
        <div class="col-sm-1 col-md-2 col-lg-2">
            <a class="navbar-brand title-a">
                <img [routerLink]="['']" class="logo-desk" src="../../../../assets/img/qr-menu/logo_desk.png" alt="Hamburger Linguì" />
            </a>
        </div>
        <div [ngClass]="this.topNavConfiguration.toLetRestaurantEnter && isPlatformEnabled ? 'col-lg-8 col-md-8 col-sm-9' : 'col-lg-8 col-md-8 col-sm-9'">
            <ul class="text-center">
                <li *ngFor="let title of this.topNavConfiguration.navTitle" (click)="scrollToElement(title.link)" class="nav-item">
                    <a class="nav-link" [style.color]="this.topNavConfiguration.navbar.color">{{ title.name | translate }}</a>
                </li>
            </ul>
        </div>
        <div
            *ngIf="this.topNavConfiguration.toLetRestaurantEnter && isPlatformEnabled"
            class="col-sm-2 col-lg-2 col-md-2 auth-buttons"
            style="display: flex; justify-content: flex-end"
        >
            <ng-container *ngIf="restaurantStage$ | async as restaurantStage">
                <button
                    *ngIf="topNavConfiguration.toLetRestaurantEnter && restaurantStage === 'undefined'"
                    [routerLink]="['/partner-login']"
                    type="button"
                    class="btn btn-sm"
                    style="background-color: {{ this.topNavConfiguration.navbar.color }} !important; color: white !important;"
                >
                    {{ this.topNavConfiguration.navbar.login | translate }}
                    <i class="fas fa-chevron-right" style="padding: 0"></i>
                </button>
                <ng-container *ngIf="getStageName(restaurantStage) | async as stageName">
                <button
                    *ngIf="stageName === 'RESTAURANT_STAGE_5_RESTAURANT_PAYMENT'"
                    [routerLink]="['/dashboard']"
                    type="button"
                    class="btn btn-sm"
                    style="margin-right: 1.5rem; background-color: transparent !important; border: 1px solid {{
                        this.topNavConfiguration.navbar.color
                    }} ; color: {{ this.topNavConfiguration.navbar.color }} !important;"
                >
                    {{ this.topNavConfiguration.navbar.dashboard | translate }}
                    <i class="fas fa-utensils" style="padding: 0"></i>
                </button>

                <button
                    *ngIf="stageName !== 'RESTAURANT_STAGE_5_RESTAURANT_PAYMENT' && restaurantStage !== 'undefined'"
                    [routerLink]="['/partner-configurator']"
                    type="button"
                    class="btn btn-sm"
                    style="margin-right: 1.5rem; background-color: transparent !important; border: 1px solid {{
                        this.topNavConfiguration.navbar.color
                    }} ; color: {{ this.topNavConfiguration.navbar.color }} !important;"
                >
                    {{ this.topNavConfiguration.navbar.configurator | translate }}
                    <i class="fas fa-utensils" style="padding: 0"></i>
                </button>
                </ng-container>
            </ng-container>
            <ng-container *appShowIfLogged='true'>
                <button
                    (click)="logout()"
                    type="button"
                    class="btn btn-sm"
                    style="background-color: {{ this.topNavConfiguration.navbar.color }} !important; color: white !important;"
                >
                    {{ this.topNavConfiguration.navbar.logout | translate }}
                    <i class="fas fa-chevron-right" style="padding: 0"></i>
                </button>
            </ng-container>
        </div>
    </div>
</nav>

<nav class="navbar-responsive navbar-expand-lg bg-white">
    <div class="nav-row col-sm-12 col-md-12 justify-content-between">
        <div>
            <a class="navbar-brand title-a">
                <img [routerLink]="['']" class="logo-mob" src="../../../../assets/img/qr-menu/logo_mob.png" alt="Linguì" />
            </a>
        </div>
        <div class="burger-menu" (click)="openMenu()" style="background-color: {{ this.topNavConfiguration.navbar.color }};">
            <img id="img-burger" [src]="this.topNavConfiguration.toggleMenu ? this.fork.img : this.hamburger.img" alt="Linguì" />
        </div>
    </div>
</nav>
