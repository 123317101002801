import { createAction, props } from "@ngrx/store";


export const getInitAccountConfiguration = createAction(
    "[COMPLETE-CONFIGURATION-DATA] Get Init Account Configuration"
);
export const getInitAccountConfigurationSuccess = createAction(
    "[COMPLETE-CONFIGURATION-DATA] Get Init Account Configuration Success",
    props<{results: any[]}>()
);
export const getInitAccountConfigurationFailed = createAction(
    "[COMPLETE-CONFIGURATION-DATA] Get Init Account Configuration Failed",
    props<{ error: Error }>()
);

export const getInitAccountProfile = createAction(
    "[COMPLETE-PROFILE-DATA] Get Init Account Profile "
);
export const getInitAccountProfileSuccess = createAction(
    "[COMPLETE-PROFILE-DATA] Get Init Account Profile  Success",
    props<{results: any[]}>()
);
export const getInitAccountProfileFailed = createAction(
    "[COMPLETE-PROFILE-DATA] Get Init Account Profile  Failed",
    props<{ error: Error }>()
);

export const getReviewComponent = createAction(
    "[COMPLETE-CONFIGURATION-DATA] Get Review Component"
);
export const getReviewComponentSuccess = createAction(
    "[COMPLETE-CONFIGURATION-DATA] Get Review Component Success",
    props<{results: any[]}>()
);
export const getReviewComponentFailed = createAction(
    "[COMPLETE-CONFIGURATION-DATA] Get Review Component Failed",
    props<{ error: Error }>()
);

export const getTablesAndOrdersAndProductsAndBookings = createAction(
    "[COMPLETE-TABLES-ORDERS] Get Tables And Orders And Bookings"
);
export const getTablesAndOrdersAndProductsAndBookingsSuccess = createAction(
    "[COMPLETE-TABLES-ORDERS] Get Tables And Orders Success",
    props<{results: any[]}>()
);
export const getTablesAndOrdersAndProductsAndBookingsFailed = createAction(
    "[COMPLETE-TABLES-ORDERS] Get Tables And Orders Failed",
    props<{ error: Error }>()
);