import { createAction, props } from '@ngrx/store';
import { Message,NotificationItem } from '../reducers/messaging.reducers';

export const cleanMessagingStore = createAction(
  '[Messaging] Clean Messaging Store'
);

export const receiveBackgroundMessage = createAction(
  '[Messaging] Receive Background Message',
  props<{backgroundMessage: {}}>()
);

export const receiveForegroundMessage = createAction(
  '[Messaging] Receive Foreground Message',
  props<{foregroundMessage: {}}>()
);

export const receiveMessageSuccess = createAction(
  '[Messaging] Receive Message Success',
  props<{message: Message}>()
);

export const receiveMessageFailed = createAction(
  '[Messaging] Receive Message Failed',
  props<{error: Error}>()
);

export const requestToken = createAction(
  '[Messaging] Request Token'
);

export const requestTokenSuccess = createAction(
  '[Messaging] Request Token Success',
  props<{token: string}>()
);

export const requestTokenFailed = createAction(
  '[Messaging] Request Token Failed',
  props<{error: Error}>()
);

// Notification Dispatcher

export const dispatchNotification = createAction(
  '[NOTIFICATION DISPATCHER] Dispatch Notifications',
  props<{notification: {data: any}, restaurant_id: string}>()
);

export const notificationNotDispatched = createAction(
  '[NOTIFICATION DISPATCHER] Notification not dispatched'
);


export const attentionAtTheTableNotification = createAction(
  '[NOTIFICATION DISPATCHER] Attention at the Table Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);

export const attentionAtTheTableDoneNotification = createAction(
  '[NOTIFICATION DISPATCHER] Attention at the Table Done Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);

export const deactivateTableNotification = createAction(
  '[NOTIFICATION DISPATCHER] Deactivate Table Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);

export const activateTableNotification = createAction(
  '[NOTIFICATION DISPATCHER] Activate Table Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);

export const useTableNotification = createAction(
  '[NOTIFICATION DISPATCHER] Use Table Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);

export const orderSentNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Sent Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderReceivedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Received Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const preparingOrderNotification = createAction(
  '[NOTIFICATION DISPATCHER] Preparing Order Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderDoneNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Done Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderPaidNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Paid Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderPaidWithSumUpNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Paid With SumUp Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderPaidWithCoinbaseNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Paid With Coinbase Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderPaidWithSatispayNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Paid With Satispay Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderPaidWithClassicNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Paid With Classic Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderToPayNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order To Pay Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderCompletedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Completed Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const orderWaitressCommentNotification = createAction(
  '[NOTIFICATION DISPATCHER] Order Waitress Comment Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);


export const bookingSentNotification = createAction(
  '[NOTIFICATION DISPATCHER] Booking Sent Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const bookingAcceptedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Booking Accepted Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const bookingRefusedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Booking Refused Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const bookingRemovedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Booking Removed Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const bookingCommentUpdatedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Booking Comment Updated Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const bookingGuestsNumberUpdatedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Booking Guests Number Updated Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);
export const bookingTableNumberUpdatedNotification = createAction(
  '[NOTIFICATION DISPATCHER] Booking Table Number Updated Notification',
  props<{notification: {data: NotificationItem}, restaurant_id: string}>()
);