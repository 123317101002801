import { createAction, props } from "@ngrx/store";
import { BookingSlot, CreateBookingSlot } from "src/app/model/Booking";

export const cleanBookingSlots = createAction("[BookingsSlot] Clean Booking Slots");

export const copyBookingSlots = createAction(
    "[BookingsSlot] Copy Booking Slots",
    props<{ fromDay: string, toDay: string }>()
);

export const copyBookingSlotsSuccess = createAction(
    "[BookingsSlot] Copy Booking Slots Success",
);

export const copyBookingSlotsFailed = createAction(
    "[BookingsSlot] Copy Booking Slots Failed",
    props<{ error: Error }>()
);

export const getBookingSlots = createAction(
    "[Bookings Slot] Get Booking Slots",
    props<{date?: string, guestsNumber?: string}>()
);
export const getBookingSlotsSuccess = createAction(
    "[Bookings Slot] Get Booking Slots Success",
    props<{bookingSlots: BookingSlot[], hourlySlots: string[], free_tables: {hourly_slot: string, tables: string[]}[]}>()
);
export const getBookingSlotsFailed = createAction(
    "[Bookings Slot] Get Booking Slots Failed",
    props<{ error: Error }>()
);

export const createBookingSlot = createAction(
    "[Bookings Slot] Create Booking Slot",
    props<{bookingSlot: CreateBookingSlot}>()
);
export const createBookingSlotSuccess = createAction(
    "[Bookings Slot] Create Booking Slot Success",
    props<{bookingSlot: BookingSlot}>()
);
export const createBookingSlotFailed = createAction(
    "[Bookings Slot] Create Booking Slot Failed",
    props<{ error: Error }>()
);

export const updateBookingSlot = createAction(
    "[Bookings Slot] Update Booking Slot",
    props<{bookingSlot: BookingSlot}>()
);
export const updateBookingSlotSuccess = createAction(
    "[Bookings Slot] Update Booking Slot Success",
    props<{bookingSlot: BookingSlot}>()
);
export const updateBookingSlotFailed = createAction(
    "[Bookings Slot] Update Booking Slot Failed",
    props<{ error: Error }>()
);

export const deleteBookingSlot = createAction(
    "[Bookings Slot] Delete Booking Slot",
    props<{bookingSlotId: string}>()
);
export const deleteBookingSlotSuccess = createAction(
    "[Bookings Slot] Delete Booking Slot Success",
    props<{bookingSlotId: string}>()
);
export const deleteBookingSlotFailed = createAction(
    "[Bookings Slot] Delete Booking Slot Failed",
    props<{ error: Error }>()
);