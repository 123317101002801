import { createReducer, on } from '@ngrx/store';
import { Table, TableResponse } from 'src/app/model/Table';
import { CompleteActions, MessagingActions, TableActions } from '../actions';

export interface TableState {
  currentTables: TableResponse | null;
  searchByTableStatus?: string;
}

export const initialState: TableState = {
  currentTables: null,
  searchByTableStatus: undefined,
};

export const tableReducer = createReducer(
  initialState,
  on(TableActions.cleanTablesStore, (state, action) => ({
    ...state,
    currentTables: null
  })),
  on(TableActions.getTablesByTableStatus, (state, action) => ({ ...state, searchByTableStatus: action.tableStatusId, })),
  on(TableActions.getTablesByTableStatusSuccess, (state, action) => ({ ...state, currentTables: action.tables, currentPage: 1})),

  on(TableActions.getTablesStatusSuccess, (state, action) => ({ ...state, currentTables: action.tableResponse })),
  on(CompleteActions.getTablesAndOrdersAndProductsAndBookingsSuccess, (state, action) => ({ ...state, currentTables: action.results[1] })),
  on(MessagingActions.activateTableNotification, (state, action) => ({
    ...state,
    currentTables: {
      ...state.currentTables,
      tables:
        JSON.parse(action.notification.data.table).restaurant_id === action.restaurant_id ? state.currentTables?.tables.findIndex((table) => table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id) === -1
          ? ([...state.currentTables?.tables, JSON.parse(action.notification.data.table)] as Table[])
          : state.currentTables?.tables.map((table) =>
            table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id ? JSON.parse(action.notification.data.table) : table
          ) : state.currentTables,
    } as TableResponse,
  })),
  on(MessagingActions.attentionAtTheTableNotification, (state, action) => ({
    ...state,
    currentTables: {
      ...state.currentTables,
      tables:
        JSON.parse(action.notification.data.table).restaurant_id === action.restaurant_id ? state.currentTables?.tables.findIndex((table) => table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id) === -1
          ? ([...state.currentTables?.tables, JSON.parse(action.notification.data.table)] as Table[])
          : state.currentTables?.tables.map((table) =>
            table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id ? JSON.parse(action.notification.data.table) : table
          ) : state.currentTables,
    } as TableResponse,
  })),
  on(MessagingActions.attentionAtTheTableDoneNotification, (state, action) => ({
    ...state,
    currentTables: {
      ...state.currentTables,
      tables:
        JSON.parse(action.notification.data.table).restaurant_id === action.restaurant_id ? state.currentTables?.tables.findIndex((table) => table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id) === -1
          ? ([...state.currentTables?.tables, JSON.parse(action.notification.data.table)] as Table[])
          : state.currentTables?.tables.map((table) =>
            table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id ? JSON.parse(action.notification.data.table) : table
          ) : state.currentTables,
    } as TableResponse,
  })),
  on(MessagingActions.deactivateTableNotification, (state, action) => ({
    ...state,
    currentTables: {
      ...state.currentTables,
      tables:
        JSON.parse(action.notification.data.table).restaurant_id === action.restaurant_id ? state.currentTables?.tables.findIndex((table) => table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id) === -1
          ? ([...state.currentTables?.tables, JSON.parse(action.notification.data.table)] as Table[])
          : state.currentTables?.tables.map((table) =>
            table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id ? JSON.parse(action.notification.data.table) : table
          ) : state.currentTables,
    } as TableResponse,
  })),
  on(MessagingActions.useTableNotification, (state, action) => ({
    ...state,
    currentTables: {
      ...state.currentTables,
      tables:
        JSON.parse(action.notification.data.table).restaurant_id === action.restaurant_id ? state.currentTables?.tables.findIndex((table) => table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id) === -1
          ? ([...state.currentTables?.tables, JSON.parse(action.notification.data.table)] as Table[])
          : state.currentTables?.tables.map((table) =>
            table.id === action.notification.data.table_id && table.restaurant_id === action.restaurant_id ? JSON.parse(action.notification.data.table) : table
          ) : state.currentTables,
    } as TableResponse,
  }))
);
