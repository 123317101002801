import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Table, TableResponse } from "src/app/model/Table";
import { environment } from "src/environments/environment";
import packageInfo from '../../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class TablesService {
  constructor(private http: HttpClient) { }

  getTables(restaurantId: string, tableStatusId?: string): Observable<TableResponse> {

    let params: HttpParams = new HttpParams().append('limit', 10000).append('offset', 0);
    if (tableStatusId) {
      params = params.append('statusid', tableStatusId);
    }
    return this.http.get<TableResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/tables`, {params});
  };

  createTables(restaurantId: string, table_ranges: {
    from: number,
    to: number
  }[], max_customers_number: number): Observable<{ table_ids: string[] }> {
    return this.http.post<{ table_ids: string[] }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/tables`, { table_ranges: table_ranges, max_customers_number: max_customers_number });
  };

  activateTable(restaurantId: string, tableId: string): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/tables/${tableId}/activate`, { to_activate: true });
  };

  deactivateTable(restaurantId: string, tableId: string): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/tables/${tableId}/deactivate`, { to_deactivate: false });
  };

  attentionTable(restaurantId: string, tableId: string): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/tables/${tableId}/attention`, { to_attention: false });
  };
}
