import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { Store } from '@ngrx/store';
import { ReplaySubject, Subject, takeUntil, take } from 'rxjs';
import { Menu } from 'src/app/model/Menu';
import { Product } from 'src/app/model/Product';
import { AppState } from 'src/app/store';
import { ProductActions } from 'src/app/store/actions';





@Component({
  selector: 'app-menu-product',
  templateUrl: './menu-product.component.html',
  styleUrls: ['./menu-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuProductComponent implements OnInit {

  groupProducts: Array<Product> = [];
  formProduct!: FormGroup;

  protected _onDestroy = new Subject<void>();
  @Input() products: Product[] | null | undefined;
  @Input() productsOfMenu: Product[] | null | undefined;
  @Input() editingMenu: Menu | undefined | null;

  public productFilterCtrl: FormControl = new FormControl();
  public filteredProd: ReplaySubject<Product[]> = new ReplaySubject<Product[]>(1);
  @ViewChild('singleProdSelect', { static: true }) singleProdSelect!: MatSelect;
  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    console.log(this.productsOfMenu)
    this.formProduct = new FormGroup({
      product_type_ids: new FormControl(this.productsOfMenu?.map(p => p.id) ?? [], []),
    })

    if (this.products) {
      this.filteredProd.next(this.products.slice());
      this.productFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterProducts();
        })
    }
  }

  protected filterProducts() {
    if (!this.products) {
      return;
    }
    // get the search keyword
    let search = this.productFilterCtrl.value;
    console.log(search)
    if (!search) {
      this.filteredProd.next(this.products.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    this.filteredProd.next(
      this.products!.filter(product => product.name.toLowerCase().indexOf(search) > -1)
    );


  }


  updateProduct(productId: string) {
    if (this.editingMenu) {
      this.store.dispatch(ProductActions.associateProduct({menuId: this.editingMenu.id, productId}));
    }
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredProd
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleProdSelect) {
          this.singleProdSelect.compareWith = (a: Product, b: Product) => a && b && a.id === b.id;
        }
      });
  }



}

