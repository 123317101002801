import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import * as CustomFlowModel from '../../model/CustomFlow';

@Injectable({
    providedIn: 'root'
})
export class CustomFlowService {
    constructor(private http: HttpClient) { }

    getCustomFlowData(restaurant_id: string): Observable<CustomFlowModel.CustomFlowResponse> { 
        return this.http.get<CustomFlowModel.CustomFlowResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurant_id}/custom-flow`)
    }

    updateCustomFlowData(restaurant_id: string, payload: CustomFlowModel.CustomFlowResponse): Observable<{restaurant_id: string}> {
        return this.http.post<{restaurant_id: string}>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurant_id}/custom-flow`, payload);
    }
}