<h5>INFORMAZIONI</h5>
<h6>Aggiungi informazioni extra</h6>
<div class="category-form">
  <form [formGroup]="additionalInfoForm">
    <div class="product-name">
      <span>Nome del menu: <i class="fas fa-signature"></i></span>
      <input type="text" (input)="addAdditionalInfo()" class="input-text product-input-text" formControlName="menuName" value="{{editingMenu?.name}}" placeholder="{{editingMenu?.name ?? 'Menu della sera'}}"/>
    </div>
    <br>
    <!-- <div class="product-name">
      <span>Informazioni aggiuntive: <i class="fas fa-signature"></i></span>
      <textarea class="input-text product-input-textarea" formControlName="menuAdditionalInfo" value="{{editingMenu?.additional_info}}" placeholder="{{editingMenu?.additional_info ?? 'Caro ospite/cliente, se hai delle allergie e/o intolleranze alimentari segnalalo al nostro personale e chiedi pure informazioni sul nostro cibo e sulle nostre bevande. Siamo preparati per consigliarti nel migliore dei modi.Elenco degli ingredienti allergenici utilizzati in questo esercizio e presenti nell\'allegato II del Reg. UE n. 1169/2001 - Sostanze o prodotti che provocano allergie o intolleranze'}}"></textarea>
    </div>
    <br> -->
    <!-- <div class="save-product">
      <button [disabled]="!additionalInfoForm.valid" (click)="addAdditionalInfo()" class="btn btn-primary">Aggiungi informazioni</button>
      <div data-aos="fade-down" class="alert alert-success" *ngIf="this.isFormSucceeded"><p class="alert-text">Informazioni inserite con successo!</p></div>
    </div> -->
  </form>
</div>
