import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuProductComponent } from './menu-product.component';
import { MenuProductRoutingModule } from './menu-product-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [MenuProductComponent],
  imports: [
    CommonModule,
    MenuProductRoutingModule,
    TranslateModule,
    NgbModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [MenuProductComponent]
})
export class MenuProductModule { }
