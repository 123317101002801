<div class="container-why-section {{whySectionConfiguration.whyParam.bannerClass}}">
  <div class="banner-why-section {{whySectionConfiguration.whyParam.backgroundColor}}">
    <div class="landingpage-container">
      <div class="why-container row wrap" [style.flexDirection]="whySectionConfiguration.whyParam.rowDirection">
        <div data-aos="flip-left" class="col-lg-4 col-md-4 col-sm-12 col-xs-12 why-section-text">
          <div class="why-section-img {{whySectionConfiguration.whyParam.circleImg}}">
            <img class="img-mask" src="{{whySectionConfiguration.whyParam.img}}" alt="Mask Linguì">
          </div>
        </div>
        <div data-aos="fade-up" class="col-lg-8 col-md-8 col-sm-12 col-xs-12 why-section-text">
          <h3 style="color: white !important;"> {{ whySectionConfiguration.whyParam.title | translate}} </h3>
          <p style="color: white !important; line-height: 25px !important;"> {{ whySectionConfiguration.whyParam.par | translate}} </p>
        </div>
      </div>
    </div>
  </div>
</div>

