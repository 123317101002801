import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthState} from './auth.reducer';

const selectAuthState =
  createFeatureSelector<AuthState>('auth');

export const getToken = createSelector(
  selectAuthState,
  (stateAuth: AuthState) => stateAuth.accessToken
);

export const getEmailToken = createSelector(
  selectAuthState,
  (stateAuth: AuthState) => stateAuth.emailToken
);
