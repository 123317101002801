import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuPreviewComponent } from './menu-preview.component';
import { MenuPreviewRoutingModule } from './menu-preview-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogConfirmModule } from '../../dialog-confirm/dialog-confirm.module';

@NgModule({
  declarations: [MenuPreviewComponent],
  imports: [
    CommonModule,
    MenuPreviewRoutingModule,
    DialogConfirmModule,
    TranslateModule,
    NgbModule,
  ],
  exports: [MenuPreviewComponent]
})
export class MenuPreviewModule { }
