import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { MessagingService } from '../../providers/services/messaging.service';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { MessagingActions } from '../actions';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Message, NOTIFICATION_IDs } from '../reducers/messaging.reducers';
import { Store } from '@ngrx/store';
import { AppState } from '..';

@Injectable()
export class MessagingEffects {

  constructor(
    private actions$: Actions,
    private messagingService: MessagingService,
    private store$: Store<AppState>
  ) {
  }

  initEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    switchMap(() => [
      MessagingActions.cleanMessagingStore()
    ])
  ));

  requestTokenEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.requestToken),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.messagingService.requestPermission().pipe(
          map((token) => MessagingActions.requestTokenSuccess({ token: token })),
          catchError(err => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[requestTokenEffect$ - requestPermission] error: ${JSON.stringify(err)}`);
            }
            return of(MessagingActions.requestTokenFailed({ error: new Error(err.message) }));
          })
        )
      )
    )
  );

  receiveForegroundMessageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.receiveForegroundMessage),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.messagingService.receiveForegroundMessage(action.foregroundMessage).pipe(
          map((payload) => MessagingActions.receiveMessageSuccess({ message: payload as Message })),
          catchError(err => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[receiveForegroundMessageEffect$ - receiveForegroundMessage] error: ${JSON.stringify(err)}`);
            }
            return of(MessagingActions.receiveMessageFailed({ error: new Error(err.message) }));
          })
        )
      )
    )
  );

  receiveBackgroundMessageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.receiveBackgroundMessage),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.messagingService.receiveBackgroundMessage(action.backgroundMessage).pipe(
          map((payload) => MessagingActions.receiveMessageSuccess({ message: payload as Message })),
          catchError(err => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[receiveBackgroundMessageEffect$ - receiveBackgroundMessage] error: ${JSON.stringify(err)}`);
            }
            return of(MessagingActions.receiveMessageFailed({ error: new Error(err.message) }));
          })
        )
      )
    )
  );

  dispatchMesssagesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.receiveMessageSuccess),
      withLatestFrom(this.store$),
      map(([action, storeState]) => {
        return MessagingActions.dispatchNotification({ restaurant_id: storeState.restaurantStore.restaurant!.id, notification: { data: action.message.data } })
      })
    )
  );

  // Dispatching Messages Logic
  dispatchNotificationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.dispatchNotification),
      withLatestFrom(this.store$),
      map(([action, storeState]) => {
        switch (action.notification.data.notificationId) {
          case 'RESTAURANT_TABLE_STATUS_TABLE_ENABLED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.activateTableNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  table: action.notification.data.table,
                  status_id: action.notification.data.status_id
                }
              }
            });
          }
          case 'RESTAURANT_TABLE_STATUS_ATTENTION_NEEDED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.attentionAtTheTableNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'DANGER',
                  timestamp: Date.now(),
                  table: action.notification.data.table,
                  status_id: action.notification.data.status_id
                }
              }
            });
          }
          case 'RESTAURANT_TABLE_STATUS_ATTENTION_COMPLETED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.attentionAtTheTableDoneNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  table: action.notification.data.table,
                  status_id: action.notification.data.status_id
                }
              }
            });
          }
          case 'RESTAURANT_TABLE_STATUS_TABLE_USE' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.useTableNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'INFO',
                  timestamp: Date.now(),
                  table: action.notification.data.table,
                  status_id: action.notification.data.status_id
                }
              }
            });
          }
          case 'RESTAURANT_TABLE_STATUS_TABLE_DISABLED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.deactivateTableNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  table: action.notification.data.table,
                  status_id: action.notification.data.status_id
                }
              }
            });
          }

          case 'RESTAURANT_ORDER_STATUS_ORDER_SENT' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderSentNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'INFO',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            });
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_RECEIVED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderReceivedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'INFO',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_PREPARING' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.preparingOrderNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'WARNING',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_DONE' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderDoneNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_PAID' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderPaidNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_SUMUP' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderPaidWithSumUpNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_COINBASE' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderPaidWithCoinbaseNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_CLASSIC' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderPaidWithClassicNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_SATISPAY' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderPaidWithSatispayNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_TO_PAY' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderToPayNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'DANGER',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_COMPLETED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderCompletedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            });
          }
          case 'RESTAURANT_ORDER_STATUS_ORDER_WAITRESS_COMMENT' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.orderWaitressCommentNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  table_id: action.notification.data.table_id,
                  notificationId: action.notification.data.notificationId,
                  order_id: action.notification.data.order_id,
                  product_id: action.notification.data.product_id,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  order: action.notification.data.order,
                  status_id: action.notification.data.status_id
                }
              }
            })
          }

          case 'RESTAURANT_BOOKING_STATUS_BOOKING_SENT' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.bookingSentNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  booking: action.notification.data.booking,
                  notificationId: action.notification.data.notificationId,
                  booking_id: action.notification.data.booking_id,
                  gravity: 'INFO',
                  timestamp: Date.now(),
                  status_id: action.notification.data.status_id,
                }
              }
            })
          }
          case 'RESTAURANT_BOOKING_STATUS_BOOKING_ACCEPTED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.bookingAcceptedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  booking_id: action.notification.data.booking_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'AVAILABLE',
                  timestamp: Date.now(),
                  status_id: action.notification.data.status_id,
                  booking: action.notification.data.booking,
                }
              }
            })
          }
          case 'RESTAURANT_BOOKING_STATUS_BOOKING_REFUSED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.bookingRefusedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  booking_id: action.notification.data.booking_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'WARNING',
                  timestamp: Date.now(),
                  status_id: action.notification.data.status_id,
                  booking: action.notification.data.booking,
                }
              }
            })
          }
          case 'RESTAURANT_BOOKING_STATUS_BOOKING_CANCELLED' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.bookingRemovedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  booking_id: action.notification.data.booking_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'DANGER',
                  timestamp: Date.now(),
                  status_id: action.notification.data.status_id,
                  booking: action.notification.data.booking,
                }
              }
            })
          }
          case 'RESTAURANT_BOOKING_STATUS_BOOKING_COMMENT_UPDATE' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.bookingCommentUpdatedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  booking_id: action.notification.data.booking_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'DANGER',
                  timestamp: Date.now(),
                  status_id: action.notification.data.status_id,
                  booking: action.notification.data.booking,
                }
              }
            })
          }
          case 'RESTAURANT_BOOKING_STATUS_BOOKING_GUESTS_NUMBER_UPDATE' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.bookingGuestsNumberUpdatedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  booking_id: action.notification.data.booking_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'DANGER',
                  timestamp: Date.now(),
                  status_id: action.notification.data.status_id,
                  booking: action.notification.data.booking,
                }
              }
            })
          }
          case 'RESTAURANT_BOOKING_STATUS_BOOKING_TABLE_NUMBER_UPDATE' as keyof typeof NOTIFICATION_IDs: {
            return MessagingActions.bookingTableNumberUpdatedNotification({
              restaurant_id: storeState.restaurantStore.restaurant!.id, notification: {
                data: {
                  booking_id: action.notification.data.booking_id,
                  notificationId: action.notification.data.notificationId,
                  gravity: 'DANGER',
                  timestamp: Date.now(),
                  status_id: action.notification.data.status_id,
                  booking: action.notification.data.booking,
                }
              }
            })
          }

          default: {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[dispatchNotificationEffect$ - dispatchNotificationEffect] Notification (${action.notification.data.notificationId}) NOT DISPACHED!`);
            }
            return MessagingActions.notificationNotDispatched();
          }
        }
      })
    )
  );

  // startLoading$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MessagingActions.requestToken),
  //     map(() => SystemActions.StartLoading())
  //   )
  // );

  // stopLoading$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MessagingActions.requestTokenSuccess, MessagingActions.requestTokenFailed),
  //     map(() => SystemActions.StopLoading())
  //   )
  // );

}
