<div [ngClass]="topNavConfiguration.toggleMenu ? avatarBannerOpened() : topNavConfiguration.dirtyMenuClasses"
  style="background-color: {{topNavConfiguration.bannerBackground}}">
  <div class="col-12 nav-list-wrapper" style='margin-top: {{topNavConfiguration.navListWrapperOpenTop}}'
    [ngClass]="topNavConfiguration.toggleMenu ? 'nav-list-wrapper-open' : ''">
    <div class="hamb-top-wrapper" *ngIf="topNavConfiguration.toggleMenu">
      <img src="{{hamburgerTop.img}}" class="hamburger-top" alt="Hamburger Linuguì">
    </div>
    <ul class="nav-list-responsive" *ngIf="topNavConfiguration.toggleMenu">
      <li (click)='scrollToElement(title.link)' class="nav-item-responsive"
        *ngFor="let title of topNavConfiguration.navTitle">
        <a class="nav-link-responsive">{{ title.name | translate}}</a>
      </li>
      <li (click)="scrollToElement('hello')" class="nav-item-responsive">
        <div class="btn nav-btn nav-link-responsive sign-btn"
          style="background-color: white !important; color: {{topNavConfiguration.navbar.color}}">
          <a style="color: {{topNavConfiguration.navbar.color}} !important;">{{
            topNavConfiguration.navbar.presentation | translate}} <i class="fas fa-chevron-right"></i></a>
        </div>
      </li>
      <ng-container *ngIf="this.topNavConfiguration.toLetRestaurantEnter && isPlatformEnabled">
        <ng-container *ngIf="restaurantStage$ | async as restaurantStage">
          <li [routerLink]="['/partner-login']"
            *ngIf="topNavConfiguration.toLetRestaurantEnter && restaurantStage==='undefined'"
            class="nav-item-responsive" style="display: contents;">
            <a class="nav-link-responsive">
              {{ topNavConfiguration.navbar.login | translate}}
            </a>
          </li>

        </ng-container>
        <ng-container *ngIf="restaurantStage$ | async as restaurantStage">
          <ng-container *ngIf="getStageName(restaurantStage) | async as stageName">
            <ng-container
              *ngIf='restaurantStage !== undefined && stageName !== "RESTAURANT_STAGE_5_RESTAURANT_PAYMENT"'>
              <li [routerLink]="['/partner-configurator']" *ngIf="topNavConfiguration.toLetRestaurantEnter"
                class="nav-item-responsive">
                <div class="btn nav-btn nav-link-responsive sign-btn"
                  style="background-color: white !important; color: {{topNavConfiguration.navbar.color}}">
                  <a>{{ topNavConfiguration.navbar.configurator | translate}} <i class="fas fa-utensils"></i></a>
                </div>
              </li>
            </ng-container>
            <ng-container
              *ngIf='restaurantStage !== undefined && stageName === "RESTAURANT_STAGE_5_RESTAURANT_PAYMENT"'>
              <li [routerLink]="['/dashboard']" *ngIf="topNavConfiguration.toLetRestaurantEnter"
                class="nav-item-responsive">
                <div class="btn nav-btn nav-link-responsive sign-btn"
                  style="background-color: white !important; color: {{topNavConfiguration.navbar.color}}">
                  <a>{{ topNavConfiguration.navbar.dashboard | translate}} <i class="fas fa-utensils"></i></a>
                </div>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <br>
      <ng-container *appShowIfLogged='true'>
        <li (click)='logout()' *ngIf="topNavConfiguration.toLetRestaurantEnter" class="nav-item-responsive">
          <div class="btn nav-btn nav-link-responsive sign-btn"
            style="background-color: transparent !important;border: 1px solid #fff !important;">
            <a style="color: white !important">{{ topNavConfiguration.navbar.logout | translate}} <i
                class="fas fa-chevron-right"></i></a>
          </div>
        </li>
      </ng-container>
    </ul>
    <div class="hamb-bot-wrapper" *ngIf="topNavConfiguration.toggleMenu">
      <img src="{{hamburgerBot.img}}" class="hamburger-bot" alt="Hamburger Linuguì">
    </div>
  </div>
</div>