import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { isUserLoggedIn } from '../../store/selectors/restaurants.selectors';


@Directive({
  selector: '[appShowIfLogged]'
})
export class ShowIfLoggedDirective implements OnInit, OnDestroy {

  @Input() appShowIfLogged!: boolean;
  private destroy$ = new Subject();
  private hasView = false;

  constructor(
    private template: TemplateRef<any>,
    private view: ViewContainerRef,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(isUserLoggedIn),
        map(isLogged => !!isLogged),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(isLogged => {
        if ((isLogged && this.appShowIfLogged) || (!this.appShowIfLogged && !isLogged)) {
          this.view.createEmbeddedView(this.template);
          this.hasView = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next({});
  }
}
