import { createReducer, on } from "@ngrx/store";
import { Menu } from "src/app/model/Menu";
import { MenuActions, ProductActions } from "../actions";


export interface MenuState {
  menu?: {
    menus: Menu[],
    totalMenus: number
  };
  editingMenu?: Menu;
}

const initEditingMenu = {
  color_title: "#F25116",
  color_category: "#F25116",
  color_price: "#F25116",
  color_product: "#F25116",
  color_ingredient: "#000000",
  font_title: "Montserrat",
  font_category: "Montserrat",
  font_price: "Montserrat",
  font_ingredient: "Montserrat",
  font_product: "Montserrat",
  name: "Menu",
  additional_info: "EMPTY"
} as Menu;

export const initialState: MenuState = {
  menu: {
    menus: [],
    totalMenus: 0
  }
};

export const menuReducer = createReducer(
  initialState,
  on(MenuActions.cleanMenus, (state, action) => ({
    ...state, menu: {
      menus: [],
      totalMenus: 0
    },
    editingMenu: undefined
  })),

  on(MenuActions.saveProductColor,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, color_product: action.color } as Menu
    })
  ),
  on(MenuActions.savePriceColor,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, color_price: action.color } as Menu
    })
  ),
  on(MenuActions.saveCategoryColor,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, color_category: action.color } as Menu
    })
  ),
  on(MenuActions.saveTitleColor,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, color_title: action.color } as Menu
    })
  ),
  on(MenuActions.saveIngredientsColor,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, color_ingredient: action.color } as Menu
    })
  ),
  on(MenuActions.saveProductFont,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, font_product: action.font } as Menu
    })
  ),
  on(MenuActions.savePriceFont,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, font_price: action.font } as Menu
    })
  ),
  on(MenuActions.saveCategoryFont,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, font_category: action.font } as Menu
    })
  ),
  on(MenuActions.saveTitleFont,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, font_title: action.font } as Menu
    })
  ),
  on(MenuActions.saveIngredientsFont,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, font_ingredient: action.font } as Menu
    })
  ),
  on(MenuActions.saveAdditionalInfo,
    (state, action) => ({
      ...state,
      editingMenu: { ...state.editingMenu, name: action.name, additional_info: action.info } as Menu
    })
  ),
  on(MenuActions.getMenuSuccess,
    (state, action) => ({ ...state, menu: { menus: action.menus, totalMenus: action.totalMenus } })
  ),
  on(MenuActions.removeMenuSuccess,
    (state, action) => ({ ...state, menu: { ...state.menu, menus: state.menu?.menus.filter((menuItem) => menuItem.id !== action.menuId) as Menu[], totalMenus: state.menu?.totalMenus as number } })
  ),
  on(MenuActions.completeEditingMenuSuccess,
    (state, action) => ({ ...state, editingMenu: initEditingMenu })
  ),
  on(MenuActions.createMenuSuccess,
    (state, action) => ({ ...state, editingMenu: initEditingMenu })
  ),
  on(MenuActions.loadMenuIntoMenuBuilder,
    (state, action) => ({ ...state, editingMenu: state.menu?.menus.find(m => m.id === action.menuId) })
  ),
  on(MenuActions.goToMenuBuilder, (state, action) => ({ ...state, editingMenu: initEditingMenu })),
);