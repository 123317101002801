import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpClientComponent } from './lp-client.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { LpClientRoutingModule } from './lp-client-routing.module';
import { TopNavModule } from 'src/app/components/navbar/top-nav/top-nav.module';
import { WhatIsSectionModule } from 'src/app/components/presentation/what-is-section/what-is-section.module';
import { WhySectionModule } from 'src/app/components/presentation/why-section/why-section.module';
import { WhatDoSectionModule } from 'src/app/components/presentation/what-do-section/what-do-section.module';
import { HowSectionModule } from 'src/app/components/presentation/how-section/how-section.module';
import { MockupSectionModule } from 'src/app/components/presentation/mockup-section/mockup-section.module';
import { RegistrationSectionModule } from 'src/app/components/presentation/registration-section/registration-section.module';



@NgModule({
  declarations: [LpClientComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    LpClientRoutingModule,
    TopNavModule,
    WhatIsSectionModule,
    WhySectionModule,
    WhatDoSectionModule,
    HowSectionModule,
    MockupSectionModule,
    RegistrationSectionModule,
  ],
  exports : [ LpClientComponent]
})
export class LpClientModule { }
