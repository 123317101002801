import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RestaurantService } from '../../providers/services/restaurant.service';
import { CompleteActions, ConfigurationActions, RestaurantActions, SystemActions } from '../actions';
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { Restaurant, UpdateRestaurantPayload } from '../../model/Restaurant';
import { Store } from '@ngrx/store';
import { RouterActions } from '../../router/store';
import { AuthActions } from 'src/app/guard/store';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { AppState } from '..';
import { RestaurantStage } from 'src/app/model/Configuration';
import { isUserLoggedIn } from '../selectors/restaurants.selectors';

@Injectable()
export class RestaurantEffects {
  constructor(private actions$: Actions, private restaurantService: RestaurantService, private store$: Store<AppState>) {}

  getProfileEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.getProfileRestaurant),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.auth.emailToken) {
          return this.restaurantService.getRestaurantProfile().pipe(
            map(({ restaurant }) => RestaurantActions.getProfileRestaurantSuccess({ restaurant })),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[getProfileEffect$ - getRestaurantProfile] error: ${JSON.stringify(err)}`);
              }
              return of(RestaurantActions.getProfileRestaurantFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(RestaurantActions.cleanRestaurantStatus());
        }
      })
    )
  );

  getProfileFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.getProfileRestaurantFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          // Restaurant exists on Cognito but must be configured
          return RestaurantActions.getRestaurantConfigurator();
        } else {
          const notification = new SystemNotification({ error, message: error.message });
          return SystemActions.SetNotification(notification);
        }
      })
    )
  );

  getProfileSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.getProfileRestaurantSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'login-success' }));
      })
    )
  );

  createRestaurantEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.createRestaurant),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.auth.emailToken) {
          return this.restaurantService
            .createRestaurant(
              {
                email: storeState.auth.emailToken,
                name: storeState.restaurantStore.restaurant!.name,
                address: storeState.restaurantStore.restaurant!.address,
                admin_name: storeState.restaurantStore.restaurant!.admin_name,
                phone_number: storeState.restaurantStore.restaurant!.phone_number,
                type_id: storeState.restaurantStore.restaurant!.type_id,
                city: storeState.restaurantStore.restaurant!.city,
                pin: storeState.restaurantStore.restaurant!.pin
              },
            )
            .pipe(
              map(({ restaurantid }) => {
                return RestaurantActions.createRestaurantSuccess({ restaurantid,
                  email: storeState.auth.emailToken!, 
                  name: storeState.restaurantStore.restaurant!.name,
                  address: storeState.restaurantStore.restaurant!.address,
                  admin_name: storeState.restaurantStore.restaurant!.admin_name,
                  phone_number: storeState.restaurantStore.restaurant!.phone_number,
                  type_id: storeState.restaurantStore.restaurant!.type_id,
                  city: storeState.restaurantStore.restaurant!.city,
                  stage_id: storeState.restaurantStore.restaurant!.stage_id,
                  pin: storeState.restaurantStore.restaurant!.pin
                });
              }),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[createRestaurantEffect$ - createRestaurant] error: ${JSON.stringify(err)}`);
                }
                return of(RestaurantActions.createRestaurantFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(RestaurantActions.cleanRestaurantStatus());
        }
      })
    )
  );

  createRestaurantSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.createRestaurantSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'restaurant-created' }));
      })
    )
  );

  createRestaurantFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.createRestaurantFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  updateRestaurantEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.updateRestaurant),
      withLatestFrom(this.store$.select(isUserLoggedIn)),
      switchMap(([action, restaurant]) => {
        if (restaurant) {
          const { address, city, email, admin_name, phone_number, pin, type_id, stage_id, currency_ids, payments_accepted } = restaurant;
          const restaurantPayload: Partial<UpdateRestaurantPayload> = { address, city, email, admin_name, phone_number, pin, type_id, stage_id, currency_ids, payments_accepted};
          
          return this.restaurantService.updateRestaurant(restaurant.id, restaurantPayload).pipe(
            map(() => RestaurantActions.updateRestaurantSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[updateRestaurantEffect$ - login] error: ${JSON.stringify(err)}`);
              }
              return of(RestaurantActions.updateRestaurantFailed({ error: new Error(err.error.name) }));
            })
          );
        }

        return of(RestaurantActions.updateRestaurantFailed({ error: new Error('Restaurant not defined') }));
      })
    )
  );

  getRestaurantProfileAfterGetRestaurantStagesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getRestaurantStagesSuccess),
      map(() => RestaurantActions.getProfileRestaurant())
    )
  );

  redirectToDashboardOrConfiguratorEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.getProfileRestaurantSuccess),
      withLatestFrom(this.store$),
      map(([action, storeState]) => {
        if (
        JSON.stringify(storeState.restaurantStore) !== "{}" && action.restaurant.stage_id &&
          storeState.configurationStore.restaurantStages?.find((el: RestaurantStage) => el.id === action.restaurant.stage_id)?.name ===
            'RESTAURANT_STAGE_5_RESTAURANT_PAYMENT'
        ) {
          return RestaurantActions.getRestaurantDashboard();
        } else {
          return RestaurantActions.getRestaurantConfigurator();
        }
      })
    )
  );

  redirectToDashboardEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.getRestaurantDashboard),
      mergeMap((action) => {
        return [CompleteActions.getInitAccountProfile(), RouterActions.go({ path: ['dashboard'] })];
      })
    )
  );

  redirectToConfiguratorEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.getRestaurantConfigurator),
      concatMap((action) => {
        return [CompleteActions.getInitAccountConfiguration(), RouterActions.go({ path: ['partner-configurator'] })];
      })
    )
  );

  removeRestaurantEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.removeRestaurant),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.restaurantService.removeRestaurant(storeState.restaurantStore.restaurant.id).pipe(
            map(() => RestaurantActions.removeRestaurantSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[removeRestaurantEffect$ - login] error: ${JSON.stringify(err)}`);
              }
              return of(RestaurantActions.removeRestaurantFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of();
        }
      })
    )
  );

  enableSumUpAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.enableSumUpAccount),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && action.code) {
          return this.restaurantService.enableSumUpAccount(storeState.restaurantStore.restaurant.id, action.code).pipe(
            map(() => RestaurantActions.enableSumUpAccountSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[enableSumUpAccountEffect$ - enableSumUpAccount] error: ${JSON.stringify(err)}`);
              }
              return of(RestaurantActions.enableSumUpAccountFailed({ error: new Error(err.error.name) }));
            })
          )
        } else {
          return of();
        }
      })
    )
  );

  enableSatispayAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.enableSatispayAccount),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && action.code) {
          return this.restaurantService.enableSatispayAccount(storeState.restaurantStore.restaurant.id, action.code).pipe(
            map(() => RestaurantActions.enableSatispayAccountSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[enableSatispayAccountEffect$ - enableSatispayAccount] error: ${JSON.stringify(err)}`);
              }
              return of(RestaurantActions.enableSatispayAccountFailed({ error: new Error(err.error.name) }));
            })
          )
        } else {
          return of();
        }
      })
    )
  );

  enableClassicAccountEffect$ = createEffect(() =>
  this.actions$.pipe(
    ofType(RestaurantActions.enableClassicAccount),
    withLatestFrom(this.store$),
    exhaustMap(([action, storeState]) => {
      if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && action.code) {
        return this.restaurantService.enableClassicAccount(storeState.restaurantStore.restaurant.id, action.code).pipe(
          map(() => RestaurantActions.enableClassicAccountSuccess()),
          catchError((err) => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[enableClassicAccountEffect$ - enableClassicAccount] error: ${JSON.stringify(err)}`);
            }
            return of(RestaurantActions.enableClassicAccountFailed({ error: new Error(err.error.name) }));
          })
        )
      } else {
        return of();
      }
    })
  )
);

  triggerLogoutAfterRestaurantRemoveEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantActions.removeRestaurantSuccess),
      map(() => {
        return AuthActions.logout();
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RestaurantActions.updateRestaurant,
        //RestaurantActions.getProfileRestaurant,
        RestaurantActions.removeRestaurant,
        RestaurantActions.createRestaurant,
        RestaurantActions.enableSumUpAccount,
        RestaurantActions.enableSatispayAccount,
        RestaurantActions.enableClassicAccount,
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RestaurantActions.removeRestaurantFailed,
        RestaurantActions.updateRestaurantFailed,
        // RestaurantActions.getProfileRestaurantFailed,
        RestaurantActions.updateRestaurantSuccess,
        RestaurantActions.removeRestaurantSuccess,
        // RestaurantActions.getProfileRestaurantSuccess,
        RestaurantActions.createRestaurantFailed,
        RestaurantActions.createRestaurantSuccess,
        RestaurantActions.enableSumUpAccountSuccess,
        RestaurantActions.enableSumUpAccountFailed,
        RestaurantActions.enableSatispayAccountSuccess,
        RestaurantActions.enableSatispayAccountFailed,
        RestaurantActions.enableClassicAccountSuccess,
        RestaurantActions.enableClassicAccountFailed,
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
