import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './';

export interface AuthState {
  accessToken?: string;
  emailToken?: string;
}

export const initialState: AuthState = {};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess,
    (state, action) => (
      {
        ...state,
        accessToken: action.auth.access_token,
        emailToken: action.auth.email
      })
  ),
  on(AuthActions.loginFailed,
    (state, action) => (
      {
        ...state,
        emailToken: action.email,
      })
  ),
  on(AuthActions.registerSuccess,
    (state, action) => (
      {
        ...state,
        emailToken: action.emailToken
      })
  ),
  on(AuthActions.logoutSuccess, state => (
    { ...state, emailToken: undefined, accessToken: undefined })
  ),
  on(AuthActions.recoverAccountSuccess, (state, action) => (
    { ...state, emailToken: action.email })
  ),
  on(AuthActions.saveAuth, (state, action) => (
    {
      ...state,
      accessToken: action.auth.accessToken,
      emailToken: action.auth.emailToken
    })
  ),
);

