<div id="cosa-fa"  class='what-do-container'>
  <img class="circle {{whatDoSectionConfiguration.whatDoInfo.positionCircle}}" src="{{whatDoSectionConfiguration.whatDoInfo.colorCircle}}" alt="">
  <div class="do-section-container">
    <div data-aos="fade-up" class="title">
      <h3 class="title" [style.color]="whatDoSectionConfiguration.whatDoInfo.colorTitle">{{ whatDoSectionConfiguration.whatDoInfo.title | translate }}</h3>
    </div>
    <div data-aos="fade-up" class="row nav-pills-navigation {{whatDoSectionConfiguration.whatDoInfo.colorBtnHover}} {{whatDoSectionConfiguration.whatDoInfo.rowDirection}} ">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="nav-pills-wrapper">
          <ul ngbNav #nav="ngbNav" type="pills"
            class="nav nav-pills vertical-pills nav-tabs">
            <li ngbNavItem *ngFor="let whatDo of whatDoSectionConfiguration.whatDo">
              <a ngbNavLink (click)="handleImage(whatDo.img)"> <span> {{ whatDo.btn | translate}} </span></a>
              <ng-template ngbNavContent class="tab-space">
                <div>
                  <div class="container">
                    <p>{{whatDo.par | translate}}</p>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="container-fluid">
          <img class="img-fluid" src="{{image}}" alt="What do icon">
        </div>
      </div>
    </div>

  </div>
</div>