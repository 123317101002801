import { createReducer, on } from "@ngrx/store";
import { CustomFlowActions } from "../actions";

export interface CustomFlowState {
    flows: {
        order_check: boolean,
        printer_check: boolean
    }
}

export const initialState: CustomFlowState = {
    flows: {
        order_check: false,
        printer_check: false
    }
};

export const customFlowReducer = createReducer(
    initialState,

    // Getting the custom flow state
    on(CustomFlowActions.getCustomFlow, (state) => ({
        ...state
    })),

    // Updating the order check in custom flow state
    on(CustomFlowActions.updateOrderCheckInCustomFlow, (state, action) => ({
        ...state,
        flows: {
            ...state.flows,
            order_check: action.order_check
        }
    })),

    // Updating the printer check in custom flow state
    on(CustomFlowActions.updatePrinterCheckInCustomFlow, (state, action) => ({
        ...state,
        flows: {
            ...state.flows,
            printer_check: action.printer_check,
        }
    })),

    on(CustomFlowActions.getCustomFlowSuccess, (state, action) => ({
        ...state,
        flows: action.customFlow.flows
    }))
);