import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationSnackbarComponent } from './notification-snackbar.component';



@NgModule({
  declarations: [NotificationSnackbarComponent],
	imports: [CommonModule, MatSnackBarModule],
	exports: [NotificationSnackbarComponent],
})
export class NotificationSnackbarModule { }
