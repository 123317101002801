import { createAction, props } from "@ngrx/store";
import { RestaurantImage } from "src/app/model/RestaurantImage";

export const cleanRestaurantImageStatus = createAction(
    "[RestaurantImage] Clean RestaurantImage Status"
);

export const getRestaurantImages = createAction(
    "[Restaurant Image] Get Restaurant Images"
);
export const getRestaurantImagesSuccess = createAction(
    "[Restaurant Image] Get Restaurant Images Success",
    props<{restaurantImages: RestaurantImage}>()
);
export const getRestaurantImagesFailed = createAction(
    "[Restaurant Image] Get Restaurant Images Failed",
    props<{ error: Error }>()
);

export const createRestaurantImages = createAction(
    "[Restaurant Image] Create Restaurant Images"
);
export const createRestaurantImagesSuccess = createAction(
    "[Restaurant Image] Create Restaurant Images Success",
    props<{restaurantImages: RestaurantImage}>()
);
export const createRestaurantImagesFailed = createAction(
    "[Restaurant Image] Create Restaurant Images Failed",
    props<{ error: Error }>()
);

export const deleteRestaurantImage = createAction(
    "[Restaurant Image] Delete Restaurant Image",
    props<{restaurantImageId: string}>()
);
export const deleteRestaurantImageSuccess = createAction(
    "[Restaurant Image] Delete Restaurant Image Success",
    props<{imageid: string}>()
);
export const deleteRestaurantImageFailed = createAction(
    "[Restaurant Image] Delete Restaurant Image Failed",
    props<{ error: Error }>()
);

export const saveRestaurantImagesBuffer = createAction(
    "[Restaurant Image] Save Restaurant Images Buffer",
    props<{imageBuffer: string[]}>()
);
export const cleanRestaurantImagesBuffer = createAction(
    "[Restaurant Image] Clean Restaurant Images Buffer"
);