import { createAction, props } from '@ngrx/store';
import { ProductImage } from 'src/app/model/ProductImage';

export const cleanProductImageStatus = createAction('[ProductImage] Clean ProductImage Status');

export const getProductsImagesSuccess = createAction('[ProductImage] Get Products Images Success', props<{ i: [{ images_urls: string[] }, string][] }>());

export const getProductImages = createAction('[Product Image] Get Product Images', props<{ productId: string }>());
export const getProductImagesSuccess = createAction('[Product Image] Get Product Images Success', props<{ productImages: ProductImage; productId: string }>());
export const getProductImagesFailed = createAction('[Product Image] Get Product Images Failed', props<{ error: Error }>());

export const createProductImages = createAction('[Product Image] Create Product Images', props<{ productId: string; images: Array<string> }>());
export const createProductImagesSuccess = createAction(
  '[Product Image] Create Product Images Success',
  props<{ productImages: ProductImage; productId: string }>()
);
export const createProductImagesFailed = createAction('[Product Image] Create Product Images Failed', props<{ error: Error }>());

export const deleteProductImage = createAction('[Product Image] Delete Product Image', props<{ productImageId: string; productId: string }>());
export const deleteProductImageSuccess = createAction('[Product Image] Delete Product Image Success', props<{ imageid: string; productImageId: string }>());
export const deleteProductImageFailed = createAction('[Product Image] Delete Product Image Failed', props<{ error: Error }>());

export const saveProductImagesBuffer = createAction('[Product Image] Save Product Images Buffer', props<{ imageBuffer: string[]; productImageId: string }>());
export const cleanProductImagesBuffer = createAction('[Product Image] Clean Product Images Buffer');
