import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { BookingSlot, CreateBookingSlot, CreateBookingSlotResponse, DeleteBookingResponse } from 'src/app/model/Booking';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BookingSlotService {
  constructor(private http: HttpClient) {}

  getBookingSlot(restaurantId: string, date?: string, guestsNumber?: string): Observable<{ booking_slots: BookingSlot[], hourly_slots: string[], free_tables: {hourly_slot: string, tables: string[]}[]}> {
    let params: HttpParams = new HttpParams();
    if(date && guestsNumber) {
      params = params.append('date', date);
      params = params.append('guests_number', guestsNumber)
    }
    return this.http.get<{ booking_slots: BookingSlot[], hourly_slots: string[], free_tables: {hourly_slot: string, tables: string[]}[]}>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/booking-slots`, {params});
  }

  createBookingSlot(restaurantId: string, payload: CreateBookingSlot): Observable<CreateBookingSlotResponse> {
    return this.http.post<CreateBookingSlotResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/booking-slots`, payload);
  }

  updateBookingSlot(restaurantId: string, bookingSlotId: string, payload: CreateBookingSlot): Observable<CreateBookingSlotResponse> {
    return this.http.patch<CreateBookingSlotResponse>(
      `${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/booking-slots/${bookingSlotId}`,
      payload
    );
  }

  deleteBookingSlot(restaurantId: string, bookingSlotId: string): Observable<DeleteBookingResponse> {
    return this.http.delete<DeleteBookingResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/booking-slots/${bookingSlotId}`);
  }

  copyBookingSlots(
    restaurantId: string,
    fromDayId: string,
    toDayId: string,
    bookingsSlots: BookingSlot[]
  ): Observable<CreateBookingSlotResponse[]> {
    const bs = bookingsSlots.filter((bs) => bs.week_day_id === fromDayId);
    return forkJoin(
      bs.map((b) => {
        return this.createBookingSlot(restaurantId, {
          end_time: b.end_time,
          start_time: b.start_time,
          week_day_id: toDayId,
        });
      })
    );
  }
}
