import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGMTranslateService } from 'src/app/features/translate/ngm-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private ngmTranslate: NGMTranslateService,
    public router: Router,
  ) {
  }
  linkHome = environment.linkHome

  date = new Date();
  languages: string[] = [
    'it',
    'en'
  ];
  isLanguageInitiated = false;
  languageGroup = new FormGroup({
    language: new FormControl('', Validators.required)
  });


  ngOnInit(): void {
    if (!this.isLanguageInitiated) {
      this.changeLanguage();
      this.isLanguageInitiated = true;
    }
  }

  changeLanguage(language?: string): void {
    this.ngmTranslate.setLanguage(language);
  }
}
