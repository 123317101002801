import {PageType} from '../../../utils/common-functions';
import { HowInfo, HowText } from '../../../model/LandingPage';

export interface HowSectionConfiguration {
  howText: HowText;
  howInfo: HowInfo[];
}

export const howSectionContentConfiguration: Map<PageType, HowSectionConfiguration> = new Map([

  ['RESTAURANT', {
    howText: {
      title: 'howSection.lp-r-how-section-title',
      par: 'howSection.lp-r-how-section-par',
      colorTitle: '#5b8c2a', /* lp-client - '#F25116' */
      pathImg: 'assets/img/qr-menu/LP-Restaurant/lprestaurantpath-green.svg',
      circleImg: 'lp-r-circle-img', /* lp-client - 'lp-c-circle-img' */
    },

    howInfo: [
      {
        img: 'assets/img/qr-menu/LP-Restaurant/howworks-1.svg',
        title: 'howSection.lp-r-how-section-subtitle1',
        position: '-30',
        positionX: 'positionR'
      },
      {
        img: 'assets/img/qr-menu/LP-Restaurant/howworks-2.svg',
        title: 'howSection.lp-r-how-section-subtitle2',
        position: '30',
        positionX: 'positionL'
      },
      {
        img: 'assets/img/qr-menu/LP-Restaurant/howworks-3.svg',
        title: 'howSection.lp-r-how-section-subtitle3',
        position: '10',
        positionX: 'positionR'
      },
      {
        img: 'assets/img/qr-menu/LP-Restaurant/howworks-4.svg',
        title: 'howSection.lp-r-how-section-subtitle4',
        position: '-50',
        positionX: 'positionL'
      }
    ]
  }],

  ['CLIENT', {
    howText: {
      title: 'howSection.lp-r-how-section-title',
      par: 'howSection.lp-c-how-section-par',
      colorTitle: '#F25116' /* lp-client - '#F25116' */,
      pathImg: 'assets/img/qr-menu/LP-Client/lpclientpath-red.svg',
      circleImg: 'lp-c-circle-img' /* lp-client - 'lp-c-circle-img' */,
    },

    howInfo: [
      {
        img: 'assets/img/qr-menu/LP-Client/howworks-1.svg',
        title: 'howSection.lp-c-how-section-subtitle1',
        position: '-30',
        positionX: 'positionR'
      },
      {
        img: 'assets/img/qr-menu/LP-Client/howworks-2.svg',
        title: 'howSection.lp-c-how-section-subtitle2',
        position: '30',
        positionX: 'positionL'
      },
      {
        img: 'assets/img/qr-menu/LP-Client/howworks-3.svg',
        title: 'howSection.lp-c-how-section-subtitle3',
        position: '10',
        positionX: 'positionR'
      },
      {
        img: 'assets/img/qr-menu/LP-Client/howworks-4.svg',
        title: 'howSection.lp-c-how-section-subtitle4',
        position: '-50',
        positionX: 'positionL'
      },
    ]
  }]

]);

