import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuAdditionalInfoComponent } from './menu-additional-info.component';
import { MenuAdditionalInfoRoutingModule } from './menu-additional-info-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [MenuAdditionalInfoComponent],
  imports: [
    CommonModule,
    MenuAdditionalInfoRoutingModule,
    TranslateModule,
    NgbModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [MenuAdditionalInfoComponent]
})
export class MenuAdditionalInfoModule { }
