import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, withLatestFrom, exhaustMap } from 'rxjs';
import { AppState } from '..';
import { CustomFlowActions, SystemActions } from '../actions';
import { CustomFlowService } from 'src/app/providers/services/custom-flow.service';

@Injectable()
export class CustomFlowEffects {
  constructor(private actions$: Actions, private customFlowService: CustomFlowService, private store$: Store<AppState>) { }

  getCustomFlowDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFlowActions.getCustomFlow),
    withLatestFrom(this.store$),
    exhaustMap(([action, store]) => {
        if(store.auth.accessToken && store.restaurantStore.restaurant) {
            return this.customFlowService.getCustomFlowData(store.restaurantStore.restaurant?.id).pipe(
                map((response) => {
                    return CustomFlowActions.getCustomFlowSuccess({customFlow: response})
                }),
                catchError((err) => of(CustomFlowActions.getCustomFlowFailed({error: new Error(err)})))
            )
        } else {
            return of(CustomFlowActions.getCustomFlowFailed({error: new Error("Generic Error")}))
        }
    })
  ))

  updateCustomFlowDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFlowActions.updateCustomFlow),
    withLatestFrom(this.store$),
    exhaustMap(([action, store]) => {
        if(store.auth.accessToken && store.restaurantStore.restaurant) {
            return this.customFlowService.updateCustomFlowData(store.restaurantStore.restaurant?.id, store.customFlowStore).pipe(
                map((_) => {
                    return CustomFlowActions.updateCustomFlowSuccess()
                }),
                catchError((err) => of(CustomFlowActions.updateCustomFlowFailed({error: new Error(err)}))
            ))
        } else {
            return of(CustomFlowActions.updateCustomFlowFailed({error: new Error("Generic Error")}))
        }
    })
  ))

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFlowActions.getCustomFlow,
        CustomFlowActions.updateCustomFlow
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFlowActions.getCustomFlowFailed,
        CustomFlowActions.getCustomFlowSuccess,
        CustomFlowActions.updateCustomFlowFailed,
        CustomFlowActions.updateCustomFlowSuccess
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
