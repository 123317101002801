import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-light',
  templateUrl: './navbar-light.component.html',
  styleUrls: ['./navbar-light.component.scss']
})
export class NavbarLightComponent {

  constructor() { }
}
