import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuColorPickerComponent } from './menu-color-picker.component';
import { MenuColorPickerRoutingModule } from './menu-color-picker-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorTwitterModule } from 'ngx-color/twitter';

@NgModule({
  declarations: [MenuColorPickerComponent],
  imports: [
    CommonModule,
    MenuColorPickerRoutingModule,
    TranslateModule,
    NgbModule,
    ColorTwitterModule
  ],
  exports: [MenuColorPickerComponent]
})
export class MenuColorPickerModule { }
