import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatIsSectionComponent } from './what-is-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TopNavModule } from '../../navbar/top-nav/top-nav.module';
import { NavbarUniteModule } from '../navbar-unite/navbar-unite.module';

@NgModule({
  declarations: [WhatIsSectionComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    TopNavModule,
    NavbarUniteModule
  ],
  exports: [WhatIsSectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WhatIsSectionModule { }
