import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { menuCreateRequest, menuCreateResponse, MenuGetResponse, RemoveMenuResponse, menuUpdateRequest } from "src/app/model/Menu";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(private http: HttpClient) {}

  createMenu(restaurantId: string, menu: menuCreateRequest): Observable<menuCreateResponse> {
    return this.http.post<menuCreateResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/menus`, menu);
  };

  getMenus(restaurantId: string): Observable<MenuGetResponse> {
    return this.http.get<MenuGetResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/menus`);
  };

  removeMenu(restaurantId: string, menuId: string): Observable<RemoveMenuResponse> {
    return this.http.delete<RemoveMenuResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/menus/${menuId}`);
  };

  updateMenu(restaurantId: string, menuId: string, menuPayload: menuUpdateRequest): Observable<menuCreateResponse> {
    return this.http.patch<menuCreateResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/menus/${menuId}`, menuPayload);
  };
}
