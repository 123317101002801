import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarResComponent } from './navbar-res.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../../utils/directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        TranslateModule,
        NgbModule,
        RouterModule,
        DirectivesModule,
        CommonModule
    ],
    declarations: [NavbarResComponent],
    exports: [NavbarResComponent],

    providers: []
})
export class NavbarResModule {

}
