import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-lp-client',
    templateUrl: './lp-client.component.html',
    styleUrls: ['./lp-client.component.scss'],
})

export class LpClientComponent {

    constructor() { }

    newsLetterFeature = environment.newsLetterFeature;

    goUp(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
