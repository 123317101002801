import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageType } from '../../../utils/common-functions';
import { TopNavConfiguration, topNavContentConfiguration } from '../../navbar/top-nav/top-nav.content-configuration';

@Component({
  selector: 'app-navbar-unite',
  templateUrl: './navbar-unite.component.html',
  styleUrls: ['./navbar-unite.component.scss']
})
export class NavbarUniteComponent implements OnInit {

  @Input() pageType!: PageType;
  public topNavConfiguration!: TopNavConfiguration;


  constructor(private router: Router) {}

  ngOnInit(): void {
    this.topNavConfiguration = topNavContentConfiguration.get(this.pageType) as TopNavConfiguration;
    // update variable animation avatar
    document.documentElement.style.setProperty('--top-cli-circle-open', this.topNavConfiguration.topCliCircleOpen);
    document.documentElement.style.setProperty('--top-res-circle-open', this.topNavConfiguration.topResCircleOpen);

  }

  goToHello(): void {
    this.router.navigateByUrl('/hello');
    window.scrollTo(0,0);
  }
}
