import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, withLatestFrom, mergeMap } from "rxjs/operators";
import { of } from 'rxjs';
import { MenuActions, ProductActions, SystemActions } from "../actions";
import { MenuService } from "../../providers/services/menu.service";
import { environment } from '../../../environments/environment';
import { Menu, menuUpdateRequest } from "src/app/model/Menu";
import { RouterActions } from "src/app/router/store";
import { SystemNotification } from "src/app/utils/system-notification.class";
import { AppState } from "..";

@Injectable()
export class MenuEffects {

  constructor(
    private actions$: Actions,
    private menuService: MenuService,
    private store$: Store<AppState>
  ) { }



  createMenuEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createMenu),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && storeState.menuStore.editingMenu) {
          return this.menuService.createMenu(storeState.restaurantStore.restaurant.id, {
            name: storeState.menuStore.editingMenu.name,
            color_category: storeState.menuStore.editingMenu.color_category,
            font_category: storeState.menuStore.editingMenu.font_category,
            color_ingredient: storeState.menuStore.editingMenu.color_ingredient,
            font_ingredient: storeState.menuStore.editingMenu.font_ingredient,
            color_price: storeState.menuStore.editingMenu.color_price,
            font_price: storeState.menuStore.editingMenu.font_price,
            color_product: storeState.menuStore.editingMenu.color_product,
            font_product: storeState.menuStore.editingMenu.font_product,
            color_title: storeState.menuStore.editingMenu.color_title,
            font_title: storeState.menuStore.editingMenu.font_title,
            additional_info: storeState.menuStore.editingMenu.additional_info
          }).pipe(
            map((menuResponse) => {
              return MenuActions.createMenuSuccess({ menuId: menuResponse.menuid });
            }),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[createMenuEffect$ - createMenuEffect] error: ${JSON.stringify(err)}`);
              }

              return of(MenuActions.createMenuFailed({ error: new Error(err.error.name) }));
            })
          )
        } else {
          return of(MenuActions.createMenuFailed({ error: new Error('GenericError') }))
        }
      })
    )
  );

  

  updateMenuEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.updateMenu),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && storeState.menuStore.editingMenu) {
          const menuStore = storeState.menuStore.editingMenu as Menu;
          const menuPayload: menuUpdateRequest = {} as menuUpdateRequest;


          menuPayload.color_category = menuStore.color_category;
          menuPayload.color_ingredient = menuStore.color_ingredient;
          menuPayload.color_price = menuStore.color_price;
          menuPayload.color_product = menuStore.color_product;
          menuPayload.color_title = menuStore.color_title;
          menuPayload.font_category = menuStore.font_category;
          menuPayload.font_ingredient = menuStore.font_ingredient;
          menuPayload.font_price = menuStore.font_price;
          menuPayload.font_product = menuStore.font_product;
          menuPayload.font_title = menuStore.font_title;
          menuPayload.additional_info = menuStore.additional_info;
          menuPayload.name = menuStore.name;
          return this.menuService.updateMenu(storeState.restaurantStore.restaurant.id, menuStore.id, menuPayload).pipe(
            map((_) => {
              return MenuActions.updateMenuSuccess({ menuId: menuStore.id });
            }),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[updateMenuEffect$ - updateMenuEffect] error: ${JSON.stringify(err)}`);
              }

              return of(MenuActions.updateMenuFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(MenuActions.updateMenuFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  completeUpdateMenuEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.completeEditingMenu),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && storeState.menuStore.editingMenu) {
          if(storeState.menuStore.editingMenu.id) {
            return of(MenuActions.updateMenu());
          } else {
            return of(MenuActions.createMenu());
          }
        } else {
          return of(MenuActions.completeEditingMenuFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  completeUpdateMenuSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createMenuSuccess, MenuActions.updateMenuSuccess),
      mergeMap(action => {
        return [
          MenuActions.getMenus(),
          RouterActions.go({ path: ['/dashboard/menu'] })
        ]
      })
    )
  );

  removeMenuEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.removeMenu),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && storeState.menuStore.menu) {
          return this.menuService.removeMenu(storeState.restaurantStore.restaurant.id, action.menuId)
            .pipe(
              map((_) => {
                return MenuActions.removeMenuSuccess({ menuId: action.menuId });
              }),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[removeMenuEffect$ - removeMenuEffect] error: ${JSON.stringify(err)}`);
                }

                return of(MenuActions.removeMenuFailed({ error: new Error(err.error.name) }));
              })
            )
        } else {
          return of(MenuActions.removeMenuFailed({ error: new Error('GenericError') }))
        }
      })
    )
  );

  getMenusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.getMenus),
      withLatestFrom(this.store$),
      exhaustMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.menuService.getMenus(storeState.restaurantStore.restaurant.id)
            .pipe(
              map((menuResponse) => {
                return MenuActions.getMenuSuccess({
                  menus: menuResponse.menus,
                  totalMenus: menuResponse.totalMenus
                });
              }),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[getMenusEffect$ - getMenusEffect] error: ${JSON.stringify(err)}`);
                }
                return of(MenuActions.getMenusFailed({ error: new Error(err.error.name) }));
              })
            )

        } else {
          return of(MenuActions.getMenusFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getMenusSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.getMenuSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'menus-get-success' }))
      })
    )
  );

  getMenuFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.getMenusFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return MenuActions.getMenuSuccess({ menus: [], totalMenus: 0 });
        }
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'menus-get-failed' }))
      }
      )
    )
  );

  goToMenuBuilderEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.goToMenuBuilder),
      mergeMap(_ => {
        return [
          RouterActions.go({ path: ['menu-builder'] })
        ]
      })
    )
  );

  goToEditMenuBuilderEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.gotToEditMenuBuilder),
      mergeMap(action => {
        return [
          MenuActions.loadMenuIntoMenuBuilder({ menuId: action.menuId }),
        ]
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MenuActions.getMenus,
        MenuActions.createMenu,
        MenuActions.updateMenu,
        MenuActions.removeMenu,
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MenuActions.getMenuSuccess,
        MenuActions.getMenusFailed,
        MenuActions.createMenuFailed,
        MenuActions.updateMenuFailed,
        MenuActions.removeMenuFailed,
        MenuActions.createMenuSuccess,
        MenuActions.updateMenuSuccess,
        MenuActions.removeMenuSuccess,
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
