import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonFunctions } from 'src/app/utils/common-functions';
import * as AuthActions from '../../../guard/store/auth.actions';
import { Store } from '@ngrx/store';
import {
  TopNavConfiguration,
  topNavContentConfiguration,
} from '../../../components/navbar/top-nav/top-nav.content-configuration';
import { AppState } from 'src/app/store';

@Component({
  selector: 'app-partner-login',
  templateUrl: './partner-login.component.html',
  styleUrls: ['./partner-login.component.scss']
})
export class PartnerLoginComponent implements OnInit {

  public topNavConfiguration!: TopNavConfiguration;
  inputs: any;
  focused = false;
  successMsg = false;
  loginForm!: FormGroup;
  submitted = false;
  hide = true;
  hideConfirm = true;

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {}

  get formControls(): {[form: string]: AbstractControl} { return this.loginForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.login();
    }
  }

  // federatedLogin(provider: ('Facebook' | 'Google')): void {
  //   this.store.dispatch(AuthActions.loginSocial({provider}));
  // }

  login(): void {
    const {email, password} = this.loginForm.value;
    this.store.dispatch(AuthActions.login({email, password}));
  }

  ngOnInit(): void {
    this.topNavConfiguration = topNavContentConfiguration.get('AUTH') as TopNavConfiguration;
    this.loginForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        Validators.maxLength(50)
      ]],
      password: ['', [
        Validators.required,
        CommonFunctions.patternValidator(/\d/, { hasNumber: true }),
        CommonFunctions.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CommonFunctions.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CommonFunctions.patternValidator(/[!@#$%^&]/, { hasSpecialCharacters: true }),
        CommonFunctions.patternValidator(/[a-z-A-Z-0-9-!@#$?%^&]{8,16}/, { minMaxLength: true })
      ]],
    });
  }

}
