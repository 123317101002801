import { Component, Input, OnInit } from '@angular/core';
import { PageType } from '../../../utils/common-functions';
import { MockupSectionConfiguration, mockupSectionContentConfiguration } from './mockup-section.content-configuration';

@Component({
  selector: 'app-mockup-section',
  templateUrl: './mockup-section.component.html',
  styleUrls: ['./mockup-section.component.scss']
})
export class MockupSectionComponent implements OnInit {

  constructor() { }

  @Input() pageType!: PageType;
  public mockupSectionConfiguration!: MockupSectionConfiguration;

  ngOnInit(): void {
    this.mockupSectionConfiguration = mockupSectionContentConfiguration.get(this.pageType) as MockupSectionConfiguration;
  }

}
