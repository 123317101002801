import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { iif, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { getEmailToken, getToken } from 'src/app/guard/store/auth.selectors';
import { AppState } from 'src/app/store';
import { getRestaurantId } from 'src/app/store/selectors/restaurants.selectors';
import { environment } from 'src/environments/environment';
import packageInfo from '../../../../package.json';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // se ci sono rotte che bypassano l'aggiunta degli headers gestirlo con un IF
    // nell'else fare controllo su configuration o il resto per mettere la stringa corretta all'interno del clientrequestid
    const skipCheckRoutes = new Array<string>();
    if (skipCheckRoutes.includes(req.url)) {
      return next.handle(req);
    } else {
      return of(null).pipe(
        withLatestFrom(iif(() => this.isMailRoute(req), this.store.select(getEmailToken), this.store.select(getRestaurantId)), this.store.select(getToken)),
        map(([init, clientrequestid, token]) => !clientrequestid ? { clientrequestid: '', token } : { clientrequestid, token }),
        switchMap(({ clientrequestid, token }) => {
          const clonedReq = req.clone({
            headers: new HttpHeaders({
              clientrequestid,
              'content-type': 'application/json',
              'x-originator-type': 'web',
              'x-client-app-version': packageInfo.version,
              'x-client-app-name': 'lingui-ngm',
              Authorization: `Bearer ${token}`,
              countrycode: navigator.language,
            }),
          });

          return next.handle(clonedReq);
        })
      );
    }
  }

  private isMailRoute = (request: HttpRequest<any>): boolean => {
    const mailRoutes: Array<string> = [`${environment.backendHost}/v1/dashboard/restaurants`];
    return request.url.includes('configurations') || (mailRoutes.includes(request.url) && ['GET', 'POST'].includes(request.method));
  };
}
