import { CanActivate, Router } from '@angular/router';
import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable()
export class PlaformEnabledGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(): boolean {
    if (!environment.isPlatformEnabled) {
      this.router.navigateByUrl('/presentation');
    }
    return environment.isPlatformEnabled;
  }

}
