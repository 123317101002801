<img class="circle" src='../../../../assets/img/qr-menu/LP-Restaurant/circle-r.svg' alt="Avatar Restaurant Linuguì">
<div data-aos="fade-up" class="offers-section-container">
  <h3 id="piani" class="offers-section-title">{{offersSectionConfiguration.section_heading.title | translate}}</h3>
  <p class="offers-section-par">{{offersSectionConfiguration.section_heading.description | translate}}</p>
</div>
<div class="container-fluid">
  <!-- <div class="row justify-content-between"> -->
    <app-subscription-cards></app-subscription-cards>
    <!-- <div class='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'></div>
    <div class='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'></div> -->
  <!-- </div> -->
</div>

