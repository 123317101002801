import { Component, Input, OnInit } from '@angular/core';
import { PageType } from '../../../utils/common-functions';
import { OffersSectionConfiguration } from './models/offers-card.interface';
import { OFFERS } from './offers-section.content-configuration';

@Component({
  selector: 'app-offers-section',
  templateUrl: './offers-section.component.html',
  styleUrls: ['./offers-section.component.scss']
})
export class OffersSectionComponent implements OnInit {
  @Input() pageType!: PageType;
  offersSectionConfiguration!: OffersSectionConfiguration;

  ngOnInit(): void {
    this.offersSectionConfiguration = OFFERS.get(this.pageType) as OffersSectionConfiguration;
  }
}
