<section class="flex-start-center p-3 w-100">
  <article data-aos="fade-down" class="{{cardClasses}} w-100">
    <section class="card-header" *ngIf="cardHeaderTpl">
      <ng-container *ngTemplateOutlet="cardHeaderTpl"></ng-container>
    </section>
    <section class="card-body" *ngIf="cardBodyTpl">
      <ng-container *ngTemplateOutlet="cardBodyTpl"></ng-container>
    </section>
    <section class="card-actions flex-center" *ngIf="cardActionsTpl">
      <ng-container *ngTemplateOutlet="cardActionsTpl"></ng-container>
    </section>
  </article>
</section>