import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ConfigurationState } from "../reducers/configuration.reducers";
import { RestaurantState } from "../reducers/restaurants.reducers";

const selectConfigurationState = createFeatureSelector<ConfigurationState>('configurationStore');
const selectRestaurantState = createFeatureSelector<RestaurantState>('restaurantStore');

export const getBookingStatuses = createSelector(selectConfigurationState, (state: ConfigurationState) => state.bookingStatuses);
export const getClientStatuses = createSelector(selectConfigurationState, (state: ConfigurationState) => state.clientStatuses);
export const getCurrencyTypes = createSelector(selectConfigurationState, (state: ConfigurationState) => state.currencyTypes);
export const getIngredientTypes = createSelector(selectConfigurationState, (state: ConfigurationState) => state.ingredientTypes);
export const getOrderStatuses = createSelector(selectConfigurationState, (state: ConfigurationState) => state.orderStatuses);
export const getRestaurantProductCategories = createSelector(selectConfigurationState, (state: ConfigurationState) => state.restaurantProductCategories);
export const getRestaurantSecondaryProductCategories = createSelector(selectConfigurationState, (state: ConfigurationState) => state.restaurantSecondaryProductCategories);
export const getRestaurantStages = createSelector(selectConfigurationState, (state: ConfigurationState) => state.restaurantStages);
export const getRestaurantTypes = createSelector(selectConfigurationState, (state: ConfigurationState) => state.restaurantTypes);
export const getSpecialtyTypes = createSelector(selectConfigurationState, (state: ConfigurationState) => state.specialtyTypes);
export const getTableStatuses = createSelector(selectConfigurationState, (state: ConfigurationState) => state.tableStatuses);
export const getWeekDays = createSelector(selectConfigurationState, (state: ConfigurationState) => state.weekDays);
export const getMeasureUnitTypes = createSelector(selectConfigurationState, (state: ConfigurationState) => state.measureUnitTypes);
export const getRestaurantSumUpAccountLinking = createSelector(selectConfigurationState, (state: ConfigurationState) => state.sumupAccountLinking);

export const getRestaurantTypeNameById = createSelector(selectConfigurationState, selectRestaurantState, (configurationState: ConfigurationState, restaurantState: RestaurantState) => configurationState.restaurantTypes?.find(restaurantType => restaurantType.id === restaurantState.restaurant?.type_id)?.name);
export const getRestaurantStageNameById = (props?: {stageId: string}) => createSelector(selectConfigurationState, selectRestaurantState, (configurationState: ConfigurationState, restaurantState: RestaurantState) => configurationState.restaurantStages?.find(restaurantStage => restaurantStage.id === restaurantState.restaurant?.stage_id)?.name);
export const getRestaurantStageIdByName = (props: {stageName: string}) => createSelector(selectConfigurationState, (state: ConfigurationState) => state.restaurantStages?.find(stage => stage.name === props.stageName)?.id);

export const getWeekDayIdByName = (props: {dayName: string}) => createSelector(selectConfigurationState, (state: ConfigurationState) => state.weekDays!.find(weekDay => weekDay.name === props.dayName)?.id);
export const getWeekDayNameByWeekDayId = (props: {weekDayId: string}) => createSelector(selectConfigurationState, (state: ConfigurationState) => state.weekDays?.find(weekDay => weekDay.id === props.weekDayId)?.name);

export const getOrdersExportUrl = createSelector(selectConfigurationState, (state: ConfigurationState) => state.exportOrdersUrl);
export const getInvoicesExportUrl = createSelector(selectConfigurationState, (state: ConfigurationState) => state.exportInvoicesUrl);


