import { createAction, props } from "@ngrx/store";
import * as Configuration from '../../model/Configuration';

export const getExportInvoices = createAction(
    "[Configuration] Get Export Invoices",
    props<{restaurantid: string, billid?: string}>()
)
export const getExportInvoicesSuccess = createAction(
    "[Configuration] Get Export Invoices Success",
    props<{url: string}>()
);
export const getExportInvoicesFailed = createAction(
    "[Configuration] Get Export Invoices Failed",
    props<{ error: Error }>()
);

export const getExportOrders = createAction(
    "[Configuration] Get Export Orders",
    props<{tableid?: string, statusid?: string, categoryid?: string}>()
);
export const getExportOrdersSuccess = createAction(
    "[Configuration] Get Export Orders Success",
    props<{url: string}>()
);
export const getExportOrdersFailed = createAction(
    "[Configuration] Get Export Orders Failed",
    props<{ error: Error }>()
);

export const cleanConfigurationStatus = createAction(
    "[Configuration] Clean Configuration Status"
);

export const getBookingStatuses = createAction(
    "[Configuration] Get Booking Statuses"
);
export const getBookingStatusesSuccess = createAction(
    "[Configuration] Get Booking Statuses Success",
    props<{bookingStatuses: Configuration.BookingStatusConfiguration[]}>()
);
export const getBookingStatusesFailed = createAction(
    "[Configuration] Get Booking Statuses Failed",
    props<{ error: Error }>()
);

export const getClientStatuses = createAction(
    "[Configuration] Get Client Statuses"
);
export const getClientStatusesSuccess = createAction(
    "[Configuration] Get Client Statuses Success",
    props<{clientStatuses: Configuration.ClientStatusConfiguration[]}>()
);
export const getClientStatusesFailed = createAction(
    "[Configuration] Get Client Statuses Failed",
    props<{ error: Error }>()
);

export const getCurrencyTypes = createAction(
    "[Configuration] Get Currency Types"
);
export const getCurrencyTypesSuccess = createAction(
    "[Configuration] Get Currency Types Success",
    props<{currencyTypes: Configuration.CurrencyTypeConfiguration[]}>()
);
export const getCurrencyTypesFailed = createAction(
    "[Configuration] Get Currency Types Failed",
    props<{ error: Error }>()
);

export const getMeasureUnitTypes = createAction(
    "[Configuration] Get MeasureUnit Types"
);
export const getMeasureUnitTypesSuccess = createAction(
    "[Configuration] Get MeasureUnit Types Success",
    props<{measureUnitTypes: Configuration.MeasureUnitConfiguration[]}>()
);
export const getMeasureUnitTypesFailed = createAction(
    "[Configuration] Get MeasureUnit Types Failed",
    props<{ error: Error }>()
);

export const getIngredientTypes = createAction(
    "[Configuration] Get Ingredient Types"
);
export const getIngredientTypesSuccess = createAction(
    "[Configuration] Get Ingredient Types Success",
    props<{ingredientTypes: Configuration.IngredientTypeConfiguration[]}>()
);
export const getIngredientTypesFailed = createAction(
    "[Configuration] Get Ingredient Types Failed",
    props<{ error: Error }>()
);

export const getOrderStatuses = createAction(
    "[Configuration] Get Order Statuses"
);
export const getOrderStatusesSuccess = createAction(
    "[Configuration] Get Order Statuses Success",
    props<{orderStatuses: Configuration.OrderStatusConfiguration[]}>()
);
export const getOrderStatusesFailed = createAction(
    "[Configuration] Get Order Statuses Failed",
    props<{ error: Error }>()
);

export const getRestaurantProductCategories = createAction(
    "[Configuration] Get Restaurant Product Categories"
);
export const getRestaurantProductCategoriesSuccess = createAction(
    "[Configuration] Get Restaurant Product Categories Success",
    props<{restaurantProductCategories: Configuration.RestaurantProductCategory[], restaurantSecondaryProductCategories: Configuration.RestaurantProductCategory[]}>()
);
export const getRestaurantProductCategoriesFailed = createAction(
    "[Configuration] Get Restaurant Product Categories Failed",
    props<{ error: Error }>()
);

export const getRestaurantStages = createAction(
    "[Configuration] Get Restaurant Stages"
);
export const getRestaurantStagesSuccess = createAction(
    "[Configuration] Get Restaurant Stages Success",
    props<{restaurantStages: Configuration.RestaurantStage[]}>()
);
export const getRestaurantStagesFailed = createAction(
    "[Configuration] Get Restaurant Stages Failed",
    props<{ error: Error }>()
);

export const getRestaurantTypes = createAction(
    "[Configuration] Get Restaurant Types"
);
export const getRestaurantTypesSuccess = createAction(
    "[Configuration] Get Restaurant Types Success",
    props<{restaurantTypes: Configuration.RestaurantTypeConfiguration[]}>()
);
export const getRestaurantTypesFailed = createAction(
    "[Configuration] Get Restaurant Types Failed",
    props<{ error: Error }>()
);

export const getSpecialtyTypes = createAction(
    "[Configuration] Get Specialty Types"
);
export const getSpecialtyTypesSuccess = createAction(
    "[Configuration] Get Specialty Types Success",
    props<{specialtyTypes: Configuration.SpecialtyTypeConfiguration[]}>()
);
export const getSpecialtyTypesFailed = createAction(
    "[Configuration] Get Specialty Types Failed",
    props<{ error: Error }>()
);

export const getTableStatuses = createAction(
    "[Configuration] Get Table Statuses"
);
export const getTableStatusesSuccess = createAction(
    "[Configuration] Get Table Statuses Success",
    props<{tableStatuses: Configuration.TableStatusConfiguration[]}>()
);
export const getTableStatusesFailed = createAction(
    "[Configuration] Get Table Statuses Failed",
    props<{ error: Error }>()
);

export const getWeekDays = createAction(
    "[Configuration] Get Week Days"
);
export const getWeekDaysSuccess = createAction(
    "[Configuration] Get Week Days Success",
    props<{weekDays: Configuration.WeekDayConfiguration[]}>()
);
export const getWeekDaysFailed = createAction(
    "[Configuration] Get Week Days Failed",
    props<{ error: Error }>()
);