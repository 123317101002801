<div class="dialog-container">
  <h3 mat-dialog-title>{{data.title}}</h3>
  <section mat-dialog-content class="mb-4">
    <div class="dialog-description-container">
      <span class="dialog-description" [innerHtml]="data.description"></span>
    </div>
  </section>
  <section class="flex-center" mat-dialog-actions>
    <div class="row g-2 actions-row flex-center">
      <div class="col-6">
        <button mat-raised-button class="cancel-btn" (click)="close()">
          <span><i class="fas fa-times me-2" aria-hidden="true"></i> ANNULLA </span>
        </button>
      </div>
      <div class="col-6">
        <button mat-raised-button class="confirm-btn" (click)="confirm()" cdkFocusInitial>
          <span><i class="fas fa-{{data.confirmButtonIcon ? data.confirmButtonIcon : 'check'}} me-2" aria-hidden="true"></i> CONFERMA </span>
        </button>
      </div>
    </div>
  </section>
</div>