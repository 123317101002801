<div class="bigger-screen-needed">
  <h1>Per il builder è necessario uno schermo più grande!</h1>
</div>
<div class="menu-builder-container">
  <div class="instruments">
    <div class="small-nav-dashboard">
      <img src="assets/img/qr-menu/logo_desk.png" alt="Logo Linguì"/>
      <p>Configura il tuo menù digitale</p>
    </div>
    <div class="menu-builder-sidebar">
      <div class="logo" style="cursor: pointer;" (click)="goToDashboard()">
        <img class="res-avatar" src='assets/img/qr-menu/ChoosePage/cook2.png' />
      </div>
      <div data-aos="fade-right" class="options">
        <div #activeButton class="active-button"></div>

        <div class="option-item" (click)="activateInfos()">
          <div class="option-item-icon">
            <i class="fas fa-info"></i>
          </div>
        </div>
        <div class="option-item" (click)="activateProducts()">
          <div class="option-item-icon">
            <i class="fas fa-pizza-slice"></i>
          </div>
        </div>
        <!-- <div class="option-item" (click)="activateColors()">
          <div class="option-item-icon">
            <i class="fas fa-palette"></i>
          </div>
        </div>
        <div class="option-item" (click)="activateFonts()">
          <div class="option-item-icon">
            <i class="fas fa-font"></i>
          </div>
        </div> -->
      </div>
    </div>


    <div #optionBlock class="option-block">

      <div #infoBlock class="category-block">
        <app-menu-additional-info [editingMenu]="editingMenu$ | async"></app-menu-additional-info>
      </div>
      <div #productBlock class="category-block">
        <app-menu-product [editingMenu]="editingMenu$ | async" [products]="products$ | async" [productsOfMenu]="productsOfMenu$ | async"></app-menu-product>
      </div>
      <div #colorBlock class="category-block">
        <app-menu-color-picker [editingMenu]="editingMenu$ | async"></app-menu-color-picker>
      </div>
      <div #fontBlock class="category-block">
        <app-menu-font-picker [editingMenu]="editingMenu$ | async"></app-menu-font-picker>
      </div>

    </div>

  </div>

  <div class="container-fluid">

    <div class="small-nav-icons">
      <i class="fas fa-question"></i>
      <i (click)="goToMenuDashboard()" class="fas fa-arrow-left"></i>
    </div>
    <div (click)="completeUpdateMenu()" class="container-fluid container-save">
      <button class="btn btn-success btn-icon">
        <i class="fas fa-check"></i>
      </button>
      <div class="save-menu-description">
        <p>Salva Menù</p>
      </div>

    </div>
    <app-menu-preview></app-menu-preview>
  </div>
</div>
