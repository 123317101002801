import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BookingResponse, CreateBooking, CreateBookingResponse, UpdateBooking } from 'src/app/model/Booking';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private http: HttpClient) {}

  getBookings(restaurantId: string, date: { startDate: string; endDate: string }, bookingStatus: string, page: number): Observable<BookingResponse> {
    let params: HttpParams = new HttpParams();
    if (date.startDate !== '0' && date.endDate !== '0') {
      const searchingStartDate = new Date(date.startDate).toISOString();
      const searchingEndDate = new Date(date.endDate).toISOString();

      params = params.append('date_booked_start', searchingStartDate).append('date_booked_end', searchingEndDate);
    }

    if (page !== 0) {
      params = params.append('limit', 20).append('offset', (page - 1) * 20);
    }

    if (bookingStatus !== 'NOT_DEFINED') {
      params = params.append('statusid', bookingStatus);
    }

    return this.http.get<BookingResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/bookings`, { params });
  }

  createBooking(restaurantId: string, payload: CreateBooking): Observable<CreateBookingResponse> {
    return this.http.post<CreateBookingResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/bookings`, payload);
  }

  updateBooking(restaurantId: string, bookingId: string, payload: UpdateBooking): Observable<CreateBookingResponse> {
    return this.http.patch<CreateBookingResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/bookings/${bookingId}`, payload);
  }

  updateBookingStatus(restaurantId: string, bookingId: string, status_id: string): Observable<CreateBookingResponse> {
    const body = { status_id };
    return this.http.patch<CreateBookingResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/bookings/${bookingId}/status`, body);
  }
}
