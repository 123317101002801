import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Ingredient, Product } from 'src/app/model/Product';
import { ProductActions } from 'src/app/store/actions';
import * as MenuSelectors from '../../../store/selectors/menu.selectors';
import * as ProductSelectors from '../../../store/selectors/product.selectors';
import * as ConfigurationSelectors from '../../../store/selectors/configuration.selectors';
import * as RestaurantSelectors from '../../../store/selectors/restaurants.selectors';
import * as ProductImageSelectors from '../../../store/selectors/product-image.selectors';


import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { CurrencyTypeConfiguration, RestaurantProductCategory, IngredientTypeConfiguration } from 'src/app/model/Configuration';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store';
import { ConfirmDialogData } from 'src/app/model/core/confirm-dialog-data.interface';


@Component({
  selector: 'app-menu-preview',
  templateUrl: './menu-preview.component.html',
  styleUrls: ['./menu-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuPreviewComponent {

  @ViewChild('additionalInfo') additionalInfo!: ElementRef;
  toggleAdditionalInfo = false;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog
  ) {

  }

  menuEditingMenu$ = this.store.select(MenuSelectors.getEditingMenu);
  productsOfMenu$ = this.store.select(ProductSelectors.selectProductsOfMenu);
  productCategories$ = this.store.select(ConfigurationSelectors.getRestaurantProductCategories);
  restaurantCurrency$ = this.store.select(RestaurantSelectors.getRestaurantCurrencies);
  currencies$ = this.store.select(ConfigurationSelectors.getCurrencyTypes);
  ingredientTypes$ = this.store.select(ConfigurationSelectors.getIngredientTypes);
  restaurantName$ = this.store.select(RestaurantSelectors.getRestaurantName);
  

  menuCurrentCategory!: string;

  toggleAdditioanlInfo(): void {
    if (!this.additionalInfo.nativeElement.classList.contains('menu-additional-info-active')) {
      this.additionalInfo.nativeElement.classList.add('menu-additional-info-active');
    } else {
      this.additionalInfo.nativeElement.classList.remove('menu-additional-info-active');

    }
  }

  getCurrencySymbol(currency_id: string, currencies: CurrencyTypeConfiguration[]): string {
    const currency = currencies.find(c => c.id === currency_id);
    if (currency) {
      return currency.symbol;
    }
    return 'NOT_DEFINED';
  }

  // getImagesByProductId(productId: string): Observable<{imagesUrls:string[] | undefined, imagesBuffers: string[] | undefined}>{
  //   return this.store.select(ProductImageSelectors.getImageBuffersAndImageUrlsByProductId({productId: productId}));
  // }

  // createImages(imagesState: {imagesUrls:string[] | undefined, imagesBuffers: string[] | undefined}, restaurantId: string): string[] {
  //   const images: string[] = [];
  //   if (imagesState.imagesUrls && imagesState.imagesUrls.length > 0) {
  //     for (const imgUrl of imagesState.imagesUrls) {
  //       images.push(imgUrl);
  //     }
  //     return images;
  //   }
  //   if (imagesState.imagesBuffers && imagesState.imagesBuffers.length > 0) {
  //     for (const imgBuffer of imagesState.imagesBuffers) {
  //       images.push(imgBuffer);
  //     }
  //     return images;
  //   }
  //   return [];
  // }

  getProductCategories(products: Product[] | undefined): string[] {
    if (products === undefined) {
      return [];
    }
    const categories: string[] = Array.from(new Set(products.map(product => product.category_id)));
    if (products.length === 1) {
      this.menuCurrentCategory = categories[0];
    }
    return categories;
  }

  setMenuCurrentCategory(category: string): void {
    this.menuCurrentCategory = category;
  }

  removeProduct(product: Product): void {
    const data: ConfirmDialogData = {
      title: 'Conferma eliminazione prodotto',
      description: 'Sei sicuro di voler eliminare il prodotto ' + product.name + ' ?'
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, { data });
    dialogRef.afterClosed()
      .subscribe({
        next: (dialogValue) => {
          if (dialogValue) {
            this.store.dispatch(ProductActions.removeProductAssociation({ productId: product.id }));
          }
        }
      });
  }

  getProductCategoryName(categoryId: string, categories: RestaurantProductCategory[]): string {
    const category = categories.find(category => category.id === categoryId);
    if (category) {
      return category.description.toUpperCase();
    }
    return ''
  }

  getFileUrl(categoryId: string, categories: RestaurantProductCategory[]): string {
    const category = categories.find(category => category.id === categoryId);
    if (category) {
      return `../../../../assets/img/qr-menu/categories/${category.description.toLowerCase()}.png`;
    }
    return ''
  }

  getFrozenIngredients(product: Product, byFrozen: boolean): string {
    return product.ingredients.filter(i => i.frozen === byFrozen).map(i => i.ingredient_name).join(', ');
  }

  getIngredientName(ingredientId: string, ingredients: IngredientTypeConfiguration[]): string {
    const ingredient = ingredients.find(i => i.id === ingredientId);
    if (ingredient) {
      return ingredient.name;
    }
    return 'NOT_DEFINED';
  }
    
}

