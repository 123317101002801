import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from 'src/app/model/core/confirm-dialog-data.interface';

@Component({
    selector: 'app-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent {
    @HostListener('keydown.esc')
    public onEsc(): void {
        this.close();
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData, public dialogRef: MatDialogRef<DialogConfirmComponent>) {}

    close = (): void => this.dialogRef.close(false);
    confirm = (): void => this.dialogRef.close(true);
}
