import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, switchMap, map, of, withLatestFrom } from "rxjs";
import { AppState } from "..";
import { StatusBooking } from "src/app/model/Booking";
import { environment } from "src/environments/environment";
import { BookingActions, SystemActions } from "../actions";
import { BookingService } from "../../providers/services/bookings.service";
import { SystemNotification } from "src/app/utils/system-notification.class";

@Injectable()
export class BookingsEffects {
    constructor(
        private actions$: Actions,
        private bookingService: BookingService,
        private store$: Store<AppState>
    ) { }

    getBookingsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookings),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {

                if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
                    return this.bookingService.getBookings(storeState.restaurantStore.restaurant.id, { startDate: storeState.bookingStore.searchByDate.startDate, endDate: storeState.bookingStore.searchByDate.endDate }, storeState.bookingStore.searchByBookingStatus, storeState.bookingStore.currentPage)
                        .pipe(
                            map((bookingResponse) => {
                                return BookingActions.getBookingsSuccess({ bookingResponse: bookingResponse })
                            }),
                            catchError((err) => {
                                if (environment.env === 'DEV' || environment.env === 'INT') {
                                    console.error(`[getBookingsEffect$ - getBookingsEffect] error: ${JSON.stringify(err)}`)
                                }

                                return of(BookingActions.getBookingsFailed({ error: new Error(err.error.name) }));
                            })
                        )
                } else {
                    return of(BookingActions.getBookingsFailed({ error: new Error('GenericError') }));
                }
            })
        )
    );

    getBookingsSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsSuccess),
            map(() => {
                return SystemActions.SetNotification(new SystemNotification({ message: 'booking-get-success' }))
            })
        )
    );

    getBookingsFailedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsFailed),
            map(({ error }) => {
                if (error.message === 'NOT_FOUND') {
                    return BookingActions.getBookingsSuccess({ bookingResponse: { bookings: [], totalBookings: 0 } });
                }
                return SystemActions.SetNotification(new SystemNotification({ error, message: 'booking-get-failed' }))
            })
        )
    );

    getBookingsByDateEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByDate),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
                    return this.bookingService.getBookings(storeState.restaurantStore.restaurant.id, { startDate: storeState.bookingStore.searchByDate.startDate, endDate: storeState.bookingStore.searchByDate.endDate }, storeState.bookingStore.searchByBookingStatus, storeState.bookingStore.currentPage)
                        .pipe(
                            map((bookingResponse) => {
                                return BookingActions.getBookingsByDateSuccess({ bookingResponse: bookingResponse })
                            }),
                            catchError((err) => {
                                if (environment.env === 'DEV' || environment.env === 'INT') {
                                    console.error(`[getBookingsByDateEffect$ - getBookingsByDateEffect] error: ${JSON.stringify(err)}`)
                                }

                                return of(BookingActions.getBookingsByDateFailed({ error: new Error(err.error.name) }));
                            })
                        )
                } else {
                    return of(BookingActions.getBookingsByDateFailed({ error: new Error('GenericError') }));
                }
            })
        )
    );

    getBookingsByDateSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByDateSuccess),
            map(() => {
                return SystemActions.SetNotification(new SystemNotification({ message: 'booking-get-by-date-success' }))
            })
        )
    );

    getBookingsByDateFailedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByDateFailed),
            map(({ error }) => {
                if (error.message === 'NOT_FOUND') {
                    return BookingActions.getBookingsByDateSuccess({ bookingResponse: { bookings: [], totalBookings: 0 } });
                }
                return SystemActions.SetNotification(new SystemNotification({ error, message: 'booking-get-by-date-failed' }))
            })
        )
    );

    getBookingsByPageEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByPage),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
                    return this.bookingService.getBookings(storeState.restaurantStore.restaurant.id, { startDate: storeState.bookingStore.searchByDate.startDate, endDate: storeState.bookingStore.searchByDate.endDate }, storeState.bookingStore.searchByBookingStatus, storeState.bookingStore.currentPage)
                        .pipe(
                            map((bookingResponse) => {
                                return BookingActions.getBookingsByPageSuccess({ bookingResponse: bookingResponse })
                            }),
                            catchError((err) => {
                                if (environment.env === 'DEV' || environment.env === 'INT') {
                                    console.error(`[getBookingsByPageEffect$ - getBookingsByPageEffect] error: ${JSON.stringify(err)}`)
                                }

                                return of(BookingActions.getBookingsByPageFailed({ error: new Error(err.error.name) }));
                            })
                        )
                } else {
                    return of(BookingActions.getBookingsByPageFailed({ error: new Error('GenericError') }));
                }
            })
        )
    );

    getBookingsByPageSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByPageSuccess),
            map(() => {
                return SystemActions.SetNotification(new SystemNotification({ message: 'booking-get-by-page-success' }))
            })
        )
    );

    getBookingsByPageFailedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByPageFailed),
            map(({ error }) => {
                if (error.message === 'NOT_FOUND') {
                    return BookingActions.getBookingsByPageSuccess({ bookingResponse: { bookings: [], totalBookings: 0 } });
                }
                return SystemActions.SetNotification(new SystemNotification({ error, message: 'booking-get-by-page-failed' }))
            })
        )
    );

    getBookingsByBookingStatusEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByBookingStatus),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
                    return this.bookingService.getBookings(storeState.restaurantStore.restaurant.id, { startDate: storeState.bookingStore.searchByDate.startDate, endDate: storeState.bookingStore.searchByDate.endDate }, storeState.bookingStore.searchByBookingStatus, storeState.bookingStore.currentPage)
                        .pipe(
                            map((bookingResponse) => {
                                return BookingActions.getBookingsByBookingStatusSuccess({ bookingResponse: bookingResponse })
                            }),
                            catchError((err) => {
                                if (environment.env === 'DEV' || environment.env === 'INT') {
                                    console.error(`[getBookingsByBookingStatusEffect$ - getBookingsByBookingStatusEffect] error: ${JSON.stringify(err)}`)
                                }

                                return of(BookingActions.getBookingsByBookingStatusFailed({ error: new Error(err.error.name) }));
                            })
                        )
                } else {
                    return of(BookingActions.getBookingsByBookingStatusFailed({ error: new Error('GenericError') }));
                }
            })
        )
    );

    getBookingsByBookingStatusSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByBookingStatusSuccess),
            map(() => {
                return SystemActions.SetNotification(new SystemNotification({ message: 'booking-get-by-booking-status-success' }))
            })
        )
    );

    getBookingsByBookingStatusFailedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.getBookingsByBookingStatusFailed),
            map(({ error }) => {
                if (error.message === 'NOT_FOUND') {
                    return BookingActions.getBookingsByBookingStatusSuccess({ bookingResponse: { bookings: [], totalBookings: 0 } });
                }
                return SystemActions.SetNotification(new SystemNotification({ error, message: 'booking-get-by-booking-status-failed' }))
            })
        )
    );

    createBookingEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.createBooking),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
                    return this.bookingService.createBooking(storeState.restaurantStore.restaurant.id, action.payload)
                        .pipe(
                            map((bookingResponse) => {
                                return BookingActions.createBookingSuccess()
                            }),
                            catchError((err) => {
                                if (environment.env === 'DEV' || environment.env === 'INT') {
                                    console.error(`[createBookingEffect$ - createBookingEffect] error: ${JSON.stringify(err)}`)
                                }

                                return of(BookingActions.createBookingFailed({ error: new Error(err.error.name) }));
                            })
                        )
                } else {
                    return of(BookingActions.createBookingFailed({ error: new Error('GenericError') }));
                }
            })
        )
    );

    createBookingSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.createBookingSuccess),
            map(() => {
                return SystemActions.SetNotification(new SystemNotification({ message: 'booking-create-success' }))
            })
        )
    );

    createBookingFailedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.createBookingFailed),
            map(({ error }) => {
                return SystemActions.SetNotification(new SystemNotification({ error, message: 'booking-create-failed' }))
            })
        )
    );


    updateBookingEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.updateBooking),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
                    return this.bookingService.updateBooking(storeState.restaurantStore.restaurant.id, action.bookingId, action.payload)
                        .pipe(
                            map((bookingResponse) => {
                                return BookingActions.updateBookingSuccess({ bookingId: action.bookingId, statusBooking: '' as unknown as StatusBooking })
                            }),
                            catchError((err) => {
                                if (environment.env === 'DEV' || environment.env === 'INT') {
                                    console.error(`[updateBookingEffect$ - updateBookingEffect] error: ${JSON.stringify(err)}`)
                                }

                                return of(BookingActions.updateBookingFailed({ error: new Error(err.error.name) }));
                            })
                        )
                } else {
                    return of(BookingActions.updateBookingFailed({ error: new Error('GenericError') }));
                }
            })
        )
    );

    updateBookingSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.updateBookingSuccess),
            map(() => {
                return SystemActions.SetNotification(new SystemNotification({ message: 'booking-update-success' }))
            })
        )
    );

    updateBookingFailedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.updateBookingFailed),
            map(({ error }) => {
                return SystemActions.SetNotification(new SystemNotification({ error, message: 'booking-update-failed' }))
            })
        )
    );

    updateBookingStatusEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.updateBookingStatus),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
                    return this.bookingService.updateBookingStatus(storeState.restaurantStore.restaurant.id, action.bookingId, action.statusBookingId)
                        .pipe(
                            map((bookingResponse) => {
                                return BookingActions.updateBookingStatusSuccess({ bookingId: action.bookingId, statusBookingId: action.statusBookingId })
                            }),
                            catchError((err) => {
                                if (environment.env === 'DEV' || environment.env === 'INT') {
                                    console.error(`[updateBookingStatusEffect$ - updateBookingStatusEffect] error: ${JSON.stringify(err)}`)
                                }

                                return of(BookingActions.updateBookingStatusFailed({ error: new Error(err.error.name) }));
                            })
                        )
                } else {
                    return of(BookingActions.updateBookingStatusFailed({ error: new Error('GenericError') }));
                }
            }
            )
        )
    );

    updateBookingStatusSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.updateBookingStatusSuccess),
            map(() => {
                return SystemActions.SetNotification(new SystemNotification({ message: 'booking-update-booking-status-success' }))
            })
        )
    );

    updateBookingStatusFailedEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingActions.updateBookingStatusFailed),
            map(({ error }) => {
                return SystemActions.SetNotification(new SystemNotification({ error, message: 'booking-update-booking-status-failed' }))
            })
        )
    );

    startLoading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                BookingActions.getBookings,
                BookingActions.updateBooking,
                BookingActions.updateBookingStatus,
                BookingActions.createBooking,
                BookingActions.getBookingsByBookingStatus,
                BookingActions.getBookingsByDate,
                BookingActions.getBookingsByPage
            ),
            map(() => SystemActions.StartLoading())
        )
    );

    stopLoading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                BookingActions.getBookingsSuccess,
                BookingActions.getBookingsFailed,
                BookingActions.updateBookingSuccess,
                BookingActions.updateBookingFailed,
                BookingActions.updateBookingStatusSuccess,
                BookingActions.updateBookingStatusFailed,
                BookingActions.createBookingSuccess,
                BookingActions.createBookingFailed,
                BookingActions.getBookingsByBookingStatusSuccess,
                BookingActions.getBookingsByBookingStatusFailed,
                BookingActions.getBookingsByDateSuccess,
                BookingActions.getBookingsByDateFailed,
                BookingActions.getBookingsByPageSuccess,
            ),
            map(() => SystemActions.StopLoading())
        )
    );
}