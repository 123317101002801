import {PageType} from '../../../utils/common-functions';
import { WhyParam } from '../../../model/LandingPage';

export interface WhySectionConfiguration {
  whyParam: WhyParam;
}

export const whySectionContentConfiguration: Map<PageType, WhySectionConfiguration> = new Map([

  ['RESTAURANT', {
    whyParam: {
      title: 'whySection.lp-r-why-section-title',
      par: 'whySection.lp-r-why-section-par',
      img: 'assets/img/qr-menu/LP-Restaurant/why.svg',  /* lp-client - 'assets/img/qr-menu/LP - Client/why.svg' */
      rowDirection: 'row',  /* lp-client - row-reverse */
      circleImg: 'lp-r-circle-img', /* lp-client - lp-c-circle-img */
      backgroundColor: 'lp-r-banner-background', /* lp-client - lp-c-circle-img */
      bannerClass: 'banner-dim-r'
    }
  }],

  ['CLIENT', {
    whyParam: {
      title: 'whySection.lp-r-why-section-title',
      par: 'whySection.lp-c-why-section-par',
      img: 'assets/img/qr-menu/LP-Client/why.svg' /* lp-client - 'assets/img/qr-menu/LP - Client/why.svg' */,
      rowDirection: 'row-reverse' /* lp-client - row-reverse */,
      circleImg: 'lp-c-circle-img' /* lp-client - lp-c-circle-img */,
      backgroundColor: 'lp-c-banner-background',
      bannerClass: 'banner-dim-c'
    }
  }]

]);

