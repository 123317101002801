import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessagingActions, PreferenceActions, SystemActions } from '../actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { CommonFunctions } from 'src/app/utils/common-functions';
import { PreferenceService } from '../../providers/services/preference.service';
import { AppState } from '..';

@Injectable()
export class PreferenceEffects {
  constructor(private actions$: Actions, private preferenceService: PreferenceService, private store$: Store<AppState>) {}

  // Preferences

  getRestaurantPreferencesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferenceActions.getRestaurantPreferences),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.preferenceService.getRestaurantPreferences().pipe(
            map((preferences) => PreferenceActions.getRestaurantPreferencesSuccess({ preferences })),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[getRestaurantPreferencesEffect$ - getRestaurantPreferencesEffect] error: ${JSON.stringify(err)}`);
              }
              return of(PreferenceActions.getRestaurantPreferencesFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(PreferenceActions.getRestaurantPreferencesFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  triggerRequestTokenEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferenceActions.getRestaurantPreferencesFailed, PreferenceActions.getRestaurantPreferencesSuccess),
      map(() => MessagingActions.requestToken())
    )
  );

  triggerCreateRestaurantPreferencesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.requestTokenSuccess),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (
          storeState.auth.accessToken &&
          storeState.restaurantStore.restaurant &&
          storeState.messagingStore.token !== 'NOT_DEFINED' && storeState.messagingStore.token !== 'ERROR_TOKEN' &&
          (storeState.preferenceStore.preferences.length === 0 || !storeState.preferenceStore.preferences.some((p) => p.deviceToken === storeState.messagingStore.token))
        ) {
          return [
            PreferenceActions.createRestaurantPreferences({
              preferences: {
                deviceToken: storeState.messagingStore.token,
                notificationPush: true,
                preferences: {
                  booking: true,
                  orders: true,
                  campaigns: true,
                },
                localeApplication: localStorage.getItem('locale') ?? CommonFunctions.getDefaultLanguage(),
              },
            }),
          ];
        } else {
          return [];
        }
      })
    )
  );

  createRestaurantPreferencesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferenceActions.createRestaurantPreferences),
      withLatestFrom(this.store$),
      switchMap(([{ preferences }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.preferenceService.createRestaurantPreferences(preferences).pipe(
            map(() => PreferenceActions.createRestaurantPreferencesSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[createRestaurantPreferences$ - createRestaurantPreferences] error: ${JSON.stringify(err)}`);
              }
              return of(PreferenceActions.createRestaurantPreferencesFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(PreferenceActions.createRestaurantPreferencesFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  triggerGetRestaurantPreferencesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferenceActions.createRestaurantPreferencesSuccess),
      map(() => PreferenceActions.getRestaurantPreferences())
    )
  );

  updateRestaurantPreferencesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferenceActions.updateRestaurantPreferences),
      withLatestFrom(this.store$),
      switchMap(([{ deviceId, preferences }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.preferenceService.updateRestaurantPreferences(deviceId, preferences).pipe(
            map(() => PreferenceActions.updateRestaurantPreferencesSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[updateRestaurantPreferences$ - updateRestaurantPreferences] error: ${JSON.stringify(err)}`);
              }
              return of(PreferenceActions.updateRestaurantPreferencesFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(PreferenceActions.updateRestaurantPreferencesFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  removeRestaurantPreferencesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferenceActions.removeRestaurantPreferences),
      withLatestFrom(this.store$),
      switchMap(([{ deviceId }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.preferenceService.removeRestaurantPreferences(deviceId).pipe(
            map(() => PreferenceActions.removeRestaurantPreferencesSuccess()),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[removeRestaurantPreferences$ - removeRestaurantPreferences] error: ${JSON.stringify(err)}`);
              }
              return of(PreferenceActions.removeRestaurantPreferencesFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(PreferenceActions.removeRestaurantPreferencesFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  updateRestaurantLanguageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferenceActions.updateRestaurantLanguage),
      withLatestFrom(this.store$),
      switchMap(([{ localeApplication }, storeState]) => {
        if (
          storeState.auth.accessToken &&
          storeState.restaurantStore.restaurant &&
          storeState.preferenceStore.preferences[0].localeApplication !== localeApplication &&
          storeState.preferenceStore.preferences[0].deviceId !== 'undefined'
        ) {
          return this.preferenceService.updateRestaurantLanguage({ localeApplication }).pipe(
            map(() => {
              return PreferenceActions.updateRestaurantLanguageSuccess();
            }),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[updateRestaurantLanguage$ - updateRestaurantLanguage] error: ${JSON.stringify(err)}`);
              }
              return of(PreferenceActions.updateRestaurantLanguageFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of();
        }
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PreferenceActions.getRestaurantPreferences,
        PreferenceActions.createRestaurantPreferences,
        PreferenceActions.updateRestaurantPreferences,
        PreferenceActions.removeRestaurantPreferences
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PreferenceActions.getRestaurantPreferencesSuccess,
        PreferenceActions.createRestaurantPreferencesSuccess,
        PreferenceActions.updateRestaurantPreferencesSuccess,
        PreferenceActions.removeRestaurantPreferencesSuccess,
        PreferenceActions.getRestaurantPreferencesFailed,
        PreferenceActions.createRestaurantPreferencesFailed,
        PreferenceActions.updateRestaurantPreferencesFailed,
        PreferenceActions.removeRestaurantPreferencesFailed
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
