import { createReducer, on } from '@ngrx/store';
import { CompleteActions, PreferenceActions } from '../actions';
import { CommonFunctions } from 'src/app/utils/common-functions';
import { PreferencesResponse } from 'src/app/model/Preference';

export interface PreferenceState {
  preferences: PreferencesResponse[];
}

export const initialState: PreferenceState = {
  preferences: [
    {
      deviceToken: 'undefined',
      deviceId: 'undefined',
      notificationPush: false,
      timestamp: 0,
      preferences: {
        orders: false,
        booking: false,
        campaigns: false,
      },
      localeApplication: localStorage.getItem('locale') ?? CommonFunctions.getDefaultLanguage(),
    },
  ],
};

export const preferenceReducer = createReducer(
  initialState,
  on(PreferenceActions.cleanPreferences, (state, action) => ({
    ...state,
    preferences: [
      {
        deviceToken: 'undefined',
        deviceId: 'undefined',
        notificationPush: false,
        timestamp: 0,
        preferences: {
          orders: false,
          booking: false,
          campaigns: false,
        },
        localeApplication: localStorage.getItem('locale') ?? CommonFunctions.getDefaultLanguage(),
      },
    ],
  })),
  on(PreferenceActions.getRestaurantPreferencesSuccess, (state, action) => ({ ...state, preferences: action.preferences as PreferencesResponse[] })),
  on(PreferenceActions.updateRestaurantLanguage, (state, action) => ({
    ...state,
    preferences: state.preferences.map((pref: PreferencesResponse) => {
      return { ...pref, localeApplication: action.localeApplication };
    }),
  }))
);
