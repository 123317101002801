<div class="how-section-background"  >
  <div class="how-section-container">
    <h3 id="come-funziona" data-aos="fade-up" class="how-section-title" [style.color]="howSectionConfiguration.howText.colorTitle">{{ howSectionConfiguration.howText.title | translate}} </h3>
    <p data-aos="fade-up" class="how-section-par" >{{howSectionConfiguration.howText.par | translate}} </p>
  </div>
  <span class="how-section-line"></span>
  <div class="how-section-path row" style="position: relative;">
    <div class="how-section-images"> <img src="{{howSectionConfiguration.howText.pathImg}}" alt="Cos'è Linuguì"> </div>
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 circles-position {{info.positionX}}" [style.top]="info.position" *ngFor="let info of howSectionConfiguration.howInfo">
      <div data-aos="zoom-in" class="how-section-img {{howSectionConfiguration.howText.circleImg}}" ><img class="img-mask" src="{{info.img}}" alt="Mask Linuguì" ></div>
      <h6 class="how-section-txt" [style.color]="howSectionConfiguration.howText.colorTitle">{{ info.title | translate}}</h6>
    </div>
  </div>
</div>
