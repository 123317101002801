import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as AuthActions from 'src/app/guard/store/auth.actions';
import { environment } from '../../../../environments/environment';
import { PageType } from '../../../utils/common-functions';
import { topNavContentConfiguration, TopNavConfiguration } from './top-nav.content-configuration';
import * as RestaurantSelectors from '../../../store/selectors/restaurants.selectors';
import * as AuthSelectors from '../../../guard/store/auth.selectors';
import * as ConfigurationSelectors from '../../../store/selectors/configuration.selectors';
import { AppState } from 'src/app/store';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  public topNavConfiguration!: TopNavConfiguration;
  @Input() pageType!: PageType;

  isPlatformEnabled!: boolean;
  hamburger = {
    img: 'assets/img/qr-menu/LP-Restaurant/hamburger.svg',
    title: 'lp-r-what-is-section-hamburger',
  };

  fork = {
    img: 'assets/img/qr-menu/LP-Restaurant/fork.svg',
    title: 'lp-r-what-is-section-fork',
  };

  constructor( public router: Router, private store: Store<AppState>) {

  }

  restaurantStage$ = this.store.select(RestaurantSelectors.getRestaurantStage);
  authToken$ = this.store.select(AuthSelectors.getToken);

  ngOnInit(): void {
    this.isPlatformEnabled = environment.isPlatformEnabled;
    this.topNavConfiguration = topNavContentConfiguration.get(this.pageType) as TopNavConfiguration;
  }

  getStageName(stageId: string): Observable<string | undefined> {
    return this.store.select(ConfigurationSelectors.getRestaurantStageNameById({ stageId }));
  }


  scrollToElement(link: string): void {
    this.toggleMenu();
    if (link === 'aboutus') {
      this.router.navigateByUrl('/aboutus');
      window.scrollTo(0,0);
    } else if (link === '/restaurant') {
      this.router.navigateByUrl('/presentation');
      window.scrollTo(0,0);
    } else if (link === 'home') {
      this.router.navigateByUrl('/restaurant');
      window.scrollTo(0,0);
    }

    const element = document.querySelector(`#${link}`);
  
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  openMenu = () => {
    this.toggleMenu();
  }


  @HostListener('document:click', ['$event'])
  onClickedOutside(event: Event): void {
    const elementId = (event.target as Element).id;
    if (elementId === 'img-burger') {
      return;
    }
    const navBar = document.getElementById('img-burger') as Element;
    if (navBar.getAttribute('src') === 'assets/img/qr-menu/LP-Restaurant/fork.svg') {
      this.topNavConfiguration.toggleMenu = !this.topNavConfiguration.toggleMenu;
      this.topNavConfiguration.dirtyMenuClasses = 'avatar-banner-r-responsive res-closing-menu';
      this.topNavConfiguration.dirtyMenuBanner = 'avatar-circle-responsive avatar-restaurant-closed';
    }

  }

  toggleMenu() {

    this.topNavConfiguration.toggleMenu = !this.topNavConfiguration.toggleMenu;
    this.topNavConfiguration.dirtyMenuClasses = this.topNavConfiguration.toggleMenu ?
      this.topNavConfiguration.dirtyMenuClassOpened
      : this.topNavConfiguration.dirtyMenuClassClosing;
    this.topNavConfiguration.dirtyMenuBanner = this.topNavConfiguration.toggleMenu ?
      this.topNavConfiguration.dirtyMenuBannerOpening
      : this.topNavConfiguration.dirtyMenuBannerClosing;
  }
}
