import { createAction, props } from '@ngrx/store';
import { CreateProductEvent, UpdateProductEvent } from 'src/app/features/dashboard/products-dashboard/models/product-events.interface';
import { Product } from 'src/app/model/Product';

export const cleanProductStatus = createAction('[Product] Clean Product Status');
export const getProducts = createAction('[Product] Get Products', props<{ limit: number; offset: number }>());
export const getProductsSuccess = createAction('[Product] Get Products Success', props<{ products: Product[]; totalProducts: number }>());
export const getProductsFailed = createAction('[Product] Get Products Failed', props<{ error: Error }>());

export const getProductsOfMenu = createAction('[Product] Get Products Of Menu');
export const getProductsOfMenuSuccess = createAction('[Product] Get Products Of Menu Success', props<{ products: Product[] }>());
export const getProductsOfMenuFailed = createAction('[Product] Get Products Of Menu Failed', props<{ error: Error }>());

export const createProduct = createAction('[Product] Create Product', props<CreateProductEvent>());
export const createProductSuccess = createAction(
    '[Product] Create Product Success'
    // props<{product: Product}>()
);
export const createProductFailed = createAction('[Product] Create Product Failed', props<{ error: Error }>());

export const updateProduct = createAction('[Product] Update Product', props<UpdateProductEvent>());
export const updateProductSuccess = createAction(
    '[Product] Update Product Success'
    // props<{product: Product}>()
);
export const updateProductFailed = createAction('[Product] Update Product Failed', props<{ error: Error }>());

export const deleteProduct = createAction('[Product] Delete Product', props<{ productId: string }>());
export const deleteProductSuccess = createAction('[Product] Delete Product Success', props<{ productId: string }>());
export const deleteProductFailed = createAction('[Product] Delete Product Failed', props<{ error: Error }>());

export const associateProduct = createAction('[Product] Associate Product', props<{ menuId: string, productId: string }>());
export const associateProductSuccess = createAction('[Product] Associate Product Success', props<{ menuId: string, productId: string }>());
export const associateProductFailed = createAction('[Product] Associate Product Failed', props<{ error: Error }>());

export const saveProductToProductOfMenu = createAction('[Product] Save Product To Product Of Menu', props<{ products: Product[] }>());

export const clearProductOfMenu = createAction('[Product] Clear Product Of Menu');

export const loadAssociatedProductsOfMenuSuccess = createAction('[Product] Load Associated Products Of Menu Success', props<{ products: Product[] }>());
export const loadAssociatedProductsOfMenuFailed = createAction('[Product] Load Associated Products Of Menu Failed', props<{ error: Error }>());

export const removeProductAssociation = createAction('[Product] Remove Product Association', props<{ productId: string }>());
