import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RestaurantImageService {
  constructor(private http: HttpClient) {}

  getRestaurantImage(restaurantId: string): Observable<{ images_urls: string[] }> {
    return this.http.get<{ images_urls: string[] }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/images`);
  }

  createRestaurantImage(restaurantId: string, images: string[]): Observable<{ images_urls: string[] }> {
    return this.http.post<{ images_urls: string[] }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/images`, { images });
  }

  deleteRestaurantImage(restaurantId: string, imageId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/images/${imageId}`);
  }
}
