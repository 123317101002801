import { PageType } from '../../../utils/common-functions';
import { OffersSectionConfiguration } from './models/offers-card.interface';

export const OFFERS: Map<PageType, OffersSectionConfiguration> = new Map<PageType, OffersSectionConfiguration>([
  [
    'RESTAURANT',
    {
      section_heading: {
        title: 'offersSection.lp-r-offers-section-title',
        description: 'offersSection.lp-r-offers-section-par',
      },
      cards: [
        {
          card_classes: 'basic',
          card_heading: { title: 'BASIC', subtitle: 'offersSection.lp-r-card-subtitle', price: '' },
          card_options: [
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par2', active: true },
            { description: 'offersSection.lp-r-card-par3', active: true },
            { description: 'offersSection.lp-r-card-par4', active: true },
            { description: 'offersSection.lp-r-card-par5', active: true },
            { description: 'offersSection.lp-r-card-par6', active: true },
            { description: 'offersSection.lp-r-card-par1', active: false },
            { description: 'offersSection.lp-r-card-par1', active: false },
            { description: 'offersSection.lp-r-card-par1', active: false },
            { description: 'offersSection.lp-r-card-par1', active: false },
          ],
          card_actions: [{ label: 'offersSection.lp-r-card-cta' }],
        },
        {
          card_classes: 'plus',
          card_heading: { title: 'PLUS', subtitle: 'offersSection.lp-r-card-subtitle', price: '' },
          card_options: [
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par2', active: true },
            { description: 'offersSection.lp-r-card-par3', active: true },
            { description: 'offersSection.lp-r-card-par4', active: true },
            { description: 'offersSection.lp-r-card-par5', active: true },
            { description: 'offersSection.lp-r-card-par6', active: true },
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par1', active: false },
            { description: 'offersSection.lp-r-card-par1', active: false },
          ],
          card_actions: [{ label: 'offersSection.lp-r-card-cta' }],
        },
        {
          card_classes: 'premium',
          card_heading: { title: 'PREMIUM', subtitle: 'offersSection.lp-r-card-subtitle', price: '' },
          card_options: [
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par2', active: true },
            { description: 'offersSection.lp-r-card-par3', active: true },
            { description: 'offersSection.lp-r-card-par4', active: true },
            { description: 'offersSection.lp-r-card-par5', active: true },
            { description: 'offersSection.lp-r-card-par6', active: true },
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par1', active: true },
            { description: 'offersSection.lp-r-card-par1', active: true },
          ],
          card_actions: [{ label: 'offersSection.lp-r-card-cta' }],
        },
      ],
    },
  ],
]);
