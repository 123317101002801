import { Component, Input, OnInit } from '@angular/core';
import { PageType } from '../../../utils/common-functions';
import {
  RegistrationSectionConfiguration,
  registrationSectionContentConfiguration,
} from './registration-section.content-configuration';

@Component({
  selector: 'app-registration-section',
  templateUrl: './registration-section.component.html',
  styleUrls: ['./registration-section.component.scss']
})
export class RegistrationSectionComponent implements OnInit {

  constructor() { }

  @Input() pageType!: PageType;
  public registrationSectionConfiguration!: RegistrationSectionConfiguration;

  ngOnInit(): void {
    this.registrationSectionConfiguration = registrationSectionContentConfiguration.get(this.pageType) as RegistrationSectionConfiguration;
  }

}
