import {PageType} from '../../../utils/common-functions';
import { WhatDoInfo, WhatDo } from '../../../model/LandingPage';

export interface WhatDoSectionConfiguration {
  whatDo: WhatDo[];
  whatDoInfo: WhatDoInfo;
}

export const whatDoContentConfiguration: Map<PageType, WhatDoSectionConfiguration> = new Map([

  ['RESTAURANT', {
    whatDoInfo: {
      title: 'whatDoSection.lp-r-do-section-title',
      colorTitle: '#5b8c2a', /* lp-client - '#F25116' */
      colorBtn: 'lp-r-what-do-btn', /* lp-client - 'lp-c-what-do-btn' */
      colorBtnHover: 'lp-r-whatdo-style', /* lp-client - 'lp-c-whatdo-style' */
      colorCircle: 'assets/img/qr-menu/LP-Restaurant/circle-r.svg',
      positionCircle: 'pos-circle-r',
      rowDirection: 'row-direction-r'
    },
    whatDo: [
      {
        btn: 'whatDoSection.lp-r-do-section-btn1',
        par: 'whatDoSection.lp-r-do-section-par1',
        img: 'assets/img/qr-menu/LP-Restaurant/what-1.svg'
      },
      {
        btn: 'whatDoSection.lp-r-do-section-btn2',
        par: 'whatDoSection.lp-r-do-section-par2',
        img: 'assets/img/qr-menu/LP-Restaurant/what-2.svg'
      },
      {
        btn: 'whatDoSection.lp-r-do-section-btn3',
        par: 'whatDoSection.lp-r-do-section-par3',
        img: 'assets/img/qr-menu/LP-Restaurant/what-3.svg'
      }
    ]
  }],

  ['CLIENT', {
    whatDoInfo: {
      title: 'whatDoSection.lp-r-do-section-title',
      colorTitle: '#F25116' /* lp-client - '#F25116' */,
      colorBtn: 'lp-c-what-do-btn' /* lp-client - 'lp-c-what-do-btn' */,
      colorBtnHover: 'lp-c-whatdo-style' /* lp-client - 'lp-c-whatdo-style' */,
      colorCircle: 'assets/img/qr-menu/LP-Client/circle-c.svg',
      positionCircle: 'pos-circle-c',
      rowDirection: 'row-direction-c'
    },

    whatDo: [
      {
        btn: 'whatDoSection.lp-c-do-section-btn1',
        par: 'whatDoSection.lp-c-do-section-par1',
        img: 'assets/img/qr-menu/LP-Client/what-1.svg',
      },
      {
        btn: 'whatDoSection.lp-c-do-section-btn2',
        par: 'whatDoSection.lp-c-do-section-par2',
        img: 'assets/img/qr-menu/LP-Client/what-2.svg',
      },
      {
        btn: 'whatDoSection.lp-c-do-section-btn3',
        par: 'whatDoSection.lp-c-do-section-par3',
        img: 'assets/img/qr-menu/LP-Client/what-3.svg',
      },
    ]
  }]

]);

