import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: '../nav/nav.component.html',
  styleUrls: ['./nav.component.scss'],

})
export class NavComponent  {

  constructor(){  }

  linkHome = environment.linkHome
  window:boolean = false;
  fork:string = 'navbar-toogler-fork';
  hamburger:string = 'navbar-toogler-hamburger'
  backgroundColor:string = 'bg-orange'
  logo:string = '../../../assets/img/qr-menu/logoWhite.png'
  backGroundButton:string = 'bg-green'
  getProperties(e: any) {
    // Icon change
    if(e.window == false) {
      this.window = true
    } else {
      this.window = false
    }
  }

}
