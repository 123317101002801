import { createReducer, on } from "@ngrx/store";
import { Order, OrderResponse } from "src/app/model/Order";
import { CompleteActions, MessagingActions, OrderActions } from "../actions";
import { NotificationItem } from "./messaging.reducers";

export interface OrderState {
  ordersStatus: OrderResponse,
  currentPage: number;
  searchByOrderStatus?: string;
  searchByProductCategory: string | undefined;
}

export const initialState: OrderState = {
  ordersStatus: {orders: [], totalOrders: 0},
  currentPage: 0,
  searchByOrderStatus: undefined,
  searchByProductCategory: undefined
}

export const ordersReducer = createReducer(
  initialState,
  on(OrderActions.cleanOrders, (state, action) => ({
    ...state, ordersStatus: {orders: [], totalOrders: 0}, currentPage: 0, searchByOrderStatus: undefined, searchByProductCategory: 'null'
  })),
  on(OrderActions.getOrdersSuccess, (state, action) => ({ ...state, ordersStatus: action.orders, currentPage: 1})),
  on(CompleteActions.getTablesAndOrdersAndProductsAndBookingsSuccess, (state, action) => ({ ...state, ordersStatus: action.results[0], currentPage: 1})),
  on(OrderActions.getOrdersByOrderStatus, (state, action) => ({ ...state, searchByOrderStatus: action.orderStatusId, })),
  on(OrderActions.getOrdersByOrderStatusSuccess, (state, action) => ({ ...state, ordersStatus: action.orders, currentPage: 1})),
  on(OrderActions.getOrdersByPage, (state, action) => ({...state, currentPage: action.page,})),
  on(OrderActions.getOrdersByPageSuccess, (state, action) => ({...state, ordersStatus: action.orders})),
  on(OrderActions.getOrdersByProductCategory, (state, action) => ({ ...state, searchByProductCategory: action.productCategory, })),
  on(OrderActions.getOrdersByProductCategorySuccess, (state, action) => ({ ...state, ordersStatus: action.orders, currentPage: 1 })),
  on(OrderActions.toUpdateOrder, (state, action) => ({...state, ordersStatus: {...state.ordersStatus, orders: state.ordersStatus.orders.map(o => o.id === action.orderId ? {...o, selected: !o.selected} : o)}})),
  on(MessagingActions.orderSentNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderReceivedNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.preparingOrderNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderDoneNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderPaidNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderPaidWithSumUpNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderPaidWithClassicNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderPaidWithCoinbaseNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderPaidWithSatispayNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderToPayNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderCompletedNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
  on(MessagingActions.orderWaitressCommentNotification, (state, action) => ({...state, ordersStatus: processNotification(state, action) as OrderResponse})),
);

const processNotification = (state: OrderState, action: {notification: {data: NotificationItem}, restaurant_id: string}) => {
  return {  ...state.ordersStatus, orders: JSON.parse(action.notification.data.order).restaurant_id === action.restaurant_id ? state.ordersStatus.orders.findIndex(order => order.id === action.notification.data.order_id) === -1 ? [...state.ordersStatus.orders, JSON.parse(action.notification.data.order)] as Order[] : state.ordersStatus.orders.map(order => order.id === action.notification.data.order_id ? JSON.parse(action.notification.data.order) : order) : state.ordersStatus.orders};
}