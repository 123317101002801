<!-- TOP NAVBAR -->
<app-top-nav id='restaurant'
             [pageType]='pageType'
></app-top-nav>
<!-- TOP NAVBAR -->
<div class="landingpage-container" *ngIf="topNavConfiguration.whatIsParam">
  <div style=" padding: 235px 0 40px 0; flex-direction: {{topNavConfiguration.whatIsParam.rowDirection}};">
    <div class="{{topNavConfiguration.whatIsParam.classBanner}}"></div>
    <div class="banner-mobile">
      <app-navbar-res
        [pageType]='pageType'
      >

      </app-navbar-res>
      <div [ngClass]="topNavConfiguration.dirtyMenuBanner">
        <div class="avatar-mobile" style="background: {{topNavConfiguration.avatarStyle.background}}; border: {{topNavConfiguration.avatarStyle.border}}; top: {{topNavConfiguration.marginAvatarMobile}}">
          <img style="border-radius: 50%;" src="{{topNavConfiguration.whatIsParam.img}}" alt="What is Linguì" />
        </div>
      </div>
    </div>

    <div  [attr.data-aos]="topNavConfiguration.whatIsParam.animationContent" class='row what-is-content' style='flex-direction: {{topNavConfiguration.whatIsParam.rowDirection}};'>
      <div class="col-lg-5 col-sm-12 col-md-5 avatar-circle">
        <div class="{{topNavConfiguration.whatIsParam.avatar}}">
          <img style="border-radius: 50%;" src="{{topNavConfiguration.whatIsParam.img}}" alt="What is Linguì" />
        </div>
      </div>
      <div  *ngIf="pageType !== 'AUTH'"  whatIsContent class="col-lg-7 col-sm-12 col-md-7 whatis-container">
        <div style='padding-top: 3rem; padding-left: 2.5rem; padding-right: 1rem'>
          <h3 style="color: {{topNavConfiguration.whatIsParam.txtColor}};"> {{ topNavConfiguration.whatIsParam.title | translate}} </h3>
          <p> {{ topNavConfiguration.whatIsParam.par | translate}} </p>
        </div>
      </div>
      <div *ngIf="pageType === 'AUTH'"  whatIsContent class="col-lg-7 col-sm-12 col-md-7 whatis-container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <div *ngIf="pageType !== 'AUTH'"  presentationButton class="container-btn" style="margin-top: 10%;">
    <button (click)="goToHello()" type="button" class="btn-restaurant btn what-is-btn {{topNavConfiguration.whatIsParam.classBtn}}">
      <a >{{ topNavConfiguration.whatIsParam.btn | translate}}</a>
      <i class="fas fa-chevron-right" style="margin-left: 50px;"></i>
    </button>
  </div>

</div>
