import { createReducer, on } from '@ngrx/store';
import * as Configuration from '../../model/Configuration';

import { CompleteActions, ConfigurationActions } from '../actions';

export interface ConfigurationState {
  bookingStatuses?: Configuration.BookingStatusConfiguration[];
  clientStatuses?: Configuration.ClientStatusConfiguration[];
  currencyTypes?: Configuration.CurrencyTypeConfiguration[];
  ingredientTypes?: Configuration.IngredientTypeConfiguration[];
  orderStatuses?: Configuration.OrderStatusConfiguration[];
  restaurantProductCategories?: Configuration.RestaurantProductCategory[];
  restaurantSecondaryProductCategories?: Configuration.RestaurantProductCategory[];
  restaurantStages?: Configuration.RestaurantStage[];
  restaurantTypes?: Configuration.RestaurantTypeConfiguration[];
  specialtyTypes?: Configuration.SpecialtyTypeConfiguration[];
  tableStatuses?: Configuration.TableStatusConfiguration[];
  weekDays?: Configuration.WeekDayConfiguration[];
  measureUnitTypes?: Configuration.MeasureUnitConfiguration[];
  sumupAccountLinking?: Configuration.SumUpAccountLinking;
  exportInvoicesUrl?: string;
  exportOrdersUrl?: string;
}

export const initialState: ConfigurationState = {
  bookingStatuses: [],
  clientStatuses: [],
  currencyTypes: [],
  ingredientTypes: [],
  orderStatuses: [],
  restaurantProductCategories: [],
  restaurantStages: [],
  restaurantTypes: [],
  specialtyTypes: [],
  tableStatuses: [],
  weekDays: [],
  measureUnitTypes: [],
  sumupAccountLinking: {url: 'undefined'},
  exportInvoicesUrl: undefined,
  exportOrdersUrl: undefined
};

export const configurationReducer = createReducer(
  initialState,
  on(ConfigurationActions.cleanConfigurationStatus, (state, action) => ({
    ...state,
    bookingStatuses: undefined,
    clientStatuses: undefined,
    currencyTypes: undefined,
    ingredientTypes: undefined,
    orderStatuses: undefined,
    restaurantProductCategories: undefined,
    restaurantStages: undefined,
    restaurantTypes: undefined,
    specialtyTypes: undefined,
    tableStatuses: undefined,
    weekDays: undefined,
    sumupAccountLinking: {url: 'undefined'},
    exportInvoicesUrl: undefined,
    exportOrdersUrl: undefined
  })),
  on(ConfigurationActions.getBookingStatusesSuccess, (state: ConfigurationState, { bookingStatuses }) => ({
    ...state,
    bookingStatuses,
  })),
  on(ConfigurationActions.getClientStatusesSuccess, (state: ConfigurationState, { clientStatuses }) => ({
    ...state,
    clientStatuses,
  })),
  on(ConfigurationActions.getCurrencyTypesSuccess, (state: ConfigurationState, { currencyTypes }) => ({
    ...state,
    currencyTypes,
  })),
  on(ConfigurationActions.getIngredientTypesSuccess, (state: ConfigurationState, { ingredientTypes }) => ({
    ...state,
    ingredientTypes,
  })),
  on(ConfigurationActions.getOrderStatusesSuccess, (state: ConfigurationState, { orderStatuses }) => ({
    ...state,
    orderStatuses,
  })),
  on(ConfigurationActions.getRestaurantProductCategoriesSuccess, (state: ConfigurationState, { restaurantProductCategories, restaurantSecondaryProductCategories }) => ({
    ...state,
    restaurantProductCategories,
    restaurantSecondaryProductCategories
  })),
  on(ConfigurationActions.getRestaurantStagesSuccess, (state: ConfigurationState, { restaurantStages }) => ({
    ...state,
    restaurantStages,
  })),
  on(ConfigurationActions.getRestaurantTypesSuccess, (state: ConfigurationState, { restaurantTypes }) => ({
    ...state,
    restaurantTypes,
  })),
  on(ConfigurationActions.getMeasureUnitTypesSuccess, (state: ConfigurationState, { measureUnitTypes }) => ({
    ...state,
    measureUnitTypes,
  })),
  on(ConfigurationActions.getSpecialtyTypesSuccess, (state: ConfigurationState, { specialtyTypes }) => ({
    ...state,
    specialtyTypes,
  })),
  on(ConfigurationActions.getTableStatusesSuccess, (state: ConfigurationState, { tableStatuses }) => ({
    ...state,
    tableStatuses,
  })),
  on(ConfigurationActions.getWeekDaysSuccess, (state: ConfigurationState, { weekDays }) => ({
    ...state,
    weekDays,
  })),
  on(CompleteActions.getInitAccountConfigurationSuccess, (state: ConfigurationState, action) => ({
    ...state,
    restaurantTypes: action.results[0].restaurantTypes,
    weekDays: action.results[1].weekDays,
    currencyTypes: action.results[2].currencyTypes,
    sumupAccountLinking: action.results[3]
  })),
  on(CompleteActions.getInitAccountProfileSuccess, (state: ConfigurationState, action) => ({
    ...state,
    bookingStatuses: action.results[0].bookingStatuses,
    currencyTypes: action.results[1].currencyTypes,
    ingredientTypes: action.results[2].ingredientTypes,
    measureUnitTypes: action.results[3].measureUnits,
    orderStatuses: action.results[4].orderStatuses,
    restaurantProductCategories: action.results[5].restaurantProductCategories,
    restaurantSecondaryProductCategories: action.results[5].restaurantSecondaryProductCategories,
    tableStatuses: action.results[6].tableStatuses,
    weekDays: action.results[7].weekDays,
    sumupAccountLinking: action.results[8]
  })),
  on(ConfigurationActions.getExportInvoicesSuccess, (state: ConfigurationState, action) => ({
    ...state,
    exportInvoicesUrl: action.url
  })),
  on(ConfigurationActions.getExportOrdersSuccess, (state: ConfigurationState, action) => ({
    ...state,
    exportInvoicesUrl: action.url
  }))
);
