import { FormGroup } from '@angular/forms';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NotificationGravity } from '../store/reducers/messaging.reducers';
import { environment } from 'src/environments/environment';


export type PageType = 'RESTAURANT' | 'CLIENT' | 'AUTH' | 'LOGIN' | 'REGISTER_SUBMITTED';

export const REGEX_PASSWORD_VALIDATION = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$';

export class CommonFunctions {

  public static getDefaultLanguage() {
    return 'it-IT';
  }

  public static createImages(bufferImages: string | object, bucketDirectory: 'restaurant' | 'product'): string[] {
    if (typeof bufferImages === "object") {
      //@ts-ignore
      return String.fromCharCode.apply(null, new Uint8Array(bufferImages.data)).split('$NGM$').map((fileName) => `${environment.s3ImagesUrl}/${bucketDirectory}-gallery/${fileName}`);
    }
    return bufferImages.split('$NGM$');
  }

  public static mustMatch(controlName: string, matchingControlName: string): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? String : error;
    };
  }

  public static getGravityByNotificationId(notificationId: string): { gravity: NotificationGravity } | undefined {
    switch (notificationId) {
      case 'RESTAURANT_ORDER_STATUS_ORDER_COMMENT': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_SENT': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_RECEIVED': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_PREPARING': {
        return {
          gravity: 'WARNING'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_DONE': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_COINBASE': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_SUMUP': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_SATISPAY': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_PAID': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_TO_PAY': {
        return {
          gravity: 'DANGER'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_WAITRESS_COMMENT': {
        return {
          gravity: 'DANGER'
        }
      }
      case 'RESTAURANT_ORDER_STATUS_ORDER_COMPLETED': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_TABLE_STATUS_ATTENTION_COMPLETED': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_TABLE_STATUS_ATTENTION_NEEDED': {
        return {
          gravity: 'DANGER'
        }
      }
      case 'RESTAURANT_TABLE_STATUS_TABLE_DISABLED': {
        return {
          gravity: 'WARNING'
        }
      }
      case 'RESTAURANT_TABLE_STATUS_TABLE_ENABLED': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_TABLE_STATUS_TABLE_USE': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_BOOKING_STATUS_BOOKING_ACCEPTED': {
        return {
          gravity: 'AVAILABLE'
        }
      }
      case 'RESTAURANT_BOOKING_STATUS_BOOKING_CANCELLED': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_BOOKING_STATUS_BOOKING_COMMENT_UPDATE': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_BOOKING_STATUS_BOOKING_GUESTS_NUMBER_UPDATE': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_BOOKING_STATUS_BOOKING_TABLE_NUMBER_UPDATE': {
        return {
          gravity: 'INFO'
        }
      }
      case 'RESTAURANT_BOOKING_STATUS_BOOKING_REFUSED': {
        return {
          gravity: 'DANGER'
        }
      }
      case 'RESTAURANT_BOOKING_STATUS_BOOKING_SENT': {
        return {
          gravity: 'INFO'
        }
      }
      default:
        return undefined;
    }
  }



}


