import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, switchMap, map, of, withLatestFrom, switchMapTo } from "rxjs";
import { SystemNotification } from "src/app/utils/system-notification.class";
import { environment } from "src/environments/environment";
import { AppState } from "..";
import { SystemActions, TableActions } from "../actions";
import { TablesService } from "../../providers/services/tables.service";

@Injectable()
export class TablesEffects {

  constructor(
    private actions$: Actions,
    private tablesService: TablesService,
    private store$: Store<AppState>
  ) { }

  getTablesStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.getTablesStatus),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.tablesService.getTables(storeState.restaurantStore.restaurant.id)
            .pipe(
              map((tablesStatusResponse) => {
                return TableActions.getTablesStatusSuccess({ tableResponse: tablesStatusResponse })
              }),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[getTablesStatusEffect$ - getTablesStatusEffect] error: ${JSON.stringify(err)}`);
                }
                return of(TableActions.getTablesStatusFailed({ error: new Error(err.error.name) }));
              })
            )
        } else {
          return of(TableActions.getTablesStatusFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getTablesStatusSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.getTablesStatusSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'tables-get-success' }))
      })
    )
  );

  getTablesStatusFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.getTablesStatusFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return TableActions.getTablesStatusSuccess({ tableResponse: { tables: [], totalTables: 0 } });
        }
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'tables-get-failed' }))
      })
    )
  );

  attentionTableEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.attentionTable),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && storeState.tableStore.currentTables) {
          return this.tablesService.attentionTable(storeState.restaurantStore.restaurant.id, action.tableId)
            .pipe(
              map((tableHistoryResponse) => {
                return TableActions.attentionTableSuccess({ tableId: action.tableId })
              }),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[attentionTableEffect$ - attentionTableEffect] error: ${JSON.stringify(err)}`);
                }
                return of(TableActions.attentionTableFailed({ error: new Error(err.error.name) }));
              })
            )
        } else {
          return of(TableActions.attentionTableFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  attentionTableSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.attentionTableSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'attention-table-success' }))
      })
    )
  );

  attentionTableFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.attentionTableFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'attention-table-failed' }))
      })
    )
  );

  enableTableEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.enableTable),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && storeState.tableStore.currentTables) {
          return this.tablesService.activateTable(storeState.restaurantStore.restaurant.id, action.tableId)
            .pipe(
              map((tableHistoryResponse) => {
                return TableActions.enableTableSuccess({ tableId: action.tableId, statusId: action.statusId })
              }
              ),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[enableTableEffect$ - enableTableEffect] error: ${JSON.stringify(err)}`);
                }
                return of(TableActions.enableTableFailed({ error: new Error(err.error.name) }));
              })
            )
        } else {
          return of(TableActions.enableTableFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  enableTableSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.enableTableSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'enable-table-success' }))
      })
    )
  );

  enableTableFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.enableTableFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'enable-table-failed' }))
      })
    )
  );

  unableTableEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.unableTable),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant && storeState.tableStore.currentTables) {
          return this.tablesService.deactivateTable(storeState.restaurantStore.restaurant.id, action.tableId)
            .pipe(
              map((tableHistoryResponse) => {
                return TableActions.unableTableSuccess({ tableId: action.tableId, statusId: action.statusId })
              }
              ),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[unableTableEffect$ - unableTableEffect] error: ${JSON.stringify(err)}`);
                }
                return of(TableActions.unableTableFailed({ error: new Error(err.error.name) }));
              })
            )
        } else {
          return of(TableActions.unableTableFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  unableTableSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.unableTableSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'unable-table-success' }))
      })
    )
  );

  unableTableFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.unableTableFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'unable-table-failed' }))
      })
    )
  );

  private handleTableCreationWithPlaceholder(array: {from: number, to: number}[]) {
    let placeholder = {
      from: 0,
      to: 0
    }

    let findTableZero = array.find(table => table.from === 0 || table.to === 0)

    if(!findTableZero) {
      return [placeholder, ...array]
    }

    return array
  }


  createTablesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.createTables),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          let tablesArray = this.handleTableCreationWithPlaceholder(action.rangeOfTables)
          return this.tablesService.createTables(storeState.restaurantStore.restaurant.id, tablesArray, action.customers)
            .pipe(
              map((tableHistoryResponse) => {
                return TableActions.createTablesSuccess({customers: action.customers})
              }
              ),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[createTablesEffect$ - createTablesEffect] error: ${JSON.stringify(err)}`);
                }
                return of(TableActions.createTablesFailed({ error: new Error(err.error.name) }));
              })
            )
        } else {
          return of(TableActions.createTablesFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  createTablesSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.createTablesSuccess),
      switchMapTo([
        TableActions.getTablesStatus(),
        SystemActions.SetNotification(new SystemNotification({ message: 'tables-create-success' }))
      ])
    )
  );

  createTablesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.createTablesFailed),
      map(({ error }) => {
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'tables-create-failed' }))
      })
    )
  );


  getTablesByTableStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.getTablesByTableStatus),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.tablesService
            .getTables(
              storeState.restaurantStore.restaurant.id,
              storeState.tableStore.searchByTableStatus,
            )
            .pipe(
              map((tables) => TableActions.getTablesByTableStatusSuccess({ tables })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[getTablesByTableStatusEffect$ - getTablesByTableStatusEffect] error: ${JSON.stringify(err)}`);
                }
                return of(TableActions.getTablesByTableStatusFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(TableActions.getTablesByTableStatusFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getTableByTableStatusSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.getTablesByTableStatusSuccess),
      map(() => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'tables-get-by-table-status-success' }));
      })
    )
  );

  getTableByTableStatusFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.getTablesByTableStatusFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return TableActions.getTablesByTableStatusSuccess({ tables: { tables: [], totalTables: 0 } });
        }
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'tables-get-by-table-status-failed' }));
      })
    )
  );



  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TableActions.getTablesStatus,
        TableActions.attentionTable,
        TableActions.unableTable,
        TableActions.enableTable,
        TableActions.createTables,
        TableActions.getTablesByTableStatus
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TableActions.getTablesStatusSuccess,
        TableActions.attentionTableSuccess,
        TableActions.unableTableSuccess,
        TableActions.enableTableSuccess,
        TableActions.createTablesSuccess,
        TableActions.getTablesStatusFailed,
        TableActions.attentionTableFailed,
        TableActions.unableTableFailed,
        TableActions.enableTableFailed,
        TableActions.createTablesFailed,
        TableActions.getTablesByTableStatusSuccess,
        TableActions.getTablesByTableStatusFailed
      ),
      map(() => SystemActions.StopLoading())
    )
  );

}
