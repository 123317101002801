import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { AppState } from '..';
import { ProductActions, ProductImageActions, SystemActions } from '../actions';
import { ProductImageService } from '../../providers/services/product-image.service';
import { getRestaurantId } from '../selectors/restaurants.selectors';

@Injectable()
export class ProductImageEffects {
  constructor(private actions$: Actions, private productImageService: ProductImageService, private store$: Store<AppState>) {}

  getImageUrlsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.getProductImages),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([{ productId }, restaurant_id]) =>
        this.productImageService.getProductImage(restaurant_id, productId).pipe(
          map((productImages) => ProductImageActions.getProductImagesSuccess({ productImages, productId })),
          catchError((err) => of(ProductImageActions.getProductImagesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  // getImagesOfMenuProductsEffect$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProductActions.saveProductToProductOfMenu),
  //     withLatestFrom(this.store$.select(getRestaurantId)),
  //     switchMap(([{ products }, restaurant_id]) =>
  //       this.productImageService.getProductsImage(restaurant_id, products).pipe(
  //         map((i) => ProductImageActions.getProductsImagesSuccess({ i })),
  //         catchError((err) => of(ProductImageActions.getProductImagesFailed({ error: new Error(err.error.name) })))
  //       )
  //     )
  //   )
  // );

  getImageUrlsSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.getProductImagesSuccess),
      map((action) => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'get-product-images-success' }));
      })
    )
  );

  createImageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.createProductImages),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([{ images, productId }, restaurant_id]) => {
        return this.productImageService.createProductImage(restaurant_id, productId, images).pipe(
          map((productImages) => ProductImageActions.createProductImagesSuccess({ productImages, productId })),
          catchError((err) => of(ProductImageActions.createProductImagesFailed({ error: new Error(err.error.name) })))
        );
      })
    )
  );

  createImageSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.createProductImagesSuccess),
      map((action) => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'create-product-images-success' }));
      })
    )
  );

  createImageFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.createProductImagesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  deleteImageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.deleteProductImage),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([{ productId, productImageId }, restaurant_id]) =>
        this.productImageService.deleteProductImage(restaurant_id, productImageId, productId).pipe(
          map((i) => ProductImageActions.deleteProductImageSuccess({ imageid: productImageId, productImageId: productId })),
          catchError((err) => of(ProductImageActions.deleteProductImageFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  deleteImageSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.deleteProductImageSuccess),
      map((action) => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'delete-product-image-success' }));
      })
    )
  );

  deleteImageFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductImageActions.deleteProductImageFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        // ProductActions.saveProductToProductOfMenu,
        ProductImageActions.getProductImages,
        ProductImageActions.createProductImages,
        ProductImageActions.deleteProductImage
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProductImageActions.getProductImagesSuccess,
        ProductImageActions.getProductImagesFailed,
        ProductImageActions.createProductImagesSuccess,
        ProductImageActions.createProductImagesFailed,
        ProductImageActions.deleteProductImageSuccess,
        ProductImageActions.deleteProductImageFailed,
        ProductImageActions.getProductsImagesSuccess
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
