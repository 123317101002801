<app-nav></app-nav>

    <div class="registration-container">


        <h2 [ngStyle]="{'color': topNavConfiguration['whatIsParam'].txtColor}" class="t-green fw-800 text-center"> {{"partnerPage.partner-title-2" |
            translate}}</h2>
            <hr>
        <p>{{"authSection.partner-login-description" | translate}}</p>


        <div class="container-fluid">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="row justify-content-end">
                    <div class="col-lg-5 col-md-12 col-sm-12">

                        <h6>{{"partnerPage.partner-label-3" | translate}}</h6>
                        <div class="d-flex box-form input-container">
                            <i class="fas fa-envelope"></i>
                            <input type="text" placeholder='{{"partnerPage.partner-placeholder3" | translate}}'
                                formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && formControls['email'].errors }">
                            <div *ngIf="formControls['email'].dirty">
                                <div *ngIf="!formControls['email'].errors" class="success-msg icon-check">
                                    <i class="fas fa-check-circle"></i>
                                </div>
                            </div>
                        </div>
                        <div class="msg">
                            <div *ngIf="formControls['email'].dirty">
                                <div *ngIf="formControls['email'].errors" class="error-msg">
                                    <span *ngIf="formControls['email'].errors['email']">{{"partnerPage.partner-error2" |
                                        translate}}</span>
                                </div>
                                <div *ngIf="formControls['email'].errors" class="error-msg">
                                    <span *ngIf="formControls['email'].errors['maxlength']">{{"partnerPage.partner-error2" |
                                        translate}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-1 col-md-12 col-sm-12"></div>
                    <div class="col-lg-5 col-md-12 col-sm-12">

                        <h6>{{"partnerPage.partner-label-5" | translate}}</h6>
                        <div class="d-flex box-form input-container">
                            <i class="fas fa-key"></i>
                            <input type="password" id="password" formControlName="password" (focus)='focused = true'
                                (blur)='focused = false' placeholder='{{"partnerPage.partner-placeholder5" | translate}}'
                                [type]="hide? 'password' : 'text'"
                                [ngClass]="loginForm.controls['password'].invalid ? 'is-invalid' : ''">
                            <div class="icon-eye">
                                <i class="material-icons" matSuffix (click)="hide = !hide">
                                    {{hide? 'visibility_off': 'visibility'}}
                                </i>
                            </div>
                        </div>
                        <div class="msg">
                            <div *ngIf="formControls['password'].dirty && formControls['password'].errors"
                                class="error-msg">
                                <span>{{"partnerPage.partner-error-4" | translate}}</span>
                            </div>
                            <div data-aos="fade-in" *ngIf="!formControls['password'].errors" class="success-msg">
                                <span>{{"partnerPage.partner-success-psw-3" | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end" style="margin-top: 30px;">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="recover-password-container">
                            <small style="cursor: pointer;" [routerLink]="'/partner-reset-password'" class="recover-password">Recupera la mia password</small>
                        </div>
                        <button style="margin-top:0;" [disabled]="!loginForm.valid" class="btn btn-register">
                            <div>
                                {{"partnerPage.partner-button-3" | translate}}
                            </div>
                        </button>
                        <p style="font-size: 85%;font-weight: 500;margin:0;">Non hai un account?</p>
                        <p style="font-size: 85%;font-weight: 600;margin:0;color: #5b8c2a; cursor:pointer;"
                            [routerLink]="'/partner-register'">Crea un account adesso!</p>
                    </div>
                </div>

            </form>
        </div>
    </div>