import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { AuthActions } from './index';
import { RouterActions } from '../../router/store';
import { environment } from '../../../environments/environment';
import {
  BookingActions,
  BookingsSlotActions,
  ConfigurationActions,
  MenuActions,
  MessagingActions,
  OrderActions,
  PreferenceActions,
  ProductActions,
  ProductImageActions,
  RestaurantActions,
  RestaurantImageActions,
  SystemActions,
  TableActions,
} from '../../store/actions';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: AuthService, private store$: Store<AppState>) { }
  // initEffect$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ROOT_EFFECTS_INIT),
  //     // get token
  //     mapTo(this.authService.getToken()),
  //     // we want dispatch an action only when an accessToken is found in sessionStorage
  //     filter((accessToken: any) => !!accessToken),
  //     // save token in sessionStorage
  //     switchMap(({ accessToken, emailToken }) => [AuthActions.saveAuth({ auth: { accessToken, emailToken } })])
  //   )
  // );

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) =>
        this.authService.login({ email: action.email.toLowerCase(), password: action.password }).pipe(
          switchMap((response) => [AuthActions.loginSuccess({ auth: { access_token: response.access_token, email: response.email } })]),
          catchError((err) => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[loginEffect$ - login] error: err}`);
            }

            return of(AuthActions.loginFailed({ error: new Error(err), email: action.email.toLowerCase() }));
          })
        )
      )
    )
  );

  loginFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginFailed),
      map(({ error }) => {
        if(JSON.stringify(error.message) === '"User is not confirmed."') {
          return RouterActions.go({ path: ['partner-confirm-account'] })
        } else {
          const notification = new SystemNotification({ error, message: JSON.stringify(error.message) });
          return SystemActions.SetNotification(notification);
        }
      })
    )
  );

  loginSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      tap((action) =>
        this.authService.saveAuth({
          accessToken: action.auth.access_token,
          email: action.auth.email,
        })
      ),
      switchMapTo([
        // SystemActions.SetNotification(new SystemNotification({ message: 'login-success' })), // handled by the get profile restaurant
        ConfigurationActions.getRestaurantStages(),
      ])
    )
  );

  registerEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      switchMap((action) =>
        this.authService.register(action.registerPayload).pipe(
          map(() => AuthActions.registerSuccess({ emailToken: action.registerPayload.email })),
          catchError((err) => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[registerEffect$ - register] error: ${JSON.stringify(err)}`);
            }

            return of(AuthActions.registerFailed({ error: new Error(err.message) }));
          })
        )
      )
    )
  );

  registerFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  // route to partner-confirm-account page after register success
  registerSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerSuccess),
      switchMapTo([
        RouterActions.go({ path: ['partner-confirm-account'] }),
        SystemActions.SetNotification(new SystemNotification({ message: 'registration-confirmed' })),
      ])
    )
  );

  resendConfirmationCodeEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resendConfirmationCode),
      switchMap((action) =>
        this.authService.resendConfirmationCode(action.resendConfirmationCodePayload).pipe(
          map(() => AuthActions.resendConfirmationCodeSuccess({ emailToken: action.resendConfirmationCodePayload.email })),
          catchError((err) => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[resendConfirmationCodeEffect$ - resendConfirmationCode] error: ${JSON.stringify(err)}`);
            }

            return of(AuthActions.resendConfirmationCodeFailed({ error: new Error(err.message) }));
          })
        )
      )
    )
  );

  resendConfirmationCodeFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resendConfirmationCodeFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  // route to partner-confirm-account page after resendConfirmationCode success
  resendConfirmationCodeSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resendConfirmationCodeSuccess),
      switchMapTo([
        RouterActions.go({ path: ['partner-confirm-account'] }),
        SystemActions.SetNotification(new SystemNotification({ message: 'registration-confirmed' })),
      ])
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap((action) => this.authService.cleanAuth()),
      switchMapTo([
        AuthActions.logoutSuccess(),
        RestaurantActions.cleanRestaurantStatus(),
        ConfigurationActions.cleanConfigurationStatus(),
        RestaurantImageActions.cleanRestaurantImageStatus(),
        BookingsSlotActions.cleanBookingSlots(),
        MessagingActions.cleanMessagingStore(),
        ProductImageActions.cleanProductImageStatus(),
        ProductActions.cleanProductStatus(),
        BookingActions.cleanBookings(),
        MenuActions.cleanMenus(),
        OrderActions.cleanOrders(),
        PreferenceActions.cleanPreferences(),
        SystemActions.Clean(),
        TableActions.cleanTablesStore(),
      ])
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutSuccess),
      switchMapTo([RouterActions.go({ path: ['partner-login'] }), SystemActions.SetNotification(new SystemNotification({ message: 'logged-out' }))])
    )
  );

  confirmAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmAccount),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.authService.confirmAccount({ code: action.confirmAccountPayload.confirmCode, email: action.confirmAccountPayload.email }).pipe(
          map(() => AuthActions.confirmAccountSuccess()),
          catchError((err) => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[confirmAccountEffect$ - confirmAccount] error: ${JSON.stringify(err)}`);
            }

            return of(AuthActions.confirmAccountFailed({ error: new Error(err.message) }));
          })
        )
      )
    )
  );

  confirmAccountFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmAccountFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  confirmAccountSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmAccountSuccess),
      switchMapTo([
        // Create account on DB
        RouterActions.go({ path: ['partner-login'] }),
        SystemActions.SetNotification(new SystemNotification({ message: 'account-confirmed' })),
      ])
    )
  );

  recoverAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.recoverAccount),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.authService.recoverAccount(action.email.toLowerCase() === 'not available' ? storeState.auth.emailToken as string : action.email.toLowerCase()).pipe(
          switchMapTo([RouterActions.go({ path: ['partner-confirm-password'] }), AuthActions.recoverAccountSuccess({ email: action.email.toLowerCase() === 'not available' ? storeState.auth.emailToken as string : action.email.toLowerCase() })]),
          catchError((err) => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[registerEffect$ - register] error: ${JSON.stringify(err)}`);
            }
            return of(AuthActions.recoverAccountFailed({ error: new Error(err) }));
          })
        )
      )
    )
  );

  recoverAccountSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.recoverAccountSuccess),
      switchMapTo([SystemActions.SetNotification(new SystemNotification({ message: 'recover-success' }))])
    )
  );

  recoverAccountFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.recoverAccountFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  resetAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.authService.resetPassword({ email: storeState.auth.emailToken as string, resetCode: action.code, password: action.password }).pipe(
          switchMapTo([RouterActions.go({ path: ['partner-login'] }), AuthActions.resetPasswordSuccess()]),
          catchError((err) => {
            if (environment.env === 'DEV' || environment.env === 'INT') {
              console.error(`[resetAccountEffect$ - register] error: ${JSON.stringify(err)}`);
            }
            return of(AuthActions.resetPasswordFailed({ error: new Error(err) }));
          })
        )
      )
    )
  );

  resetAccountSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPasswordSuccess),
      switchMapTo([SystemActions.SetNotification(new SystemNotification({ message: 'reset-success' }))])
    )
  );

  resetAccountFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPasswordFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login, AuthActions.register, AuthActions.recoverAccount, AuthActions.resetPassword, AuthActions.confirmAccount, AuthActions.resendConfirmationCode),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        //AuthActions.loginSuccess, // loader will be deactivated from restaurant get profile success which will route to dashboard or config
        AuthActions.loginFailed,
        AuthActions.registerSuccess,
        AuthActions.registerFailed,
        AuthActions.recoverAccountSuccess,
        AuthActions.recoverAccountFailed,
        AuthActions.resetPasswordSuccess,
        AuthActions.resetPasswordFailed,
        AuthActions.confirmAccountFailed,
        AuthActions.confirmAccountSuccess,
        AuthActions.resendConfirmationCodeFailed,
        AuthActions.resendConfirmationCodeSuccess
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
