import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Restaurant, UpdateRestaurantPayload, CreateRestaurantDB, CreateRestaurantResponse } from '../../model/Restaurant';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  constructor(private http: HttpClient) {}

  getRestaurantProfile(): Observable<{ restaurant: Restaurant }> {
    return this.http.get<{ restaurant: Restaurant }>(`${environment.backendHost}/v1/dashboard/restaurants`);
  }

  createRestaurant(restaurantPayload: CreateRestaurantDB): Observable<CreateRestaurantResponse> {
    return this.http.post<CreateRestaurantResponse>(`${environment.backendHost}/v1/dashboard/restaurants`, restaurantPayload);
  }

  updateRestaurant(restaurantId: string, restaurantPayload: Partial<UpdateRestaurantPayload>): Observable<{ restaurantId: string }> {
    return this.http.patch<{ restaurantId: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}`, restaurantPayload);
  }

  removeRestaurant(restaurantId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}`);
  }

  enableSumUpAccount(restaurantid: string, code: string): Observable<{ sumup_account_id: string}> {
    return this.http.post<{sumup_account_id: string}>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantid}/sumupaccount`, {
      code: code
    });
  }

  enableSatispayAccount(restaurantid: string, code: string): Observable<{ satispay_account_id: string}> {
    return this.http.post<{satispay_account_id: string}>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantid}/satispayaccount`, {
      code: code
    });
  }

  enableClassicAccount(restaurantid: string, code: string): Observable<{ classic_account_id: string}> {
    return this.http.post<{classic_account_id: string}>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantid}/classicaccount`, {
      code: code
    });
  }
}
