import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/store';
import { PreferenceActions } from 'src/app/store/actions';
import { CommonFunctions } from 'src/app/utils/common-functions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NGMTranslateService {

  constructor(public translate: TranslateService, private store: Store<AppState>) {
  }

  setLanguage(language?: string): Subscription {
    let userLang = (language && navigator.language) ?? CommonFunctions.getDefaultLanguage();
    localStorage.setItem('locale', userLang);
    if (environment.env !== 'PROD') {
      this.store.dispatch(PreferenceActions.updateRestaurantLanguage({ localeApplication: userLang }));
    }
    if (userLang !== 'en-US' && userLang !== 'it-IT') {
      userLang === 'it-IT';
    }
    return this.translate.use(userLang).subscribe();
  }
}
