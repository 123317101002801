
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { NotFoundComponent } from './not-found.component';
import { NotFoundRoutingModule } from './not-found-routing.module';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';



@NgModule({
    imports: [
        CommonModule,
        NotFoundRoutingModule,
        RouterModule,
        TranslateModule
    ],
    declarations: [NotFoundComponent],
    exports: [NotFoundComponent],

    providers: [

    ]
})
export class NotFoundModule { }

