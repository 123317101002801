<div class="row g-3 flex-center">
  <div
    class="col-md-4 col-lg-3 subscription-card {{ card.card_classes }}"
    *ngFor="let card of cards; let first = first; let last = last"
    [ngClass]="{ 'ms-2': !first, 'me-2': !last }"
  >
    <app-card [cardHeaderTpl]="cardHeaderTpl" [cardBodyTpl]="cardBodyTpl" [cardActionsTpl]="cardActionsTpl"></app-card>

    <ng-template #cardHeaderTpl>
      <div class="flex-between-center w-100">
        <h3 class="subscription-card-title mb-0">
          <strong>{{ card.card_heading.title | translate }}</strong>
        </h3>
        <div class="icon icon-primary text-right">
          <i class="now-ui-icons objects_diamond"></i>
        </div>
      </div>
      <div class="card-title">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed gravida porta est. Cras euismod pulvinar eros a mattis. Donec tortor felis, elementum a
        vehicula et, semper at diam. Morbi at magna vel orci efficitur scelerisque.
      </div>
      <div class="my-4 flex-center">
        <h1 class="mb-0">{{ card.card_heading.price | translate }}</h1>
        <h4 class="mb-0">/year *</h4>
      </div>
      <!-- {{ card.card_heading.subtitle | translate }} -->
    </ng-template>

    <ng-template #cardBodyTpl>
      <div class="flex-between-center" *ngFor="let option of card.card_options">
        <h5>{{ option.description | translate }}</h5>
        <span class="{{ option.active ? 'text-white' : 'text-danger' }}"><i class="fas {{ option.active ? 'fa-check' : 'fa-times' }} fa-lg"></i></span>
      </div>
    </ng-template>

    <ng-template #cardActionsTpl>
      
      <ng-container *ngFor="let action of card.card_actions">
        <a (click)="completeConfiguration !== undefined ? executeCallback(card.card_classes.toUpperCase()) : openMail()" class="btn btn-neutral btn-txt {{ action.action_class }}">{{ textButton ?? (action.label | translate) }}</a>
      </ng-container>
    </ng-template>
  </div>
</div>
<section class="flex-center w-100 mt-4">
  <article class="terms">
    <span>* L'importo indicato fa riferimento al costo di abbonamento al servizio. Verranno inoltre addebitati tutti i costi tecnici.</span>
  </article>
</section>