import { Component, Input, OnInit } from '@angular/core';
import { PageType } from '../../../utils/common-functions';
import { whatDoContentConfiguration, WhatDoSectionConfiguration } from './what-do-section.content-configuration';

@Component({
  selector: 'app-what-do-section',
  templateUrl: './what-do-section.component.html',
  styleUrls: ['./what-do-section.component.scss']
})
export class WhatDoSectionComponent implements OnInit {

  constructor() { }

  public image!: string;

  @Input() pageType!: PageType;
  public whatDoSectionConfiguration!: WhatDoSectionConfiguration;

  ngOnInit(): void {
    this.whatDoSectionConfiguration = whatDoContentConfiguration.get(this.pageType) as WhatDoSectionConfiguration;
    this.image = this.whatDoSectionConfiguration.whatDo[0].img;
  }

  handleImage(image: string): void {
    this.image = image;
  }

}
