import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatDoSectionComponent } from './what-do-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [WhatDoSectionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule
  ],
 exports :[ WhatDoSectionComponent]
})
export class WhatDoSectionModule { }
