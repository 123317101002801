import { createAction, props } from '@ngrx/store';
import { Restaurant } from '../../model/Restaurant';

export const getProfileRestaurant = createAction('[RESTAURANT] Get Restaurant Profile');

export const getProfileRestaurantSuccess = createAction('[RESTAURANT] Get Restaurant Profile Success', props<{ restaurant: Restaurant }>());

export const getProfileRestaurantFailed = createAction('[RESTAURANT] Get Restaurant Profile Failed', props<{ error: Error }>());

export const createRestaurant = createAction('[RESTAURANT] Create Restaurant');

export const createRestaurantSuccess = createAction('[RESTAURANT] Create Restaurant Success', props<{ restaurantid: string, email: string, name: string, address: string, admin_name: string, phone_number: string, type_id: string, city: string, pin: string, stage_id: string }>());

export const createRestaurantFailed = createAction('[RESTAURANT] Create Restaurant Failed', props<{ error: Error }>());

export const routeToRestaurantConfigurator = createAction('[RESTAURANT] Route User to Configurator');

export const routeToRestaurantDashboard = createAction('[RESTAURANT] Route User to Dashboard');

export const getRestaurantDashboard = createAction('[RESTAURANT] Get Restaurant Dashboard');

export const getRestaurantConfigurator = createAction('[RESTAURANT] Get Restaurant Configurator');

export const cleanRestaurantStatus = createAction('[RESTAURANT] Clean Restaurant Status');

export const saveRestaurantType = createAction('[RESTAURANT] Save Restaurant Type Inside Store', props<{ type_id: string }>());
export const saveRestaurantAddress = createAction('[RESTAURANT] Save Restaurant Address Inside Store', props<{ address: string }>());
export const saveRestaurantCity = createAction('[RESTAURANT] Save Restaurant City Inside Store', props<{ city: string }>());
export const saveRestaurantLatitude = createAction('[RESTAURANT] Save Restaurant Latitude Inside Store', props<{ latitude: number }>());
export const saveRestaurantLongitude = createAction('[RESTAURANT] Save Restaurant Longitude Inside Store', props<{ longitude: number }>());
export const saveRestaurantPhoneNumber = createAction('[RESTAURANT] Save Restaurant Phone Number Inside Store', props<{ phone_number: string }>());
export const saveRestaurantEmail = createAction('[RESTAURANT] Save Restaurant Email Inside Store', props<{ email: string }>());

export const saveRestaurantPIN = createAction('[RESTAURANT] Save Restaurant PIN Inside Store', props<{ pin: string }>());
export const saveRestaurantName = createAction('[RESTAURANT] Save Restaurant Name Inside Store', props<{ name: string }>());
export const saveRestaurantAdminName = createAction('[RESTAURANT] Save Restaurant AdminName Inside Store', props<{ admin_name: string }>());
export const saveRestaurantCurrencies = createAction('[RESTAURANT] Save Restaurant Currencies', props<{ currency_ids: string[] }>());
export const saveRestaurantStage = createAction('[RESTAURANT] Update Restaurant Stage Inside Store', props<{ stage_id: string }>());
export const saveRestaurantPlan = createAction('[RESTAURANT] Update Restaurant Plan Inside Store', props<{ plan_name: string }>());


export const updateRestaurant = createAction('[RESTAURANT] Update Restaurant');
export const updateRestaurantSuccess = createAction('[RESTAURANT] Update Restaurant Success');
export const updateRestaurantFailed = createAction('[RESTAURANT] Update Restaurant Failed', props<{ error: Error }>());

export const removeRestaurant = createAction('[RESTAURANT] Remove Restaurant');
export const removeRestaurantSuccess = createAction('[RESTAURANT] Remove Restaurant Success');
export const removeRestaurantFailed = createAction('[RESTAURANT] Remove Restaurant Failed', props<{ error: Error }>());

export const enableSumUpAccount = createAction('[RESTAURANT] Enable SumUp Account Restaurant', props<{code: string}>());
export const enableSumUpAccountSuccess = createAction('[RESTAURANT] Enable SumUp Account Restaurant Success');
export const enableSumUpAccountFailed = createAction('[RESTAURANT] Enable SumUp Account Restaurant Failed', props<{ error: Error}>());

export const enableSatispayAccount = createAction('[RESTAURANT] Enable Satispay Account Restaurant', props<{code: string}>());
export const enableSatispayAccountSuccess = createAction('[RESTAURANT] Enable Satispay Account Restaurant Success');
export const enableSatispayAccountFailed = createAction('[RESTAURANT] Enable Satispay Account Restaurant Failed', props<{ error: Error}>());


export const enableClassicAccount = createAction('[RESTAURANT] Enable Classic Account Restaurant', props<{code: string}>());
export const enableClassicAccountSuccess = createAction('[RESTAURANT] Enable Classic Account Restaurant Success');
export const enableClassicAccountFailed = createAction('[RESTAURANT] Enable Classic Account Restaurant Failed', props<{ error: Error}>());



export const saveRestaurantSumup = createAction('[RESTAURANT] Save Restaurant Sumup', props<{value: boolean}>());
export const saveRestaurantClassic = createAction('[RESTAURANT] Save Restaurant Classic', props<{value: boolean}>());
export const saveRestaurantSatispay = createAction('[RESTAURANT] Save Restaurant Satispay', props<{value: boolean}>());
