import { Component, Input, OnInit } from '@angular/core';
import { Hamburger } from 'src/app/model/LandingPage';
import { TopNavConfiguration, topNavContentConfiguration } from '../../navbar/top-nav/top-nav.content-configuration';
import { PageType } from '../../../utils/common-functions';

@Component({
  selector: 'app-what-is-section',
  templateUrl: './what-is-section.component.html',
  styleUrls: ['./what-is-section.component.scss']
})
export class WhatIsSectionComponent implements OnInit {

  public topNavConfiguration!: TopNavConfiguration;
  @Input() pageType!: PageType;
  hamburgerTop!: Hamburger;
  hamburgerBot!: Hamburger;

  constructor() {}

  ngOnInit(): void {
    this.topNavConfiguration = topNavContentConfiguration.get(this.pageType) as TopNavConfiguration;
    this.hamburgerTop = {
      img: 'assets/img/qr-menu/LP-Restaurant/hamburgerTop.svg',
      title: 'lp-r-what-is-section-hamburgerTop',
    };

    this.hamburgerBot = {
      img: 'assets/img/qr-menu/LP-Restaurant/hamburgerBot.svg',
      title: 'lp-r-what-is-section-hamburgerBot',
    };
  }
}
