import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HowSectionComponent } from './how-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HowSectionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule
  ],
  exports : [ HowSectionComponent ]
})
export class HowSectionModule { }
