import {
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export class SystemNotification {
	error?: Error;
	options: SystemNotificationOptions;

	constructor(obj: SystemNotificationObject) {
		this.error = obj.error || undefined;
		this.options = new SystemNotificationOptions(obj.message, !!this.error);
	}

	getInterface(): ISystemNotification {
		const { data, duration, horizontalPosition, verticalPosition, panelClass } = this.options;
		return {
			data: { title: data.title, message: data.message },
			duration,
			horizontalPosition,
			verticalPosition,
			panelClass
		};
	} 
}

export class SystemNotificationOptions implements ISystemNotification {
	data: SystemNotificationData;
	duration: number;
	horizontalPosition: MatSnackBarHorizontalPosition;
	verticalPosition: MatSnackBarVerticalPosition;
	panelClass: Array<string>;

	constructor(
		_message: string,
		_hasError = false,
		_duration = 2500,
		_horizontalPosition?: MatSnackBarHorizontalPosition,
		_verticalPosition?: MatSnackBarVerticalPosition
	) {
		this.duration = _duration;
		this.horizontalPosition = _horizontalPosition || 'right';
		this.verticalPosition = _verticalPosition || 'bottom';
		this.panelClass = _hasError ? ['error-snackbar'] : ['success-snackbar'];
		this.data = _hasError
			? { title: 'Alert!', message: _message }
			: { title: 'Operation completed.', message: _message };
	}
}

export interface SystemNotificationOptionsData {
	title: string;
	message: string;
};

export interface SystemNotificationObject {
	error?: Error;
	message: string;
};

export interface SystemNotificationData {
	title: string;
	message: string;
};

export interface ISystemNotification {
	data: SystemNotificationData;
	duration: number;
	horizontalPosition: MatSnackBarHorizontalPosition;
	verticalPosition: MatSnackBarVerticalPosition;
	panelClass: Array<string>;
}