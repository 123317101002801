<!--SECTION-->
<div class="main-container container-fluid pr-0">
  <div class="main-container col-12 d-flex justify-content-center pr-0 img-container">
    <img src='../../../../assets/img/qr-menu/maintenance.svg' alt="404 Linguì">
  </div>
</div>
<div class="text-container container-fluid pr-0 d-flex flex-column justify-content-center mb-5">
  <div>
    <h2 class="mb-0">{{'maintenanceSection.maintenance-text' | translate}}</h2>
  </div>
  <div>
    <h2 class="mb-0">{{'maintenanceSection.maintenance-text-1' | translate}}</h2>
  </div>
  <div>
    <h2>{{'maintenanceSection.maintenance-text-2' | translate}}</h2>
  </div>
</div>
<!--SECTION-->
