import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpRestaurantComponent } from './lp-restaurant.component';

import { RouterModule } from '@angular/router';
import { LpRestaurantRoutingModule } from './lp-restaurant-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WhatIsSectionModule } from 'src/app/components/presentation/what-is-section/what-is-section.module';
import { WhySectionModule } from 'src/app/components/presentation/why-section/why-section.module';
import { HowSectionModule } from 'src/app/components/presentation/how-section/how-section.module';
import { WhatDoSectionModule } from 'src/app/components/presentation/what-do-section/what-do-section.module';
import { OffersSectionModule } from 'src/app/components/presentation/offers-section/offers-section.module';
import { MockupSectionModule } from 'src/app/components/presentation/mockup-section/mockup-section.module';
import { RegistrationSectionModule } from 'src/app/components/presentation/registration-section/registration-section.module';
import { TopNavModule } from 'src/app/components/navbar/top-nav/top-nav.module';


@NgModule({
  declarations: [LpRestaurantComponent],
  imports: [
    CommonModule,
    RouterModule,
    LpRestaurantRoutingModule,
    TranslateModule,
    NgbModule,
    TopNavModule,
    WhatIsSectionModule,
    WhySectionModule,
    HowSectionModule,
    WhatDoSectionModule,
    OffersSectionModule,
    MockupSectionModule,
    RegistrationSectionModule,
  ],
  exports : [ LpRestaurantComponent]
})
export class LpRestaurantModule { }
