import { createAction, props } from "@ngrx/store";
import * as CustomFlowModel from '../../model/CustomFlow'

export const getCustomFlow = createAction(
    "[CUSTOM-FLOW]: Getting order printer checks from custom flow",
)

export const getCustomFlowFailed = createAction(
    "[CUSTOM-FLOW]: Error getting custom flow data",
    props<{ error: Error }>()
)

export const getCustomFlowSuccess = createAction(
    "[CUSTOM-FLOW]: Success getting custom flow data",
    props<{ customFlow: CustomFlowModel.CustomFlowResponse }>()
)

export const updatePrinterCheckInCustomFlow = createAction(
    "[CUSTOM-FLOW]: Updating printer check in custom flow",
    props<{ printer_check: boolean }>()
)

export const updateOrderCheckInCustomFlow = createAction(
    "[CUSTOM-FLOW]: Updating order check in custom flow",
    props<{ order_check: boolean }>()
)

export const updateCustomFlow = createAction(
    "[CUSTOM-FLOW]: Updating custom flow",
)

export const updateCustomFlowSuccess = createAction(
    "[CUSTOM-FLOW]: Success updating custom flow"
)

export const updateCustomFlowFailed = createAction(
    "[CUSTOM-FLOW]: Error updating custom flow",
    props<{error: Error}>()
)