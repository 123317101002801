<!--
This is probably the most complex component of the whole application. ⚠️
Do not touch it if you don't know what you're doing!

It calls app-navbar-unite and it's passing the pageType (RESTAURANT or CLIENT)
It contains in web mode the client and restaurant bars with avatars along with the navigation bar.
In mobile mode it contains the navigation bar and the restaurant and client bars with avatars which comes from top.

WhatIsSection -> AppNavbarUnite & Mobile navigation navbar & web banners with descriptive content about what is lingui

AppNavbarUnite has AppTopNav which contains the web navigation bar and the responsive bar with the logo and button to open mobile navigation bar.

-->


<app-navbar-unite
  [pageType]='pageType'
>

</app-navbar-unite>
