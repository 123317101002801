import { createAction, props } from "@ngrx/store";
import { PreferencesResponse, PreferencesRequest, PreferencesPatch } from "src/app/model/Preference";

export const cleanPreferences = createAction("[Preference] Clean Preferences");

export const getRestaurantPreferences = createAction(
    '[RESTAURANT] Get Preferences'
);

export const getRestaurantPreferencesSuccess = createAction(
    '[RESTAURANT] Get Preferences Success',
    props<{ preferences: PreferencesResponse[] }>()
);

export const getRestaurantPreferencesFailed = createAction(
    '[RESTAURANT] Get Preferences Failed',
    props<{ error: Error }>()
);

export const createRestaurantPreferences = createAction(
    '[RESTAURANT] Post Preferences',
    props<{ preferences: PreferencesRequest }>()
);

export const createRestaurantPreferencesSuccess = createAction(
    '[RESTAURANT] Post Preferences Success'
);

export const createRestaurantPreferencesFailed = createAction(
    '[RESTAURANT] Post Preferences Failed',
    props<{ error: Error }>()
);

export const updateRestaurantPreferences = createAction(
    '[RESTAURANT] Patch Preferences',
    props<{ preferences: PreferencesPatch, deviceId: string }>()
);

export const updateRestaurantPreferencesSuccess = createAction(
    '[RESTAURANT] Patch Preferences Success'
);

export const updateRestaurantPreferencesFailed = createAction(
    '[RESTAURANT] Patch Preferences Failed',
    props<{ error: Error }>()
);

export const removeRestaurantPreferences = createAction(
    '[RESTAURANT] Delete Preferences',
    props<{ deviceId: string }>()
);

export const removeRestaurantPreferencesSuccess = createAction(
    '[RESTAURANT] Delete Preferences Success'
);

export const updateRestaurantLanguage = createAction(
    '[RESTAURANT] Update Restaurant Language',
    props<{ localeApplication: string }>()
);
export const updateRestaurantLanguageSuccess = createAction(
    '[RESTAURANT] Update Restaurant Language Success'
);

export const updateRestaurantLanguageFailed = createAction(
    '[RESTAURANT] Update Restaurant Language Failed',
    props<{ error: Error }>()
);


export const removeRestaurantPreferencesFailed = createAction(
    '[RESTAURANT] Delete Preferences Failed',
    props<{ error: Error }>()
);
