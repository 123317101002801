import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, switchMapTo, withLatestFrom } from 'rxjs';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { AppState } from '..';
import { BookingsSlotActions, SystemActions } from '../actions';
import { BookingSlotService } from '../../providers/services/booking-slot.service';
import { getRestaurantId } from '../selectors/restaurants.selectors';

@Injectable()
export class BookingSlotEffects {
  constructor(private actions$: Actions, private bookingSlotService: BookingSlotService, private store$: Store<AppState>) {}

  getBookingSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.getBookingSlots),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([action, restaurant_id]) =>
        this.bookingSlotService.getBookingSlot(restaurant_id, action.date, action.guestsNumber).pipe(
          map(({ booking_slots, hourly_slots, free_tables}) => BookingsSlotActions.getBookingSlotsSuccess({ bookingSlots: booking_slots, hourlySlots: hourly_slots, free_tables})),
          catchError((err) => of(BookingsSlotActions.getBookingSlotsFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getBookingSlotsFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.getBookingSlotsFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return BookingsSlotActions.getBookingSlotsSuccess({ bookingSlots: [], hourlySlots: [], free_tables: [] });
        } else {
          const notification = new SystemNotification({ error, message: error.message });
          return SystemActions.SetNotification(notification);
        }
      })
    )
  );

  copyBookingSlotsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.copyBookingSlots),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.bookingSlotService
          .copyBookingSlots(
            storeState.restaurantStore.restaurant!.id,
            storeState.configurationStore.weekDays!.find((w) => w.name === action.fromDay)!.id,
            storeState.configurationStore.weekDays!.find((w) => w.name === action.toDay)!.id,
            storeState.bookingSlotsStore.bookingsSlots!
          )
          .pipe(
            map((bs) => BookingsSlotActions.copyBookingSlotsSuccess()),
            catchError((err) => of(BookingsSlotActions.copyBookingSlotsFailed({ error: new Error(err.error.name) })))
          )
      )
    )
  );

  copyBookingSlotsFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.copyBookingSlotsFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  copyBookingSlotsSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.copyBookingSlotsSuccess),
      map(() => {
        const notification = new SystemNotification({ message: 'Booking slots copied successfully' });
        SystemActions.SetNotification(notification);
        return BookingsSlotActions.getBookingSlots({});
      })
    )
  );

  createBookingSlotEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.createBookingSlot),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([action, restaurant_id]) =>
        this.bookingSlotService
          .createBookingSlot(restaurant_id, {
            end_time: action.bookingSlot.end_time,
            start_time: action.bookingSlot.start_time,
            week_day_id: action.bookingSlot.week_day_id,
          })
          .pipe(
            map((bs) =>
              BookingsSlotActions.createBookingSlotSuccess({
                bookingSlot: {
                  id: bs['bookingid'],
                  creation_date: new Date().toISOString(),
                  end_time: action.bookingSlot.end_time,
                  start_time: action.bookingSlot.start_time,
                  restaurant_id,
                  last_update_date: new Date().toISOString(),
                  week_day_id: action.bookingSlot.week_day_id,
                },
              })
            ),
            catchError((err) => of(BookingsSlotActions.createBookingSlotFailed({ error: new Error(err.error.name) })))
          )
      )
    )
  );

  createBookingSlotFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.createBookingSlotFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  createBookingSlotSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.createBookingSlotSuccess),
      switchMapTo([SystemActions.SetNotification(new SystemNotification({ message: 'booking-slot-created' }))])
    )
  );

  updateBookingSlot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.updateBookingSlot),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([action, restaurant_id]) =>
        this.bookingSlotService
          .updateBookingSlot(restaurant_id, action.bookingSlot.id, {
            end_time: action.bookingSlot.end_time,
            start_time: action.bookingSlot.start_time,
            week_day_id: action.bookingSlot.week_day_id,
          })
          .pipe(
            map((bs) =>
              BookingsSlotActions.updateBookingSlotSuccess({
                bookingSlot: {
                  id: action.bookingSlot.id,
                  creation_date: new Date().toISOString(),
                  end_time: action.bookingSlot.end_time,
                  start_time: action.bookingSlot.start_time,
                  restaurant_id,
                  last_update_date: new Date().toISOString(),
                  week_day_id: action.bookingSlot.week_day_id,
                },
              })
            ),
            catchError((err) => of(BookingsSlotActions.updateBookingSlotFailed({ error: new Error(err.error.name) })))
          )
      )
    )
  );

  updateBookingSlotFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.updateBookingSlotFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  updateBookingSlotSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.updateBookingSlotSuccess),
      switchMapTo([SystemActions.SetNotification(new SystemNotification({ message: 'booking-slot-updated' }))])
    )
  );

  deleteBookingSlot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.deleteBookingSlot),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([{ bookingSlotId }, restaurant_id]) =>
        this.bookingSlotService.deleteBookingSlot(restaurant_id, bookingSlotId).pipe(
          map((bs) => BookingsSlotActions.deleteBookingSlotSuccess({ bookingSlotId })),
          catchError((err) => of(BookingsSlotActions.deleteBookingSlotFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  deleteBookingSlotFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.deleteBookingSlotFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  deleteBookingSlotSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingsSlotActions.deleteBookingSlotSuccess),
      switchMapTo([SystemActions.SetNotification(new SystemNotification({ message: 'booking-slot-deleted' }))])
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingsSlotActions.createBookingSlot,
        BookingsSlotActions.getBookingSlots,
        BookingsSlotActions.updateBookingSlot,
        BookingsSlotActions.deleteBookingSlot,
        BookingsSlotActions.copyBookingSlots
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingsSlotActions.createBookingSlotSuccess,
        BookingsSlotActions.createBookingSlotFailed,
        BookingsSlotActions.getBookingSlotsSuccess,
        // BookingsSlotActions.getBookingSlotsFailed, -- Shows error if there are no booking slots
        BookingsSlotActions.updateBookingSlotSuccess,
        BookingsSlotActions.updateBookingSlotFailed,
        BookingsSlotActions.deleteBookingSlotSuccess,
        BookingsSlotActions.deleteBookingSlotFailed,
        BookingsSlotActions.copyBookingSlotsFailed,
        BookingsSlotActions.copyBookingSlotsSuccess
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
