<div class="mockup-section-banner"  [style.backgroundColor]="mockupSectionConfiguration.mockupInfo.colorGreen">
  <div class="row mockup-section-row">
    <div class="col-lg-7 col-md-6 col-sm-12 container-text">
      <h3 data-aos="fade-up">{{ mockupSectionConfiguration.mockupInfo.title | translate}}</h3>
      <p data-aos="fade-up">{{ mockupSectionConfiguration.mockupInfo.par | translate}}</p>
      <a href='mailto:sales@lingui.it' data-aos="zoom-in" [style.color]="mockupSectionConfiguration.mockupInfo.colorGreen" type="button" class="btn mockup-btn">{{ mockupSectionConfiguration.mockupInfo.cta | translate}}</a>
    </div>
    <div class="col-lg-5 col-md-6 col-sm-12">
      <div class="container-fluid text-center">
        <img class="img-fluid" style="max-height: 500px" src="{{mockupSectionConfiguration.mockupInfo.img}}" alt="Mockup Linuguì">
      </div>
    </div>
  </div>

</div>
