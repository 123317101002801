<nav class="navbar navbar-expand-lg fixed-top" [id]="backgroundColor" >
  <div class="container-fluid">
    <div class="col-lg-2 d-flex me-lg-4 justify-content-end">
      <div class="navbar-brand container-img">
        <a [href]="linkHome" >
          <img [src]="logo" alt="brand-linguì">
        </a>
      </div>
    </div>
    <button (click)="getProperties(this)" class="navbar-toggler me-2 border border-light " type="button" data-bs-toggle="collapse" data-bs-target="#navbarLinguì" aria-controls="navbarLinguì" aria-expanded="false" aria-label="Toggle navigation">
      <span [class]="(window == true ? fork : hamburger)"></span>
    </button>
    <div class="row collapse navbar-collapse" id="navbarLinguì">
      <div class="col-lg-7">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex ">
          <li class="nav-item me-lg-4 ms-lg-5 d-flex justify-content-center">
            <a  [href]="linkHome" class="nav-link active text-light fw-bold" aria-current="page" >Come funziona</a>
          </li>
          <li class="nav-item me-lg-4 d-flex justify-content-center">
            <a  [href]="linkHome+'/pricing'" class="nav-link text-light fw-bold">I nostri piani</a>
          </li>
          <li class="nav-item text-light me-lg-4 d-flex justify-content-center">
            <a  [href]="linkHome" class="nav-link text-light fw-bold" >Contattaci</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3">
        <div class="row ">
          <div class="col-lg-6 d-flex pb-sm-1 pt-sm-1 p-1 justify-content-center justify-content-lg-end">
            <button [id]="backGroundButton" routerLink="/partner-register" class="button-size text-light fw-bold f-20 btn-register" type="submit">Registrati</button>
          </div>
          <div class="col-lg-6 d-flex pb-sm-1 pt-sm-1 p-1 justify-content-center justify-content-lg-start">
            <button routerLink="/partner-login" class="button-size fw-bold bg-light t-orange f-20 btn-enter" type="submit">Entra</button>
          </div>
        </div>
      </div>
    </div>



  </div>
</nav>

