import { createReducer, on } from "@ngrx/store";
import { CompleteActions, RestaurantImageActions } from "../actions";

export interface RestaurantImageState {
    imagesUrls?: string[],
    imagesBuffers?: string[]
}

export const initialState: RestaurantImageState = {
    imagesBuffers: [],
    imagesUrls: []
};

export const restaurantImageReducer = createReducer(
    initialState,
    on(RestaurantImageActions.cleanRestaurantImageStatus, (state, action) => ({
        ...state, imagesUrls: [], imagesBuffers: []
    })),
    on(RestaurantImageActions.getRestaurantImagesSuccess, (state, action) => ({
        ...state, imagesUrls: action.restaurantImages.images_urls
    })),
    on(RestaurantImageActions.createRestaurantImagesSuccess, (state, action) => ({
        ...state, imagesBuffers: [], imagesUrls: action.restaurantImages.images_urls
    })),
    on(RestaurantImageActions.deleteRestaurantImageSuccess, (state, action) => ({
        ...state, imagesUrls: state.imagesUrls!.filter(image => image !== action.imageid)
    })),
    on(RestaurantImageActions.saveRestaurantImagesBuffer, (state, action) => ({
        ...state, imagesBuffers: action.imageBuffer
    })),
    on(RestaurantImageActions.cleanRestaurantImagesBuffer, (state, action) => ({
        ...state, imagesBuffers: []
    })),
    on(CompleteActions.getReviewComponentSuccess, (state, action) => ({
        ...state, imagesUrls: action.results[1].images_urls
    })),
);