import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PreferencesResponse, PreferencesRequest, PreferencesPatch } from 'src/app/model/Preference';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PreferenceService {
  constructor(private http: HttpClient) {}
  getRestaurantPreferences(): Observable<PreferencesResponse[]> {
    return this.http.get<PreferencesResponse[]>(`${environment.backendHost}/v1/accounts/preferences`);
  }

  createRestaurantPreferences(payload: PreferencesRequest): Observable<{ deviceId: string }> {
    return this.http.post<{ deviceId: string }>(`${environment.backendHost}/v1/accounts/preferences`, payload);
  }

  updateRestaurantPreferences(deviceId: string, payload: PreferencesPatch): Observable<{ deviceId: string }> {
    return this.http.patch<{ deviceId: string }>(`${environment.backendHost}/v1/accounts/preferences/${deviceId}`, payload);
  }

  removeRestaurantPreferences(deviceId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${environment.backendHost}/v1/accounts/preferences/${deviceId}`);
  }

  updateRestaurantLanguage(payload: { localeApplication: string }): Observable<{ message: string }> {
    return this.http.patch<{ message: string }>(`${environment.backendHost}/v1/accounts/preferences/language`, payload);
  }
}
