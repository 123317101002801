import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuFontPickerComponent } from './menu-font-picker.component';
import { MenuFontPickerRoutingModule } from './menu-font-picker-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontPickerModule } from 'ngx-font-picker';


@NgModule({
  declarations: [MenuFontPickerComponent],
  imports: [
    CommonModule,
    MenuFontPickerRoutingModule,
    TranslateModule,
    NgbModule,
    FontPickerModule
  ],
  exports: [MenuFontPickerComponent]
})
export class MenuFontPickerModule { }
