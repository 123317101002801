import { Component, HostBinding, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() cardClasses: string | Array<string> = '';
  @Input() cardHeaderTpl: TemplateRef<unknown> | undefined;
  @Input() cardBodyTpl: TemplateRef<unknown> | undefined;
  @Input() cardActionsTpl: TemplateRef<unknown> | undefined;

  @HostBinding('class.w-100') maxWidth() {
    return true;
  }

  ngOnInit(): void {
    if (Array.isArray(this.cardClasses)) {
      this.cardClasses = this.cardClasses.join(' ');
    }
  }
}
