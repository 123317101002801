import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuActions } from 'src/app/store/actions';
import { Font, FontInterface } from 'ngx-font-picker';
import { Menu } from 'src/app/model/Menu';
import { AppState } from 'src/app/store';

@Component({
    selector: 'app-menu-font-picker',
    templateUrl: './menu-font-picker.component.html',
    styleUrls: ['./menu-font-picker.component.scss'],
})
export class MenuFontPickerComponent {
    @Input() editingMenu: Menu | undefined | null;

    constructor(private store: Store<AppState>) {}

    titleFont = 'Montserrat';
    categoryFont = 'Montserrat';
    productFont = 'Montserrat';
    ingredientsFont = 'Montserrat';
    priceFont = 'Montserrat';

    public font: FontInterface = new Font({
        family: 'Montserrat',
        size: '14px',
        style: 'regular',
        styles: ['regular'],
    });

    chooseFont($event: any, subject: string) {
        this.showFont(subject, $event.family);
    }

    showFont(subject: string, font: string) {
        switch (subject) {
            case 'title': {
                this.titleFont = font;
                this.store.dispatch(MenuActions.saveTitleFont({ font }));
                break;
            }
            case 'product': {
                this.productFont = font;
                this.store.dispatch(MenuActions.saveProductFont({ font }));
                break;
            }
            case 'category': {
                this.categoryFont = font;
                this.store.dispatch(MenuActions.saveCategoryFont({ font }));
                break;
            }
            case 'ingredients': {
                this.ingredientsFont = font;
                this.store.dispatch(MenuActions.saveIngredientsFont({ font }));
                break;
            }
            case 'price': {
                this.priceFont = font;
                this.store.dispatch(MenuActions.savePriceFont({ font }));
                break;
            }
        }
    }
}
