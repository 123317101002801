export const environment = {
  production: false,
  cognitoUserPoolId: 'eu-west-1_u12Rim3sB',
  cognitoClientId: '2idhjajbq1cckqd53mvn6bn0ss',
  backendHost: 'https://z7koqiadji.execute-api.eu-west-1.amazonaws.com/int',
  isPlatformEnabled: true,
  env: 'INT',
  newsLetterFeature: false,
  // enabled = false, disabled = true
  sumupPayment: false,
  classicPayment: false, 
  satispayPayment: false,
  coinbasePayment: true,
  maintenanceOn: false,

  s3ImagesUrl: 'https://d4q50oe377974.cloudfront.net',

  // Firebase Variables - same as dev
  _firebase_apiKey: 'AIzaSyAg2jg13kXsQXExey0PSaj2mRwQpi-pGqU',
  _firebase_authDomain: 'dev-lingui.firebaseapp.com',
  _firebase_projectId: 'dev-lingui',
  _firebase_storageBucket: 'dev-lingui.appspot.com',
  _firebase_messagingSenderId: '870352920339',
  _firebase_appId: '1:870352920339:web:db2fafb032333aeb4e84e6',
  _firebase_vapidKey: 'BJINhqMVDiCittesK4rkBLwpMki2wO_rq53Cp9FiR2iO6ykDi9s7koLMgVNm9fJwL1hpsfyWKJci_EDhaGDocHI',

  fontGoogleApiKey: 'AIzaSyAg2jg13kXsQXExey0PSaj2mRwQpi-pGqU',
  localStorageKeyPrefix: 'lingui_app_int_',
  linkHome: 'https://int.lingui.it'
};
