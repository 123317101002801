<div class="navbar">
  <div class="container d-flex justify-content-between">
    <div class="logo">
      <a href="" class="navbar-brand title-a" >
        <img [routerLink]="['']" class="logo-desk" src='../../../../assets/img/qr-menu/logo_mob.png'
          alt="Hamburger Linguì">
      </a>
    </div>
    <div class="button-menu">
      <button class="btn btn-menu-light" [routerLink]='["/presentation"]' > {{ 'navigation.lp-r-navbar-title1' | translate}} <i class="fas fa-chevron-right" style='margin-left:50px' aria-hidden="true"></i></button>
    </div>
  </div>
</div>
