import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { AppState } from '..';
import { ConfigurationActions, SystemActions } from '../actions';
import { ConfigurationService } from '../../providers/services/configuration.service';

@Injectable()
export class ConfigurationEffects {
  constructor(private actions$: Actions, private configurationService: ConfigurationService, private store$: Store<AppState>) { }

  getExportInvoicesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getExportInvoices),
      switchMap((action) =>
        this.configurationService.getExportInvoices(action.restaurantid, action.billid).pipe(
          map(({url}) => {
            window.document.open(url, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
            return ConfigurationActions.getExportInvoicesSuccess({url})
          }),
          catchError((err) => of(ConfigurationActions.getExportInvoicesFailed({error: new Error(err.error.name)})))
        )
      )
    )
  );

  getExportOrdersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getExportOrders),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) =>
        this.configurationService.getExportOrders(storeState.restaurantStore.restaurant!.id, action.tableid, action.statusid, action.categoryid).pipe(
          map(({url}) => {
            window.document.open(url, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
            return ConfigurationActions.getExportOrdersSuccess({url})
          }),
          catchError((err) => of(ConfigurationActions.getExportOrdersFailed({error: new Error(err.error.name)})))
        )
      )
    )
  );

  getBookingStatusesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getBookingStatuses),
      switchMap(() =>
        this.configurationService.getBookingStatus().pipe(
          map(({ bookingStatuses }) => ConfigurationActions.getBookingStatusesSuccess({ bookingStatuses })),
          catchError((err) => of(ConfigurationActions.getBookingStatusesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );
  getBookingStatusesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getBookingStatusesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getClientStatusesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getClientStatuses),
      switchMap(() =>
        this.configurationService.getClientStatus().pipe(
          map(({ clientStatuses }) => ConfigurationActions.getClientStatusesSuccess({ clientStatuses })),
          catchError((err) => of(ConfigurationActions.getClientStatusesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );
  getClientStatusesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getClientStatusesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getCurrencyTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getCurrencyTypes),
      switchMap(() =>
        this.configurationService.getCurrencyType().pipe(
          map(({ currencyTypes }) => ConfigurationActions.getCurrencyTypesSuccess({ currencyTypes })),
          catchError((err) => of(ConfigurationActions.getCurrencyTypesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getCurrencyTypesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getCurrencyTypesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getMeasureUnitTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getMeasureUnitTypes),
      switchMap(() =>
        this.configurationService.getMeasureUnitType().pipe(
          map(({ measureUnits }) => ConfigurationActions.getMeasureUnitTypesSuccess({ measureUnitTypes: measureUnits })),
          catchError((err) => of(ConfigurationActions.getMeasureUnitTypesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getMeasureUnitTypesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getMeasureUnitTypesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getIngredientTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getIngredientTypes),
      switchMap(() =>
        this.configurationService.getIngredientType().pipe(
          map(({ ingredientTypes }) => ConfigurationActions.getIngredientTypesSuccess({ ingredientTypes })),
          catchError((err) => of(ConfigurationActions.getIngredientTypesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getIngredientTypesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getIngredientTypesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getOrderStatusesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getOrderStatuses),
      switchMap(() =>
        this.configurationService.getOrderStatus().pipe(
          map(({ orderStatuses }) => ConfigurationActions.getOrderStatusesSuccess({ orderStatuses })),
          catchError((err) => of(ConfigurationActions.getOrderStatusesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getOrderStatusesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getOrderStatusesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getRestaurantProductCategorysEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getRestaurantProductCategories),
      switchMap(() =>
        this.configurationService.getRestaurantProductCategory().pipe(
          map(({ restaurantProductCategories, restaurantSecondaryProductCategories }) => ConfigurationActions.getRestaurantProductCategoriesSuccess({ restaurantProductCategories, restaurantSecondaryProductCategories })),
          catchError((err) => of(ConfigurationActions.getRestaurantProductCategoriesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getRestaurantProductCategorysFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getRestaurantProductCategoriesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getRestaurantStagesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getRestaurantStages),
      switchMap(() =>
        this.configurationService.getRestaurantStage().pipe(
          map(({ restaurantStages }) => ConfigurationActions.getRestaurantStagesSuccess({ restaurantStages })),
          catchError((err) => of(ConfigurationActions.getRestaurantStagesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getRestaurantStagesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getRestaurantStagesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getRestaurantTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getRestaurantTypes),
      exhaustMap(() =>
        this.configurationService.getRestaurantType().pipe(
          map(({ restaurantTypes }) => ConfigurationActions.getRestaurantTypesSuccess({ restaurantTypes })),
          catchError((err) => of(ConfigurationActions.getRestaurantTypesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getRestaurantTypesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getRestaurantTypesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getSpecialtyTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getSpecialtyTypes),
      switchMap(() =>
        this.configurationService.getSpecialtyType().pipe(
          map(({ specialtyTypes }) => ConfigurationActions.getSpecialtyTypesSuccess({ specialtyTypes })),
          catchError((err) => of(ConfigurationActions.getSpecialtyTypesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getSpecialtyTypesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getSpecialtyTypesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getTableStatusesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getTableStatuses),
      switchMap(() =>
        this.configurationService.getTableStatus().pipe(
          map(({ tableStatuses }) => ConfigurationActions.getTableStatusesSuccess({ tableStatuses })),
          catchError((err) => of(ConfigurationActions.getTableStatusesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getTableStatusesFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getTableStatusesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getWeekDaysEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getWeekDays),
      switchMap(() =>
        this.configurationService.getWeekDay().pipe(
          map(({ weekDays }) => ConfigurationActions.getWeekDaysSuccess({ weekDays })),
          catchError((err) => of(ConfigurationActions.getWeekDaysFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getWeekDaysFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.getWeekDaysFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ConfigurationActions.getBookingStatuses,
        ConfigurationActions.getClientStatuses,
        ConfigurationActions.getCurrencyTypes,
        ConfigurationActions.getIngredientTypes,
        ConfigurationActions.getOrderStatuses,
        ConfigurationActions.getRestaurantProductCategories,
        ConfigurationActions.getRestaurantStages,
        ConfigurationActions.getRestaurantTypes,
        ConfigurationActions.getSpecialtyTypes,
        ConfigurationActions.getTableStatuses,
        ConfigurationActions.getWeekDays,
        ConfigurationActions.getExportInvoices,
        ConfigurationActions.getExportOrders
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ConfigurationActions.getBookingStatusesSuccess,
        ConfigurationActions.getBookingStatusesFailed,
        ConfigurationActions.getClientStatusesSuccess,
        ConfigurationActions.getClientStatusesFailed,
        ConfigurationActions.getCurrencyTypesSuccess,
        ConfigurationActions.getCurrencyTypesFailed,
        ConfigurationActions.getIngredientTypesSuccess,
        ConfigurationActions.getIngredientTypesFailed,
        ConfigurationActions.getOrderStatusesSuccess,
        ConfigurationActions.getOrderStatusesFailed,
        ConfigurationActions.getRestaurantProductCategoriesSuccess,
        ConfigurationActions.getRestaurantProductCategoriesFailed,
        // ConfigurationActions.getRestaurantStagesSuccess,
        ConfigurationActions.getRestaurantStagesFailed,
        ConfigurationActions.getRestaurantTypesSuccess,
        ConfigurationActions.getRestaurantTypesFailed,
        ConfigurationActions.getSpecialtyTypesSuccess,
        ConfigurationActions.getSpecialtyTypesFailed,
        ConfigurationActions.getTableStatusesSuccess,
        ConfigurationActions.getTableStatusesFailed,
        ConfigurationActions.getWeekDaysSuccess,
        ConfigurationActions.getWeekDaysFailed,
        ConfigurationActions.getExportInvoicesSuccess,
        ConfigurationActions.getExportOrdersFailed,
        ConfigurationActions.getExportOrdersSuccess,
        ConfigurationActions.getExportInvoicesFailed
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
