import { createReducer, on } from '@ngrx/store';
import moment from 'moment';
import { Booking, BookingResponse } from 'src/app/model/Booking';
import { BookingActions, CompleteActions, MessagingActions } from '../actions';

export interface BookingState {
    bookingsStatus: BookingResponse;
    currentPage: number;
    searchByDate: { startDate: string; endDate: string };
    searchByBookingStatus: string;
}

export const initialState: BookingState = {
    bookingsStatus: { bookings: [], totalBookings: 0 },
    currentPage: 1,
    searchByDate: { startDate: moment(new Date(Date.now()).toISOString()).startOf('day').toISOString(), endDate: moment(new Date(Date.now()).toISOString()).endOf('day').toISOString() },
    searchByBookingStatus: 'NOT_DEFINED',
};

export const bookingReducer = createReducer(
    initialState,
    on(BookingActions.cleanBookings, (state, action) => ({
        ...state,
        bookingsStatus: { bookings: [], totalBookings: 0 },
        currentPage: 1,
        searchByDate: { startDate: moment(new Date().toISOString()).startOf('day').toISOString(), endDate:  moment(new Date().toISOString()).endOf('day').toISOString() },
        searchByBookingStatus: 'NOT_DEFINED',
    })),
    on(BookingActions.getBookingsSuccess, (state, action) => ({ ...state, bookingsStatus: action.bookingResponse, currentPage: 1 })),
    on(CompleteActions.getTablesAndOrdersAndProductsAndBookingsSuccess, (state: BookingState, action) => ({
        ...state,
        bookingsStatus: {
            bookings: action.results[3].bookings,
            totalBookings: action.results[3].totalBookings
        },
        currentPage: 1
    })),
    on(BookingActions.getBookingsByDate, (state, action) => ({ ...state, searchByDate: { startDate: action.startDate, endDate: action.endDate } })),
    on(BookingActions.getBookingsByDateSuccess, (state, action) => ({ ...state, bookingsStatus: action.bookingResponse, currentPage: 1 })),

    on(BookingActions.getBookingsByBookingStatus, (state, action) => ({ ...state, searchByBookingStatus: action.bookingStatus })),
    on(BookingActions.getBookingsByBookingStatusSuccess, (state, action) => ({ ...state, bookingsStatus: action.bookingResponse, currentPage: 1 })),

    on(BookingActions.getBookingsByPage, (state, action) => ({ ...state, currentPage: action.page })),
    on(BookingActions.getBookingsByPageSuccess, (state, action) => ({ ...state, bookingsStatus: action.bookingResponse })),

    on(MessagingActions.bookingSentNotification, (state, action) => ({
        ...state,
        bookingsStatus: {
            ...state.bookingsStatus,
            bookings:
                JSON.parse(action.notification.data.booking).restaurant_id === action.restaurant_id ? state.bookingsStatus!.bookings.findIndex((booking) => booking.id === action.notification.data.booking_id) === -1
                    ? ([...state.bookingsStatus!.bookings, JSON.parse(action.notification.data.booking)] as Booking[])
                    : state.bookingsStatus?.bookings.map((booking) =>
                          booking.id === action.notification.data.booking_id ? JSON.parse(action.notification.data.booking) : booking
                      ) : state.bookingsStatus.bookings,
        } as BookingResponse,
    })),
    on(MessagingActions.bookingAcceptedNotification, (state, action) => ({
        ...state,
        bookingsStatus: {
            ...state.bookingsStatus,
            bookings:
                JSON.parse(action.notification.data.booking).restaurant_id === action.restaurant_id ? state.bookingsStatus!.bookings.findIndex((booking) => booking.id === action.notification.data.booking_id) === -1
                    ? ([...state.bookingsStatus!.bookings, JSON.parse(action.notification.data.booking)] as Booking[])
                    : state.bookingsStatus?.bookings.map((booking) =>
                          booking.id === action.notification.data.booking_id ? JSON.parse(action.notification.data.booking) : booking
                      ) : state.bookingsStatus.bookings,
        } as BookingResponse,
    })),
    on(MessagingActions.bookingRefusedNotification, (state, action) => ({
        ...state,
        bookingsStatus: {
            ...state.bookingsStatus,
            bookings:
                JSON.parse(action.notification.data.booking).restaurant_id === action.restaurant_id ? state.bookingsStatus!.bookings.findIndex((booking) => booking.id === action.notification.data.booking_id) === -1
                    ? ([...state.bookingsStatus!.bookings, JSON.parse(action.notification.data.booking)] as Booking[])
                    : state.bookingsStatus?.bookings.map((booking) =>
                          booking.id === action.notification.data.booking_id ? JSON.parse(action.notification.data.booking) : booking
                      ) : state.bookingsStatus.bookings,
        } as BookingResponse,
    })),
    on(MessagingActions.bookingRemovedNotification, (state, action) => ({
        ...state,
        bookingsStatus: {
            ...state.bookingsStatus,
            bookings:
                JSON.parse(action.notification.data.booking).restaurant_id === action.restaurant_id ? state.bookingsStatus!.bookings.findIndex((booking) => booking.id === action.notification.data.booking_id) === -1
                    ? ([...state.bookingsStatus!.bookings, JSON.parse(action.notification.data.booking)] as Booking[])
                    : state.bookingsStatus?.bookings.map((booking) =>
                          booking.id === action.notification.data.booking_id ? JSON.parse(action.notification.data.booking) : booking
                      ) : state.bookingsStatus.bookings,
        } as BookingResponse,
    })),
    on(MessagingActions.bookingGuestsNumberUpdatedNotification, (state, action) => ({
        ...state,
        bookingsStatus: {
            ...state.bookingsStatus,
            bookings:
                JSON.parse(action.notification.data.booking).restaurant_id === action.restaurant_id ? state.bookingsStatus!.bookings.findIndex((booking) => booking.id === action.notification.data.booking_id) === -1
                    ? ([...state.bookingsStatus!.bookings, JSON.parse(action.notification.data.booking)] as Booking[])
                    : state.bookingsStatus?.bookings.map((booking) =>
                          booking.id === action.notification.data.booking_id ? JSON.parse(action.notification.data.booking) : booking
                      ) : state.bookingsStatus.bookings,
        } as BookingResponse,
    })),
    on(MessagingActions.bookingCommentUpdatedNotification, (state, action) => ({
        ...state,
        bookingsStatus: {
            ...state.bookingsStatus,
            bookings:
                JSON.parse(action.notification.data.booking).restaurant_id === action.restaurant_id ? state.bookingsStatus!.bookings.findIndex((booking) => booking.id === action.notification.data.booking_id) === -1
                    ? ([...state.bookingsStatus!.bookings, JSON.parse(action.notification.data.booking)] as Booking[])
                    : state.bookingsStatus?.bookings.map((booking) =>
                          booking.id === action.notification.data.booking_id ? JSON.parse(action.notification.data.booking) : booking
                      ) : state.bookingsStatus.bookings,
        } as BookingResponse,
    })),
    on(MessagingActions.bookingTableNumberUpdatedNotification, (state, action) => ({
        ...state,
        bookingsStatus: {
            ...state.bookingsStatus,
            bookings:
                JSON.parse(action.notification.data.booking).restaurant_id === action.restaurant_id ? state.bookingsStatus!.bookings.findIndex((booking) => booking.id === action.notification.data.booking_id) === -1
                    ? ([...state.bookingsStatus!.bookings, JSON.parse(action.notification.data.booking)] as Booking[])
                    : state.bookingsStatus?.bookings.map((booking) =>
                          booking.id === action.notification.data.booking_id ? JSON.parse(action.notification.data.booking) : booking
                      ) : state.bookingsStatus.bookings,
        } as BookingResponse,
    }))
);
