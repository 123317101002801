import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { delay, Observable, takeUntil } from 'rxjs';
import packageInfo from '../../package.json';

import { environment } from '../environments/environment';
import * as AOS from 'aos';
import smoothScroll from 'smoothscroll-polyfill';

import { UnsubscriptionHandler } from './utils/unsubscription-handler';
import { SystemNotification } from './utils/system-notification.class';
import { NotificationSnackbarComponent } from './components/notification-snackbar/notification-snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { selectLoading, selectNotification } from './store/selectors/system.selectors';
import { AppState } from './store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: []
})
export class AppComponent extends UnsubscriptionHandler {
  loading$: Observable<boolean>;

  hostName = location.hostname;
  hideBannerVersion = false;
  title = 'GLOBAL-NextGenMenu-Frontend-Web';
  loading = true;
  public version: string = packageInfo.version;
  public env: string = environment.env;
  public isPlatformEnabled = environment.isPlatformEnabled;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private snackbar: MatSnackBar,
    public snackBar: MatSnackBar) {
      
    super();
    AOS.init();
    // kick off the polyfill!
    smoothScroll.polyfill();

    this.loading$ = this.store.select(selectLoading).pipe(delay(100));
		this.store
			.select(selectNotification)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (notification: SystemNotification | undefined): void => {
					if (notification) {
						const { duration, horizontalPosition, verticalPosition, panelClass, data } = notification.options;
						const snackBarConfig: MatSnackBarConfig = { duration, horizontalPosition, verticalPosition, panelClass, data };
						this.snackbar.openFromComponent(NotificationSnackbarComponent, snackBarConfig);
					}
				},
			});
    
  }

  showBanner(): boolean {
    return !(this.hostName === 'www.lingui.it' || this.hostName === 'lingui.it');
  }


}

