import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import * as Configuration from '../../model/Configuration';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  getBookingStatus(): Observable<Configuration.BookingStatusConfigurationResponse> {
    return this.http.get<Configuration.BookingStatusConfigurationResponse>(`${environment.backendHost}/v1/configurations/booking-status`);
  };

  getClientStatus(): Observable<Configuration.ClientStatusConfigurationResponse> {
    return this.http.get<Configuration.ClientStatusConfigurationResponse>(`${environment.backendHost}/v1/configurations/client-status`);
  };

  getCurrencyType(): Observable<Configuration.CurrencyTypeConfigurationResponse> {
    return this.http.get<Configuration.CurrencyTypeConfigurationResponse>(`${environment.backendHost}/v1/configurations/currency-type`);
  };

  getMeasureUnitType(): Observable<Configuration.MeasureUnitConfigurationResponse> {
    return this.http.get<Configuration.MeasureUnitConfigurationResponse>(`${environment.backendHost}/v1/configurations/measure-unit`);
  };

  getIngredientType(): Observable<Configuration.IngredientTypeConfigurationResponse> {
    const params: HttpParams = new HttpParams().append('limit', 10000).append('offset', 0);
    return this.http.get<Configuration.IngredientTypeConfigurationResponse>(`${environment.backendHost}/v1/configurations/ingredient-type`, { params });
  };

  getOrderStatus(): Observable<Configuration.OrderStatusConfigurationResponse> {
    return this.http.get<Configuration.OrderStatusConfigurationResponse>(`${environment.backendHost}/v1/configurations/order-status`);
  };

  getRestaurantProductCategory(): Observable<Configuration.RestaurantProductCategoriesResponse> {
    return this.http.get<Configuration.RestaurantProductCategoriesResponse>(`${environment.backendHost}/v1/configurations/restaurant-product-category`);
  };

  getRestaurantStage(): Observable<Configuration.RestaurantStagesResponse> {
    return this.http.get<Configuration.RestaurantStagesResponse>(`${environment.backendHost}/v1/configurations/restaurant-stage`);
  };

  getRestaurantType(): Observable<Configuration.RestaurantTypeConfigurationResponse> {
    return this.http.get<Configuration.RestaurantTypeConfigurationResponse>(`${environment.backendHost}/v1/configurations/restaurant-type`);
  };

  getSpecialtyType(): Observable<Configuration.SpecialtyTypeConfigurationResponse> {
    return this.http.get<Configuration.SpecialtyTypeConfigurationResponse>(`${environment.backendHost}/v1/configurations/specialty-type`);
  };

  getTableStatus(): Observable<Configuration.TableStatusConfigurationResponse> {
    return this.http.get<Configuration.TableStatusConfigurationResponse>(`${environment.backendHost}/v1/configurations/table-status`);
  };

  getWeekDay(): Observable<Configuration.WeekDayConfigurationResponse> {
    return this.http.get<Configuration.WeekDayConfigurationResponse>(`${environment.backendHost}/v1/configurations/week-day`);
  };

  // This api is handled by Subscription manager on BE side
  getSubscriptionSumUpAccountLinking(): Observable<Configuration.SumUpAccountLinking> {
    return this.http.get<Configuration.SumUpAccountLinking>(`${environment.backendHost}/v1/dashboard/restaurants/sumupaccount/url`);
  }

  // This api is handled by Subscription manager on BE side
  getExportInvoices(restaurantid: string, billid?: string): Observable<{url: string}> {
    return this.http.get<{url: string}>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantid}/bills/export`);
  }

  getExportOrders(restaurantid: string, tableid?: string, statusid?: string, categoryid?: string): Observable<{url: string}> {
    let params: HttpParams = new HttpParams();
    if(tableid) {
      params.append('tableid', tableid);
    }
    if(statusid) {
      params.append('statusid', statusid);
    }
    if(categoryid) {
      params.append('categoryid', categoryid);
    }
    
    return this.http.get<{url: string}>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantid}/orders/export`, {
      params
    });

  }
}
