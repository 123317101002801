import { Injectable } from '@angular/core';
import { Observable, Observer, throwError } from 'rxjs';
import * as IAuth from '../../model/Auth';
import { Credentials } from '../../model/Auth';

import { CreateRestaurantCognito } from '../../model/Restaurant';
import { environment } from '../../../environments/environment';

import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession, ISignUpResult,  } from 'amazon-cognito-identity-js';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userPool: CognitoUserPool;
  constructor() {
    this.userPool = new CognitoUserPool({ ClientId: environment.cognitoClientId, UserPoolId: environment.cognitoUserPoolId,  });

  }

  login(credentials: Credentials): Observable<{access_token: string, email: string}> {
		const authenticationDetails: AuthenticationDetails = new AuthenticationDetails({
			Username: credentials.email,
			Password: credentials.password
		});
		const user: CognitoUser = new CognitoUser({ Username: credentials.email, Pool: this.userPool });
    
		user.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    return new Observable((observer: Observer<any>) => {
			user.authenticateUser(authenticationDetails, {
				onSuccess: (result) => {
					const access_token = result.getIdToken().getJwtToken();
          const email = credentials.email
					observer.next({ access_token, email });
				},
				onFailure: (error) => observer.error(error.message)
			});
		});
  }

  register(credentials: CreateRestaurantCognito): Observable<ISignUpResult> {
		const attributeList: Array<CognitoUserAttribute> = new Array<CognitoUserAttribute>();
		attributeList.push(...[
			new CognitoUserAttribute({ Name: 'email', Value: credentials.email })
		]);

		return new Observable((observer: Observer<ISignUpResult | undefined>) => {
			this.userPool.signUp(credentials.email, credentials.password!, attributeList, [], (err, result) => {
				if (err) {
					return observer.error(err);
				}

				observer.next(result);
			});
		});
  }

	resendConfirmationCode(credentials: CreateRestaurantCognito): Observable<void> {
		const user: CognitoUser = new CognitoUser({ Username: credentials.email, Pool: this.userPool });
		
		return new Observable((observer: Observer<any>) => {
			user.resendConfirmationCode((err, result) => {
				if (err) {
					observer.error(err.message)
				}
				observer.next(result);
			});
		});
	}

  signOut(): Observable<boolean> {
		const user: CognitoUser | null = this.userPool.getCurrentUser();
		return new Observable((observer: Observer<any>) => {
			user?.signOut(() => observer.next(true));
		});
  }

  getToken(): IAuth.Auth {
    return {
      accessToken: sessionStorage.getItem('accessToken') as string,
      emailToken: sessionStorage.getItem('emailToken') as string
    };
  }

  saveAuth(auth: {accessToken: string, email: string}): void {
    sessionStorage.setItem('accessToken', auth.accessToken);
    sessionStorage.setItem('emailToken', auth.email);
  }

  recoverAccount(email: string): Observable<any> {
    const user: CognitoUser = new CognitoUser({ Username: email, Pool: this.userPool });
		return new Observable((observer: Observer<any>) => {
			user?.forgotPassword({
				onSuccess: (result: any) => {
					console.log('Password recovery result:', result);
					observer.next(result);
				},
				onFailure: (error: Error) => observer.error(error),
			})
		});
  }

  resetPassword(resetCredentials: IAuth.ResetPassword): Observable<any> {
		const user: CognitoUser = new CognitoUser({ Username: resetCredentials.email, Pool: this.userPool });
		return new Observable((observer: Observer<any>) => {
			user?.confirmPassword(resetCredentials.resetCode, resetCredentials.password, {
				onSuccess: (result: string) => observer.next(result),
				onFailure: (error: Error) => observer.error(error),
			})
		});
  }

  cleanAuth(): Observable<boolean> {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('emailToken');
		localStorage.clear();
		sessionStorage.clear();
    return this.signOut();
  }

  confirmAccount(confirmCredentials: IAuth.ConfirmCredentials): Observable<any> {
    const user: CognitoUser = new CognitoUser({ Username: confirmCredentials.email, Pool: this.userPool });
		return new Observable((observer: Observer<any>) => {
			user.confirmRegistration(confirmCredentials.code.toString(), false, (err, result) => {
				if (err) {
					return observer.error(err);
				}

				observer.next(result);
			})
		});
  }

  changePassword(changePassword: IAuth.ChangePassword): Observable<any> {
		const user: CognitoUser | null = this.userPool.getCurrentUser();
		if (!!user) {
			let sessionValue = null;
			user!.getSession((error: Error | null, session: CognitoUserSession | null) => {
				if (error) {
					console.log(error);
				} else {
					sessionValue = session;
				}
			});
			
			if (!!sessionValue) {
				return new Observable((observer: Observer<any>) => {
					user!.changePassword(changePassword.password, changePassword.newPassword, (err, result) => {
						if (err) {
							return observer.error(err);
						}
		
						observer.next(result);
					});
				});
			} else {
				return throwError(() => new Error('User not authenticated - missing session'));
			}
		} else {
			return throwError(() => new Error('User not authenticated - missing user'));
		}
  }

}
