import { createAction, props } from "@ngrx/store";
import { Menu } from "src/app/model/Menu";
import { Product } from "src/app/model/Product";

export const cleanMenus = createAction("[Menu] Clean Menus");

export const saveMenuProduct = createAction(
  '[MENU] Save Menu Product',
  props<{product: Product}>()
);

export const removeMenuProduct = createAction(
  '[MENU] Remove Menu Product',
  props<{product: Product}>()
);

export const saveProductColor = createAction(
  '[MENU] Save Product Color',
  props<{color: string}>()
);

export const savePriceColor = createAction(
  '[MENU] Save Price Color',
  props<{color: string}>()
);

export const saveCategoryColor = createAction(
  '[MENU] Save Category Color',
  props<{color: string}>()
);

export const saveIngredientsColor = createAction(
  '[MENU] Save Ingredients Color',
  props<{color: string}>()
);

export const saveTitleColor = createAction(
  '[MENU] Save Title Color',
  props<{color: string}>()
);

export const saveProductFont = createAction(
  '[MENU] Save Product Font',
  props<{font: string}>()
);

export const savePriceFont = createAction(
  '[MENU] Save Price Font',
  props<{font: string}>()
);

export const saveCategoryFont = createAction(
  '[MENU] Save Category Font',
  props<{font: string}>()
);

export const saveIngredientsFont = createAction(
  '[MENU] Save Ingredients Font',
  props<{font: string}>()
);

export const saveTitleFont = createAction(
  '[MENU] Save Title Font',
  props<{font: string}>()
);

export const saveAdditionalInfo = createAction(
  '[MENU] Save Additional Info',
  props<{info: string, name: string}>()
);

export const createMenu = createAction(
  '[MENU] Create Menu'
);

export const createMenuSuccess = createAction(
  '[MENU] Create Menu Success',
  props<{menuId: string}>()
);

export const createMenuFailed = createAction(
  '[MENU] Create Menu Failed',
  props<{error: Error}>()
);

export const getMenus = createAction(
  '[MENU] Get Menus'
);

export const getMenuSuccess = createAction(
  '[MENU] Get Menus Success',
  props<{menus: Menu[], totalMenus: number, refreshedMenuId?: string}>()
);

export const getMenusFailed = createAction(
  '[MENU] Get Menus Failed',
  props<{error: Error}>()
);

export const updateMenu = createAction(
  '[MENU] Update Menu'
);

export const updateMenuSuccess = createAction(
  '[MENU] Update Menu Success',
  props<{menuId: string}>()
);

export const updateMenuFailed = createAction(
  '[MENU] Update Menu Failed',
  props<{error: Error}>()
);

export const removeMenu = createAction(
  '[MENU] Remove Menu',
  props<{menuId: string}>()
);

export const removeMenuSuccess = createAction(
  '[MENU] Remove Menu Success',
  props<{menuId: string}>()
);

export const removeMenuFailed = createAction(
  '[MENU] Remove Menu Failed',
  props<{error: Error}>()
);

export const goToMenuBuilder = createAction(
  '[MENU] Go To Menu Builder'
);

export const gotToEditMenuBuilder = createAction(
  '[MENU] Go To Edit Menu Builder',
  props<{menuId: string}>()
);

export const completeEditingMenu = createAction(
  '[MENU] Complete Editing Menu'
);

export const completeEditingMenuSuccess = createAction(
  '[MENU] Complete Editing Menu Success'
);

export const completeEditingMenuFailed = createAction(
  '[MENU] Complete Editing Menu Failed',
  props<{error: Error}>()
);

export const loadMenuIntoMenuBuilder = createAction(
  '[MENU] Load Menu Into Menu Builder',
  props<{menuId: string}>()
);
