<!-- WHAT IS SECTION -->
<app-what-is-section pageType="RESTAURANT"></app-what-is-section>

<!-- WHY SECTION -->
<app-why-section pageType="RESTAURANT"></app-why-section>

<!-- WHAT DO SECTION -->
<app-what-do-section pageType="RESTAURANT"></app-what-do-section>

<!-- HOW SECTION -->
<app-how-section pageType="RESTAURANT"></app-how-section>

<!-- OFFERS CARDS SECTION -->
<app-offers-section pageType="RESTAURANT"></app-offers-section>

<!-- MOCKUP SECTION -->
<app-mockup-section pageType="RESTAURANT"></app-mockup-section>

<!-- REGISTRATION SECTION -->
<app-registration-section *ngIf="newsLetterFeature" pageType="RESTAURANT"></app-registration-section>

<div class="go-up-button" (click)="goUp()">
  <div class="btn btn-icon btn-lg up-btn"><i class="fas fa-angle-up ngm-up"></i></div>
</div>
