import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './guard/services/auth.guard';

import { LoggedInGuard } from './guard/services/loggedIn.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    },

    // {
    //     path: 'hello',
    //     loadChildren: () => import('./screens/static/presentation/presentation.module').then((m) => m.PresentationModule),
    //     data: { animation: 'PresentationPage' },
    // },
    // {
    //     path: 'aboutus',
    //     loadChildren: () => import('./screens/static/aboutus/aboutus.module').then((m) => m.AboutusModule),
    // },
    // {
    //     path: 'partner-signup',
    //     loadChildren: () => import('./screens/auth/partner-signup/partner-signup.module').then((m) => m.PartnerSignupModule),
    //     canActivate: [LoggedInGuard],
    // },
    {
        path: 'partner-register',
        loadChildren: () => import('./screens/auth/partner-register/partner-register.module').then((m) => m.PartnerRegisterModule),
        canActivate: [LoggedInGuard],
    },
    {
        path: 'partner-configurator',
        loadChildren: () => import('./screens/dashboard/partner-configurator/partner-configurator.module').then((m) => m.PartnerConfiguratorModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'partner-login',
        loadChildren: () => import('./screens/auth/partner-login/partner-login.module').then((m) => m.PartnerLoginModule),
        canActivate: [LoggedInGuard],
    },
    {
        path: 'partner-reset-password',
        loadChildren: () => import('./screens/auth/partner-reset-password/partner-reset-password.module').then((m) => m.PartnerResetPasswordModule),
        canActivate: [LoggedInGuard],
    },
    {
        path: 'partner-confirm-password',
        loadChildren: () => import('./screens/auth/partner-confirm-password/partner-confirm-password.module').then((m) => m.PartnerConfirmPasswordModule),
        canActivate: [LoggedInGuard],
    },
    {
        path: 'partner-confirm-account',
        loadChildren: () => import('./screens/auth/partner-confirm-account/partner-confirm-account.module').then((m) => m.PartnerConfirmAccountModule),
        canActivate: [LoggedInGuard],
    },
    {
        path: 'menu-builder',
        loadChildren: () => import('./screens/menu-builder/menu-builder.module').then((m) => m.MenuBuilderModule),
        canActivate: [AuthGuard],
    },
    // {
    //     path: 'restaurant',
    //     loadChildren: () => import('./screens/static/lp-restaurant/lp-restaurant.module').then((m) => m.LpRestaurantModule),
    //     data: { animation: 'LPRestaurantPage' },
    // },
    // {
    //     path: 'client',
    //     loadChildren: () => import('./screens/static/lp-client/lp-client.module').then((m) => m.LpClientModule),
    //     data: { animation: 'LPClientPage' },
    // },
    {
        path: '404',
        loadChildren: () => import('./screens/static/not-found/not-found.module').then((m) => m.NotFoundModule),
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./screens/static/maintenance/maintenance.module').then((m) => m.MaintenanceComponentModule),
    },

    {
        path: '**',
        loadChildren: () =>import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            environment.maintenanceOn
                ? [
                      { path: '', redirectTo: 'maintenance', pathMatch: 'full' },
                      {
                          path: '**',
                          loadChildren: () => import('./screens/static/maintenance/maintenance.module').then((m) => m.MaintenanceComponentModule),
                      },
                  ]
                : routes,
            {
                useHash: false,
                scrollPositionRestoration: 'top'
            }
        ),
    ],
    exports: [],
})
export class AppRoutingModule {}
