import {createAction, props} from '@ngrx/store';
import { Auth } from 'src/app/model/Auth';
import { CreateRestaurantCognito } from '../../model/Restaurant';


export const login = createAction(
  '[Auth] Login',
  props<{ email: string, password: string }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ auth: {access_token: string, email: string} }>()
);

export const loginFailed = createAction(
  '[Auth] Login Failed',
  props<{ error: Error, email: string}>()
);

export const register = createAction(
  '[Auth] Register',
  props<{ registerPayload: CreateRestaurantCognito }>()
);

export const registerSuccess = createAction(
  '[Auth] Register Success',
  props<{emailToken: string}>()
);

export const registerFailed = createAction(
  '[Auth] Register Failed',
  props<{ error: Error}>()
);

export const saveAuth = createAction(
  '[Auth] Save',
  props<{ auth: Auth }>()
);


export const resendConfirmationCode = createAction(
  '[Auth] Resend Confirmation Code',
  props<{ resendConfirmationCodePayload: {email: string} }>()
);

export const resendConfirmationCodeSuccess = createAction(
  '[Auth] Resend Confirmation Code Success',
  props<{emailToken: string}>()
);

export const resendConfirmationCodeFailed = createAction(
  '[Auth] Resend Confirmation Code Failed',
  props<{ error: Error}>()
);

export const confirmAccount = createAction(
  '[Auth] Confirm Account',
  props<{ confirmAccountPayload: {
    confirmCode: string,
    email: string
  } }>()
);

export const confirmAccountSuccess = createAction(
  '[Auth] Confirm Account Success'
);

export const confirmAccountFailed = createAction(
  '[Auth] Confirm Account Failed',
  props<{ error: Error}>()

);

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success');


export const recoverAccount = createAction(
  '[Auth] Recover Account',
  props<{ email: string }>()
);

export const recoverAccountSuccess = createAction(
  '[Auth] Recover Account Success',
  props<{ email: string }>()
);

export const recoverAccountFailed = createAction(
  '[Auth] Recover Account Failed',
  props<{ error: Error}>()
);

export const resetPassword = createAction(
  '[Auth] Reset Password',
  props<{ code: string, password: string }>()
);

export const resetPasswordSuccess = createAction(
  '[Auth] Reset Password Success'
);

export const resetPasswordFailed = createAction(
  '[Auth] Reset Password Failed',
  props<{ error: Error}>()
);