import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MenuState } from "../reducers/menu.reducers";

const selectMenuState = createFeatureSelector<MenuState>('menuStore');

export const getEditingMenu = createSelector(
  selectMenuState,
  (state: MenuState) => state.editingMenu
);

export const getMenus = createSelector(
  selectMenuState,
  (state: MenuState) => state.menu
);

export const isEditingMenu = createSelector(
  selectMenuState,
  (state: MenuState) => state.editingMenu?.id === 'pro-stubproductidchangedinsidebackend123'
)

export const getMenuApprovedStateOfMenu = (index: number) => createSelector(selectMenuState, (state: MenuState) => state.menu?.menus[index].approved);

export const getAllMenus = createSelector(selectMenuState, (state :MenuState) => state.menu?.menus)