<ng-container *ngIf="productsOfMenu$ | async as productsOfMenu">
  <ng-container *ngIf="restaurantName$ | async as restaurantName">
    <ng-container *ngIf="productCategories$ | async as productCategories">
    <div *ngIf="menuEditingMenu$ | async as menuEditingMenu" class="preview-phone">
      <img class="top-bar" src="/assets/img/qr-menu/topbar.png" />
      <div class="actions">
        <i [ngStyle]="{'color': menuEditingMenu.color_title}" class="fas fa-chevron-left left-arrow"></i>
        <h4 [ngStyle]="{'color': menuEditingMenu.color_title, 'font-weight': 'bold', 'margin-top': '10px'}">{{restaurantName === 'undefined' ? 'Menu' : restaurantName}}</h4>
        <!-- <i class="fas fa-info notify-icon" style="cursor: pointer;" (click)="toggleAdditioanlInfo()"></i> -->
        <i [ngStyle]="{'color': menuEditingMenu.color_title}" style="opacity: 0;" class="fas fa-info notify-icon"></i>
      </div>
      <!-- <div #additionalInfo class="menu-additional-info">
        <h6>Informazioni Aggiuntive</h6>
        <div class="additional-info">
          <p style="font-size: 0.7rem">{{menuEditingMenu.additional_info === 'undefined' ? 'Nessuna informazione inserita': menuEditingMenu.additional_info}}</p>
        </div>
      </div> -->
      <div class="menu-application">
        <div class="container" style="box-shadow: 0px 1px 5px #00000087">
          <p [ngStyle]="{'color': menuEditingMenu.color_product, 'font-weight': '600', 'text-align': 'left'}" style="margin-left: 25px; font-size: 12px">
            {{menuEditingMenu.name === 'undefined' || menuEditingMenu.name === undefined ? 'MENU' : menuEditingMenu.name.toUpperCase()}}</p>

          <div class="container-fluid container-categories">
            <ng-container *ngFor="let category of getProductCategories(productsOfMenu)">
                <div (click)="setMenuCurrentCategory(category)" class="container-category" style="cursor: pointer;">
                  <img [ngClass]="{'img-selected': category === menuCurrentCategory}" [src]="getFileUrl(category,
                  productCategories)" style="width: 60px;height: 60px;" />
                  <button style="font-size: 10px;min-width: 60px;" 
                  [ngClass]="{ 'btn-not-selected': category !== menuCurrentCategory }"
                  [ngStyle]="{ 'padding':'2px 4px 2px 4px','font-weight':'bold','border-radius': '30px','font-family': menuEditingMenu.font_category}"
                  class="btn btn-primary btn-category" data-aos="fade-down">{{getProductCategoryName(category,
                  productCategories) | translate}}</button>
                </div>
               
            </ng-container>
          </div>
        </div>
        <div class="container-fluid" class="product-list">
            
          <ng-container *ngFor="let product of productsOfMenu">

            <div *ngIf="product.category_id === menuCurrentCategory" class="container-fluid container-product" >
              <div class="row product-item">
                <div class="col-md-12 col-lg-12 col-xs-12">

                  <!-- <div class="text-center">
                  <ngb-carousel [showNavigationArrows]="false" class="mb-4" *ngIf="getImagesByProductId(product.id) | async as images">
                    <ng-template *ngFor="let image of createImages(images, product.restaurant_id)" ngbSlide>
                      <img alt="First slide" class="d-block carousel-image" src={{image}}>
                    </ng-template>
                  </ngb-carousel>
                </div> -->
                </div> 
                <div class="col-md-12 col-lg-12 col-xs-12 col-product-name"
                  [ngStyle]="{'padding': '2px 15px 2px 15px'}">
                  <div class="container" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px">
                    <p class="product-name" [ngStyle]="{'font-family': menuEditingMenu.font_title}">
                      {{product.name}}</p>
                    <p *ngIf="restaurantCurrency$ | async as restaurantCurrency" class="product-price"
                      [ngStyle]="{'color': '#F25116'}">
                      {{product.price.toFixed(2)}}
                      <ng-container *ngIf="currencies$ | async as currencies">
                        {{getCurrencySymbol(restaurantCurrency[0], currencies)}}
                      </ng-container>
                    </p>
                  </div>

                  <ng-container *ngIf="ingredientTypes$ | async as ingredientTypes">
                    <div *ngIf="product.ingredients[0] !== undefined" class="col-md-12 col-lg-12 col-xs-12 col-ingredients">
                      <div class="container-ingredients">
                        <p [ngStyle]="{'color': menuEditingMenu.color_ingredient, 'font-family': menuEditingMenu.font_ingredient}" style="font-size: 0.6rem;"
                          class="product-ingredient">
                          {{getFrozenIngredients(product, false)}}</p>
                      </div>
                      <hr style="margin: 0.2rem;">
                      <div class="container-ingredients">
                        <div style="margin-right:2px;display: inline;">❄️ : </div>
                        <p [ngStyle]="{'color': menuEditingMenu.color_ingredient, 'font-family': menuEditingMenu.font_ingredient}" style="font-size: 0.6rem;"
                           class="product-ingredient">
                          {{getFrozenIngredients(product, true)}}</p>
                      </div>
                    </div>
                  </ng-container>

                
                  <button (click)="removeProduct(product)" class="btn btn-danger btn-mobile" style="width: 70%;"><i
                    class="fas fa-trash"></i>Rimuovi dal menù</button>
                </div>
              </div>
            </div>

          </ng-container>

      </div>
      <h4 *ngIf="!menuCurrentCategory" style="margin-left: 10px; margin-right: 10px; text-align:center">Clicca su una categoria per visualizzare i prodotti</h4>
      </div>
    </div>
  </ng-container>
  </ng-container>
</ng-container>