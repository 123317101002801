import { createReducer, on } from "@ngrx/store";
import { SystemNotification } from "src/app/utils/system-notification.class";
import { SystemActions } from "../actions";


export interface SystemState {
    loading: boolean;
    notification?: SystemNotification;
  }

const INIT_STATE: SystemState = {
  loading: false
}

export const systemReducer = createReducer(
  INIT_STATE,
  on(SystemActions.Clean, (state, action) => ({
    ...state, loading: false, notification: undefined
  })),
  on(SystemActions.StartLoading, (state: SystemState): SystemState => ({ ...state, loading: true })),
  on(SystemActions.StopLoading, (state: SystemState): SystemState => ({ ...state, loading: false })),
  on(SystemActions.SetNotification, (state: SystemState, notification): SystemState => ({ ...state, notification }))
);