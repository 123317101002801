<h5>PRODOTTI</h5>
<h6>Aggiungi i tuoi prodotti</h6>
<div class="category-form">
  <!-- SELEZIONA I PRODOTTI DA AGGIUNGERE AL MENU' -->
  <div class="form-product-existing-container">

    <form [formGroup]="formProduct">
      <span class="span-label">Seleziona Prodotti: <i class="fas fa-pizza-slice"></i></span>
      <mat-select [disableOptionCentering]="true" placeholder="Nessun prodotto selezionato" 
        formControlName="product_type_ids" [multiple]="true">

        <mat-option>
          <ngx-mat-select-search placeholderLabel="Cerca prodotti..." noEntriesFoundLabel="Nessun prodotto trovato"
            [formControl]="productFilterCtrl"></ngx-mat-select-search>
        </mat-option>

        <!-- <ng-container>
          <div class="products-block-list-item">
            <mat-option class="mat-primary product-tile" [value]="null" (onSelectionChange)="toggleAllSelection()">
              <div class="product-block">
                <label>Seleziona tutti</label>
              </div>
            </mat-option>
          </div>
        </ng-container> -->

        <ng-container *ngFor="let product of filteredProd | async">
          <div class="products-block-list-item">
            <mat-option (click)="updateProduct(product.id)" class="mat-primary product-tile" [value]="product.id">
              <div class="product-block">
                <label>{{product.name | translate}}</label>
              </div>
            </mat-option>
          </div>
        </ng-container>
      </mat-select>
      <br>
    </form>
  </div>
</div>