import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { AppState } from '..';
import { RestaurantImageActions, SystemActions } from '../actions';
import { RestaurantImageService } from '../../providers/services/restaurant-image.service';
import { getRestaurantId } from '../selectors/restaurants.selectors';

@Injectable()
export class RestaurantImageEffects {
  constructor(private actions$: Actions, private restaurantImageService: RestaurantImageService, private store$: Store<AppState>) {}

  getImageUrlsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.getRestaurantImages),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([action, restaurant_id]) =>
        this.restaurantImageService.getRestaurantImage(restaurant_id).pipe(
          map((restaurantImages) => RestaurantImageActions.getRestaurantImagesSuccess({ restaurantImages })),
          catchError((err) => of(RestaurantImageActions.getRestaurantImagesFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  getImageUrlsSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.getRestaurantImagesSuccess),
      map((action) => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'get-restaurant-images-success' }));
      })
    )
  );

  createImageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.createRestaurantImages),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        return this.restaurantImageService
          .createRestaurantImage(storeState.restaurantStore.restaurant!.id, storeState.restaurantImageStore.imagesBuffers!)
          .pipe(
            map((restaurantImages) => RestaurantImageActions.createRestaurantImagesSuccess({ restaurantImages })),
            catchError((err) => of(RestaurantImageActions.createRestaurantImagesFailed({ error: new Error(err.error.name) })))
          );
      })
    )
  );

  createImageSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.createRestaurantImagesSuccess),
      map((action) => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'create-restaurant-images-success' }));
      })
    )
  );

  createImageFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.createRestaurantImagesFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  deleteImageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.deleteRestaurantImage),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([{ restaurantImageId }, restaurant_id]) =>
        this.restaurantImageService.deleteRestaurantImage(restaurant_id, restaurantImageId).pipe(
          map((i) => RestaurantImageActions.deleteRestaurantImageSuccess({ imageid: restaurantImageId })),
          catchError((err) => of(RestaurantImageActions.deleteRestaurantImageFailed({ error: new Error(err.error.name) })))
        )
      )
    )
  );

  deleteImageSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.deleteRestaurantImageSuccess),
      map((action) => {
        return SystemActions.SetNotification(new SystemNotification({ message: 'delete-restaurant-image-success' }));
      })
    )
  );

  deleteImageFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.deleteRestaurantImageFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: error.message });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestaurantImageActions.getRestaurantImages, RestaurantImageActions.createRestaurantImages, RestaurantImageActions.deleteRestaurantImage),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RestaurantImageActions.getRestaurantImagesSuccess,
        RestaurantImageActions.getRestaurantImagesFailed,
        RestaurantImageActions.createRestaurantImagesSuccess,
        RestaurantImageActions.createRestaurantImagesFailed,
        RestaurantImageActions.deleteRestaurantImageSuccess,
        RestaurantImageActions.deleteRestaurantImageFailed
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
