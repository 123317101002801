import { createReducer, on } from '@ngrx/store';
import { BookingSlot } from 'src/app/model/Booking';
import { BookingsSlotActions, CompleteActions } from '../actions';

export interface BookingsSlotState {
    bookingsSlots: BookingSlot[],
    hourlySlots: string[],
    free_tables: {hourly_slot: string, tables: string[]}[]
}

export const initialState: BookingsSlotState = {
    bookingsSlots: [],
    hourlySlots: [],
    free_tables: []
};

export const bookingSlotReducer = createReducer(
    initialState,
    on(BookingsSlotActions.getBookingSlotsSuccess, (state, action) => ({
        ...state,
        bookingsSlots: action.bookingSlots,
        hourlySlots: action.hourlySlots,
        free_tables: action.free_tables
    })),

    on(BookingsSlotActions.createBookingSlotSuccess, (state, action) => ({
        ...state,
        bookingsSlots: [...(state.bookingsSlots as BookingSlot[]), action.bookingSlot],
    })),
    on(CompleteActions.getReviewComponentSuccess, (state, action) => ({
        ...state,
        bookingsSlots: action.results[0].booking_slots,
    })),

    on(BookingsSlotActions.updateBookingSlotSuccess, (state, action) => ({
        ...state,
        bookingsSlots: state.bookingsSlots.map((bookingSlot) => (bookingSlot.id === action.bookingSlot.id ? action.bookingSlot : bookingSlot)),
    })),

    on(BookingsSlotActions.deleteBookingSlotSuccess, (state, action) => ({
        ...state,
        bookingsSlots: state.bookingsSlots!.filter((bookingSlot) => bookingSlot.id !== action.bookingSlotId),
    })),
    on(BookingsSlotActions.cleanBookingSlots, (state, action) => ({
        ...state,
        bookingsSlots: [],
    }))
);
