import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, zip } from 'rxjs';

import { environment } from '../../../environments/environment';
import packageInfo from '../../../../package.json';
import { Product } from 'src/app/model/Product';

@Injectable({
  providedIn: 'root',
})
export class ProductImageService {
  constructor(private http: HttpClient) {}

  getProductsImage(restaurantId: string, products: Product[]): Observable<[{ images_urls: string[] }, string][]> {
    if(products.length === 0) {
      return of([]);
    }
    return forkJoin(
      products.map((p) => {
        const images = this.getProductImage(restaurantId, p.id);
        return zip(images, of(p.id));
      })
    );
  }

  getProductImage(restaurantId: string, productId: string): Observable<{ images_urls: Array<string> }> {
    return this.http
      .get<{ images_urls: Array<string> }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products/${productId}/images`)
      .pipe(
        map((value: { images_urls: Array<string> }) => ({
          ...value,
          images_urls: value.images_urls.map(
            (imageUrl) => `${environment.s3ImagesUrl}/restaurant-gallery/${restaurantId}/product-gallery/${imageUrl}`
          ),
        }))
      );
  }

  createProductImage(restaurantId: string, productId: string, images: string[]): Observable<{ images_urls: string[] }> {
    return this.http.post<{ images_urls: string[] }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products/${productId}/images`, {
      images,
    })
    .pipe(
      map((value: { images_urls: Array<string> }) => ({
        ...value,
        images_urls: value.images_urls.map(
          (imageUrl) => `${environment.s3ImagesUrl}/restaurant-gallery/${restaurantId}/product-gallery/${imageUrl}`
        ),
      }))
    );
  }

  deleteProductImage(restaurantId: string, imageId: string, productId: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products/${productId}/images/${imageId}`);
  }

  copyImageFromProduct(restaurantId: string, productId: string, imageId: string): Observable<{ images_urls: string[] }> {
    return this.http.post<{ images_urls: string[] }>(
      `${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/products/${productId}/images/copyFrom/${imageId}`,
      {}
    );
  }
}
