import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-lp-restaurant',
  templateUrl: './lp-restaurant.component.html',
  styleUrls: ['./lp-restaurant.component.scss']
})
export class LpRestaurantComponent {

  constructor() { }

  newsLetterFeature = environment.newsLetterFeature;

  goUp(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
