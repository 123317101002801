import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { AppState } from '..';
import { CompleteActions, PreferenceActions, SystemActions } from '../actions';
import { BookingSlotService } from '../../providers/services/booking-slot.service';
import { ConfigurationService } from '../../providers/services/configuration.service';
import { OrdersService } from '../../providers/services/orders.service';
import { RestaurantImageService } from '../../providers/services/restaurant-image.service';
import { TablesService } from '../../providers/services/tables.service';
import { getRestaurantId } from '../selectors/restaurants.selectors';
import { ProductService } from 'src/app/providers/services/product.service';
import { BookingService } from 'src/app/providers/services/bookings.service';

@Injectable()
export class CompleteEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private configurationService: ConfigurationService,
    private restaurantImagesService: RestaurantImageService,
    private bookingSlotService: BookingSlotService,
    private tablesService: TablesService,
    private ordersService: OrdersService,
    private productsService: ProductService,
    private bookingService: BookingService
  ) { }

  getInitAccountConfigurationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getInitAccountConfiguration),
      switchMap(() => {
        return forkJoin([
          this.configurationService.getRestaurantType(),
          this.configurationService.getWeekDay(),
          this.configurationService.getCurrencyType(),
          this.configurationService.getSubscriptionSumUpAccountLinking()
        ]).pipe(
          map((r) => CompleteActions.getInitAccountConfigurationSuccess({ results: r })),
          catchError((err) => of(CompleteActions.getInitAccountConfigurationFailed({ error: err })))
        );
      })
    )
  );
  getInitAccountConfigurationFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getInitAccountConfigurationFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: 'init-configuration-failed' });
        return SystemActions.SetNotification(notification);
      })
    )
  );
  getInitAccountConfigurationSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getInitAccountConfigurationSuccess),
      map(() => {
        const notification = new SystemNotification({ message: 'init-configuration-success' });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getReviewComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getReviewComponent),
      withLatestFrom(this.store$.select(getRestaurantId)),
      switchMap(([action, restaurant_id]) => {
        return forkJoin([
          this.bookingSlotService.getBookingSlot(restaurant_id),
          this.restaurantImagesService.getRestaurantImage(restaurant_id),
        ]).pipe(
          map((results) => CompleteActions.getReviewComponentSuccess({ results })),
          catchError((error) => of(CompleteActions.getReviewComponentFailed({ error })))
        );
      })
    )
  );
  getReviewComponentFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getReviewComponentFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: 'review-items-retrieved-failed' });
        return SystemActions.SetNotification(notification);
      })
    )
  );
  getReviewComponentSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getReviewComponentSuccess),
      map(() => {
        const notification = new SystemNotification({ message: 'review-items-retrieved' });
        return SystemActions.SetNotification(notification);
      })
    )
  );

  getInitAccountProfileEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getInitAccountProfile),
      switchMap(() => {
        return forkJoin([
          this.configurationService.getBookingStatus(),
          this.configurationService.getCurrencyType(),
          this.configurationService.getIngredientType(),
          this.configurationService.getMeasureUnitType(),
          this.configurationService.getOrderStatus(),
          this.configurationService.getRestaurantProductCategory(),
          this.configurationService.getTableStatus(),
          this.configurationService.getWeekDay(),
          this.configurationService.getSubscriptionSumUpAccountLinking()
        ]).pipe(
          map((results) => CompleteActions.getInitAccountProfileSuccess({ results })),
          catchError((error) => of(CompleteActions.getInitAccountProfileFailed({ error })))
        );
      })
    )
  );
  getInitAccountProfileFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getInitAccountProfileFailed),
      map(({ error }) => {
        const notification = new SystemNotification({ error, message: 'init-configuration-failed' });
        return SystemActions.SetNotification(notification);
      })
    )
  );
  getInitAccountProfileSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getInitAccountProfileSuccess),
      map(() => {
        const notification = new SystemNotification({ message: 'init-configuration-success' });
        SystemActions.SetNotification(notification);
        return PreferenceActions.getRestaurantPreferences();
      })
    )
  );

  getTablesAndOrdersAndProductsAndBookingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteActions.getTablesAndOrdersAndProductsAndBookings),
      withLatestFrom(this.store$),
      switchMap(([action, state]) => {
        return forkJoin([
          this.ordersService.getOrders(
            state.restaurantStore.restaurant!.id,
            state.orderStore.currentPage,
            state.orderStore.searchByOrderStatus,
            undefined
          ),
          this.tablesService.getTables(state.restaurantStore.restaurant!.id),
          this.productsService.getProducts(state.restaurantStore.restaurant!.id, 10000, 0),
          this.bookingService.getBookings(state.restaurantStore.restaurant!.id, {
            startDate: state.bookingStore.searchByDate.startDate,
            endDate: state.bookingStore.searchByDate.endDate
          }, state.bookingStore.searchByBookingStatus, state.bookingStore.currentPage)
        ]).pipe(
          map((r) => CompleteActions.getTablesAndOrdersAndProductsAndBookingsSuccess({ results: r })),
          catchError((err) => of(CompleteActions.getTablesAndOrdersAndProductsAndBookingsFailed({ error: err })))
        );
      })
    )
  );

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CompleteActions.getInitAccountConfiguration,
        CompleteActions.getReviewComponent,
        CompleteActions.getInitAccountProfile,
        CompleteActions.getTablesAndOrdersAndProductsAndBookings
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CompleteActions.getInitAccountConfigurationFailed,
        CompleteActions.getInitAccountConfigurationSuccess,
        CompleteActions.getReviewComponentSuccess,
        CompleteActions.getReviewComponentFailed,
        // CompleteActions.getInitAccountProfileSuccess,
        // CompleteActions.getInitAccountProfileFailed,
        CompleteActions.getTablesAndOrdersAndProductsAndBookingsSuccess,
        CompleteActions.getTablesAndOrdersAndProductsAndBookingsFailed
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
