import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MockupSectionComponent } from './mockup-section.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MockupSectionComponent],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    RouterModule
  ],
  exports : [MockupSectionComponent]
})
export class MockupSectionModule { }
