import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable, from } from 'rxjs';
import firebase from "firebase/app";
import 'firebase/messaging';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject({});
  constructor() {}

  requestPermission(): Observable<string> {
    const messaging = firebase.messaging();
    
    return from(messaging.getToken({vapidKey: environment._firebase_vapidKey,}).then(
        (currentToken) => {
          
          if (currentToken) {
            environment.env === 'DEV' || environment.env === 'INT' ? console.log('Notification Token: '+currentToken) : null;
            return currentToken;

          } else {
            environment.env === 'DEV' || environment.env === 'INT' ? console.log('No registration token available. Request permission to generate one.') : null;
            return 'EMPTY';
          }
      }).catch((err) => {
         console.log('An error occurred while retrieving token. ', err);
         return 'ERROR_TOKEN';
     }));
  }

  receiveBackgroundMessage(payload: {}): Observable<{}> {
    return of(payload);
  }

  receiveForegroundMessage(payload: {}): Observable<{}> {
    return of(payload);
  }



}
