import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../../utils/directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        DirectivesModule,
        NgbModule,
    ],
    declarations: [ TopNavComponent ],
    exports: [ TopNavComponent ],

    providers: []
})
export class TopNavModule {

}

