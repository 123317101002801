<!-- 404 -->
<div class="not-found">
  <div class="container-fluid pr-0">
    <div class="col-12 d-flex justify-content-end pr-0">
      <img src='../../../../assets/img/qr-menu/404.svg' alt="404 Linguì">
    </div>
  </div>
  <div class="container-fluid pr-0 d-flex flex-column justify-content-center mb-5">
      <h2 class="">{{'notFoundSection.not-found-text' | translate}}</h2>
      <h2>{{'notFoundSection.not-found-text-1' | translate}}</h2>
  </div>
</div>
<!-- 404 -->
