<!-- WHAT IS SECTION -->
<app-what-is-section
  [pageType]='"CLIENT"'
></app-what-is-section>

<!-- WHY SECTION -->
<app-why-section [pageType]='"CLIENT"'></app-why-section>

<!-- WHAT DO SECTION -->
<app-what-do-section [pageType]='"CLIENT"'></app-what-do-section>

<!-- HOW SECTION -->
<app-how-section  [pageType]='"CLIENT"'></app-how-section>

<!-- MOCKUP SECTION -->
<app-mockup-section [pageType]='"CLIENT"'></app-mockup-section>

<!-- REGISTRATION SECTION -->
<app-registration-section *ngIf="newsLetterFeature" [pageType]='"CLIENT"'></app-registration-section>

<div class="go-up-button" (click)="goUp()">
  <div class="btn btn-icon btn-lg up-btn"><i class="fas fa-angle-up ngm-up"></i></div>
</div>