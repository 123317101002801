import { Restaurant } from '../../model/Restaurant';
import { createReducer, on } from '@ngrx/store';
import { RestaurantActions, TableActions } from '../actions';
import { loginSuccess } from '../../guard/store/auth.actions';
import { state } from '@angular/animations';

export interface RestaurantState {
  restaurant?: Restaurant;
}

export const initialState: RestaurantState = {};

export const restaurantReducer = createReducer(
  initialState,
  on(RestaurantActions.getProfileRestaurantSuccess, (state, action) => ({
    ...state,
    restaurant: JSON.stringify(action.restaurant) === "{}" ? undefined : action.restaurant,
  })),
  on(loginSuccess, (state, action) => ({ ...state, restaurant: { ...state.restaurant, email: action.auth.email } as Restaurant })),
  on(RestaurantActions.createRestaurantSuccess, (state, action) => ({
    ...state,
    restaurant: { ...state.restaurant, id: action.restaurantid, email: action.email, name: action.name, address: action.address, admin_name: action.admin_name, phone_number: action.phone_number, type_id: action.type_id, city: action.city, pin: action.pin } as Restaurant,
  })),
  on(TableActions.createTablesSuccess, (state, action) => ({...state, restaurant: {...state.restaurant, max_customers_number: action.customers} as Restaurant})),

  // Application Reducers - Not using side effects
  on(RestaurantActions.saveRestaurantType, (state, { type_id }) => ({
    ...state,
    restaurant: { ...state.restaurant, type_id } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantAddress, (state, { address }) => ({
    ...state,
    restaurant: { ...state.restaurant, address } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantCity, (state, { city }) => ({
    ...state,
    restaurant: { ...state.restaurant, city } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantLatitude, (state, { latitude }) => ({
    ...state,
    restaurant: { ...state.restaurant, latitude } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantLongitude, (state, { longitude }) => ({
    ...state,
    restaurant: { ...state.restaurant, longitude } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantPhoneNumber, (state, { phone_number }) => ({
    ...state,
    restaurant: { ...state.restaurant, phone_number } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantEmail, (state, { email }) => ({
    ...state,
    restaurant: { ...state.restaurant, email } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantStage, (state, { stage_id }) => ({
    ...state,
    restaurant: { ...state.restaurant, stage_id } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantPlan, (state, { plan_name }) => ({
    ...state,
    restaurant: { ...state.restaurant, plan_name } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantName, (state, { name }) => ({
    ...state,
    restaurant: { ...state.restaurant, name } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantAdminName, (state, { admin_name }) => ({
    ...state,
    restaurant: { ...state.restaurant, admin_name } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantPIN, (state, { pin }) => ({
    ...state,
    restaurant: { ...state.restaurant, pin } as Restaurant,
  })),
  on(RestaurantActions.saveRestaurantCurrencies, (state, { currency_ids }) => ({
    ...state,
    restaurant: { ...state.restaurant, currency_ids } as Restaurant,
  })),
  on(RestaurantActions.cleanRestaurantStatus, (state, action) => ({
    ...state,
    restaurant: undefined,
  })),
  on(RestaurantActions.enableSumUpAccountSuccess, (state, {}) => ({
    ...state,
    restaurant: { ...state.restaurant, payments_accepted: { ...state.restaurant?.payments_accepted, sumup: true}} as Restaurant
  })),
  on(RestaurantActions.enableSatispayAccountSuccess, (state, {}) => ({
    ...state,
    restaurant: { ...state.restaurant, payments_accepted: { ...state.restaurant?.payments_accepted, satispay: true}} as Restaurant
  })),
  on(RestaurantActions.enableClassicAccountSuccess, (state, {}) => ({
    ...state,
    restaurant: { ...state.restaurant, payments_accepted: { ...state.restaurant?.payments_accepted, classic: true}} as Restaurant
  })),
  on(RestaurantActions.saveRestaurantSumup, (state, action) => ({
    ...state,
    restaurant: { ...state.restaurant, payments_accepted: { ...state.restaurant?.payments_accepted, sumup: action.value}} as Restaurant
  })),
  on(RestaurantActions.saveRestaurantClassic, (state, action) => ({
    ...state,
    restaurant: { ...state.restaurant, payments_accepted: { ...state.restaurant?.payments_accepted, classic: action.value}} as Restaurant
  })),
  on(RestaurantActions.saveRestaurantSatispay, (state, action) => ({
    ...state,
    restaurant: { ...state.restaurant, payments_accepted: { ...state.restaurant?.payments_accepted, satispay: action.value}} as Restaurant
  }))
);
