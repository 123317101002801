import { Component, Input, OnInit } from '@angular/core';
import { PageType } from '../../../utils/common-functions';
import { WhySectionConfiguration, whySectionContentConfiguration } from './why-section.content-configuration';

@Component({
  selector: 'app-why-section',
  templateUrl: './why-section.component.html',
  styleUrls: ['./why-section.component.scss']
})
export class WhySectionComponent implements OnInit {


  constructor() { }

  @Input() pageType!: PageType;
  public whySectionConfiguration!: WhySectionConfiguration;

  ngOnInit(): void {
    this.whySectionConfiguration = whySectionContentConfiguration.get(this.pageType) as WhySectionConfiguration;
  }

}
