<div class="registration-section-container">
  <div class="row registration-section-row">
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
      <h3 data-aos="fade-up" [style.color]="registrationSectionConfiguration.registrationText.colorTitle"> {{registrationSectionConfiguration.registrationText.title | translate}} </h3>
      <p data-aos="fade-up"> {{registrationSectionConfiguration.registrationText.par | translate}} </p>
    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
      <div class="registration-section-email">
        <i [style.color]="registrationSectionConfiguration.registrationText.colorTitle" class="fas fa-envelope registration-section-icon"></i>
        <input class="registration-section-input" type="email" id="email" placeholder="{{registrationSectionConfiguration.registrationText.input | translate}}" name="email" >
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 container-btn">
      <button data-aos="zoom-in" type="button" class="btn registration-btn" [style.backgroundColor]="registrationSectionConfiguration.registrationText.colorTitle" > {{registrationSectionConfiguration.registrationText.cta | translate}} </button>
    </div>
  </div>
</div>
