import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationSectionComponent } from './registration-section.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RegistrationSectionComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    TranslateModule
  ],
  exports : [ RegistrationSectionComponent]
})
export class RegistrationSectionModule { }
