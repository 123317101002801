import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionCardsModule } from 'src/app/shared/subscription-cards/subscription-cards.module';
import { OffersSectionComponent } from './offers-section.component';

@NgModule({
  declarations: [OffersSectionComponent],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    SubscriptionCardsModule
  ],
  exports : [OffersSectionComponent]
})
export class OffersSectionModule { }
