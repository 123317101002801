import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Menu } from 'src/app/model/Menu';
import { AppState } from 'src/app/store';
import { MenuActions } from 'src/app/store/actions';


@Component({
  selector: 'app-menu-color-picker',
  templateUrl: './menu-color-picker.component.html',
  styleUrls: ['./menu-color-picker.component.scss']
})
export class MenuColorPickerComponent {

  @Input() editingMenu: Menu | undefined | null;

  constructor(
    private store: Store<AppState>
  ) {

  }

  showTitleColor = false; showCategoryColor = false; showProductColor = false; showIngredientsColor = false; showPriceColor = false;
  colors = ['#F25115', '#FFDA16', '#0DAEE0', '#1A2C4C', '#D3D930']
  titleColor = '#F25115'; categoryColor = '#c63d0c'; productColor = '#f47243'; ingredientsColor = '#000'; priceColor = '#000';

  chooseColor($event: any) {
    this.showColor($event.color.hex);
  }

  showColor(color?: string) {
    if (color === '#f25115') {
      this.titleColor = '#c63d0c';
      this.store.dispatch(MenuActions.saveTitleColor({ color:'#c63d0c' }));
      this.productColor = color;
      this.store.dispatch(MenuActions.saveProductColor({ color }));
      this.categoryColor = '#f47243';
      this.store.dispatch(MenuActions.saveCategoryColor({ color: '#f47243' }));
    }
    if (color === '#ffda16') {
      this.titleColor = '#e0bb00';
      this.store.dispatch(MenuActions.saveTitleColor({ color: '#e0bb00'}));
      this.productColor = color;
      this.store.dispatch(MenuActions.saveProductColor({ color }));
      this.categoryColor = '#fbe991';
      this.store.dispatch(MenuActions.saveCategoryColor({ color: '#fbe991' }));
    }
    if (color === '#0daee0') {
      this.titleColor = '#0a87ae';
      this.store.dispatch(MenuActions.saveTitleColor({ color: '#0a87ae' }));
      this.productColor = color;
      this.store.dispatch(MenuActions.saveProductColor({ color }));
      this.categoryColor = '#2bc4f3';
      this.store.dispatch(MenuActions.saveCategoryColor({ color: '#2bc4f3' }));
    }
    if (color === '#1a2c4c') {
      this.titleColor = '#0d1626';
      this.store.dispatch(MenuActions.saveTitleColor({ color: '#0d1626' }));
      this.productColor = color;
      this.store.dispatch(MenuActions.saveProductColor({ color }));
      this.categoryColor = '#274372';
      this.store.dispatch(MenuActions.saveCategoryColor({ color: '#274372' }));
    }
    if (color === '#d3d930') {
      this.titleColor = '#b0b521';
      this.store.dispatch(MenuActions.saveTitleColor({ color: '#b0b521' }));
      this.productColor = color;
      this.store.dispatch(MenuActions.saveProductColor({ color }));
      this.categoryColor = '#e0e2a1';
      this.store.dispatch(MenuActions.saveCategoryColor({ color: '#e0e2a1' }));
    }
  }

}



