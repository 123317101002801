import { createReducer, on } from '@ngrx/store';
import { BookingActions, MessagingActions, OrderActions, TableActions } from '../actions';

export enum NOTIFICATION_IDs {
  // Bookings Notifications
  RESTAURANT_BOOKING_STATUS_BOOKING_ACCEPTED,
  RESTAURANT_BOOKING_STATUS_BOOKING_CANCELLED,
  RESTAURANT_BOOKING_STATUS_BOOKING_COMMENT_UPDATE,
  RESTAURANT_BOOKING_STATUS_BOOKING_GUESTS_NUMBER_UPDATE,
  RESTAURANT_BOOKING_STATUS_BOOKING_REFUSED,
  RESTAURANT_BOOKING_STATUS_BOOKING_SENT,

  // Order Notifications
  RESTAURANT_ORDER_STATUS_ORDER_COMMENT,
  RESTAURANT_ORDER_STATUS_ORDER_COMPLETED,
  RESTAURANT_ORDER_STATUS_ORDER_DONE,
  RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_COINBASE,
  RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_SUMUP,
  RESTAURANT_ORDER_STATUS_ORDER_PAID_WITH_SATISPAY,
  RESTAURANT_ORDER_STATUS_ORDER_PAID,
  RESTAURANT_ORDER_STATUS_ORDER_PREPARING,
  RESTAURANT_ORDER_STATUS_ORDER_RECEIVED,
  RESTAURANT_ORDER_STATUS_ORDER_SENT,
  RESTAURANT_ORDER_STATUS_ORDER_TO_PAY,
  RESTAURANT_ORDER_STATUS_ORDER_WAITRESS_COMMENT,

  // Table Notifications
  RESTAURANT_TABLE_STATUS_ATTENTION_COMPLETED,
  RESTAURANT_TABLE_STATUS_ATTENTION_NEEDED,
  RESTAURANT_TABLE_STATUS_TABLE_DISABLED,
  RESTAURANT_TABLE_STATUS_TABLE_ENABLED,
  RESTAURANT_TABLE_STATUS_TABLE_USE
}

export type NotificationGravity = 'INFO' | 'AVAILABLE' | 'WARNING' | 'DANGER'

export interface NotificationItem {
  notificationId?: string;
  table_id?: string;
  order_id?: string;
  booking_id?: string;
  product_id?: string;
  status_id?: string;
  gravity: NotificationGravity;
  timestamp: number;
  table?: any;
  order?: any;
  booking?: any;
}

export interface MessagingState {
  token: string;
  lastMessage: Message | undefined;
  messages: Message[] | {}[];
  tableNotifications: NotificationItem[];
  orderNotifications: NotificationItem[];
  bookingNotifications: NotificationItem[];
}

export const initialState: MessagingState = {
  token: 'NOT_DEFINED_YET',
  lastMessage: undefined,
  messages: [],
  tableNotifications: [],
  orderNotifications: [],
  bookingNotifications: []
  // preferences to receive notifications are available inside restaurant store
};

export interface Message {
  notification: {
    title: string;
    subtitle: string;
    body: string;
  }
  data: any;
}


export const messagingReducer = createReducer(
  initialState,
  on(MessagingActions.receiveMessageSuccess, (state, action) => ({...state,
    messages: state.lastMessage !== undefined ? [...state.messages, state.lastMessage] : state.messages,
    lastMessage: action.message,
    token: state.token
  })),
  on(MessagingActions.requestTokenSuccess, (state, action) => ({...state, token: action.token})),
  on(MessagingActions.cleanMessagingStore, state => ({...state, statusType: 'IDLE', lastMessage: undefined, messages: [], errorType: 'None'})),
  on(MessagingActions.activateTableNotification, (state, action) => ({...state, tableNotifications: state.tableNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.attentionAtTheTableNotification, (state, action) => ({...state, tableNotifications: state.tableNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderSentNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderReceivedNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.preparingOrderNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderDoneNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderPaidNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderPaidWithSumUpNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderPaidWithCoinbaseNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderPaidWithSatispayNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderToPayNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderCompletedNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.orderWaitressCommentNotification, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.bookingAcceptedNotification, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.bookingSentNotification, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.bookingRefusedNotification, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.bookingRemovedNotification, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.bookingCommentUpdatedNotification, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.bookingGuestsNumberUpdatedNotification, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(MessagingActions.bookingTableNumberUpdatedNotification, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(notification => notification.notificationId === action.notification.data.notificationId)})),
  on(OrderActions.getOrdersSuccess, (state, action) => ({...state, orderNotifications: []})),
  on(TableActions.getTablesStatusSuccess, (state, action) => ({...state, tableNotifications: []})),
  on(BookingActions.getBookingsSuccess, (state, action) => ({...state, bookingNotifications: []})),
  on(OrderActions.updateOrderStatusSuccess, (state, action) => ({...state, orderNotifications: state.orderNotifications.filter(order=> !action.order_ids.includes(order.order_id!))})),
  on(BookingActions.updateBookingSuccess, (state, action) => ({...state, bookingNotifications: state.bookingNotifications.filter(booking=> booking.booking_id !== action.bookingId)})),
  on(TableActions.attentionTableSuccess, (state, action) => ({...state, tableNotifications: state.tableNotifications.filter(table=> table.table_id !== action.tableId)})),
);