import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OffersCard } from 'src/app/components/presentation/offers-section/models/offers-card.interface';
import { OFFERS } from 'src/app/components/presentation/offers-section/offers-section.content-configuration';

@Component({
  selector: 'app-subscription-cards',
  templateUrl: './subscription-cards.component.html',
  styleUrls: ['./subscription-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionCardsComponent implements OnInit {
  cards: Array<OffersCard> | undefined;
  @Input() completeConfiguration: ((plan: string) => void) | undefined;
  @Input() textButton: string | undefined;

  constructor() {
    this.textButton = undefined;
  }

  ngOnInit(): void {
    this.cards = OFFERS.get('RESTAURANT')?.cards;
  }

  executeCallback(plan: string) {
    if (this.completeConfiguration) {
      this.completeConfiguration(plan);
    }
  }

  openMail(): void {
    const link = document.createElement('a')
    link.href = 'mailto:sales@lingui.it';
    link.click()
  }

}
