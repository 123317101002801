
<div class='spinner-background' *ngIf="loading$ | async">
  <div class="opacity-disable-button spinner-mat" disabled>
    <mat-spinner [diameter]="120" class="custom-spinner primary-spinner" strokeWidth="5"></mat-spinner>
  </div>
</div>



<!-- <div *ngIf="showBanner()" class="version">{{env}}-{{version}}</div> -->
<main>
  <section class="content-container" >
    <router-outlet #outlet="outlet"></router-outlet>
  </section>
  <section class="footer-container">
    <app-footer></app-footer>
  </section>
</main>
