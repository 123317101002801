<div class="container-fluid bg-dark-gray">
  <div class="container pt-4 pb-4">
    <div class="row pe-3 ps-3">
      <div class="col-12 col-md-1"></div>
      <div class="col-6 col-md-3 ps-md-0">
        <h5 class="text-white fw-700">Linguì</h5>
        <a [href]="linkHome" class="text-white mb-1">Home</a>
        <a [href]="linkHome" class="text-white d-none d-md-block mb-1">Come Funziona?</a>
        <a [href]="linkHome+'/pricing'" class="text-white d-none d-md-block mb-1">I nostri piani</a>
        <a [href]="linkHome" class="text-white d-none d-md-block  mb-1">Terms of conditions</a>
        <a [href]="linkHome" class="text-white d-none d-md-block  mb-1">Privacy</a>
      </div>
      <div class="col-6 col-md-3">
        <div class="container-contacts">
          <h5 class="text-white fw-700">Contact us</h5>
          <p class="text-white mb-1">Corso Novara 96, 10152 Torino TO</p>
          <p class="text-white d-md-none">Sales@Linguì.it</p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row d-flex flex-md-column pt-1 pb-1 pt-md-0 pb-md-0">
          <div class="d-flex justify-content-md-end justify-content-sm-center">
            <div class="container-icon me-1 ms-1">
              <a href="https://www.facebook.com/people/Lingu%C3%AC/100088836702755/" target="_blank">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
            </div>
            <div class="container-icon me-1 ms-1">
              <a href="https://www.instagram.com/lingui_app/" target="_blank">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </div>
            <div class="container-icon me-1 ms-1">
              <a href="https://www.linkedin.com/company/lingu%C3%AC/" target="_blank">
                <i class="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
            <div class="container-icon me-1 ms-1">
              <a href="https://www.youtube.com/channel/UCLW5ymIofk0Rn1ZL9jPk48w" target="_blank">
                <i class="fab fa-youtube"></i>
              </a>
            </div>
            <div class="container-icon me-1 ms-1">
              <a href="mailto:info@lingui.it" target="_blank">
                <i class="fa-solid fa-envelope"></i>
              </a>
            </div>
          </div>
          <div>

          </div>
        </div>
      </div>
      <div class="col-12 col-md-1"></div>
    </div>

    <div class="row">
      <div class="col-12 col-md-1"></div>
      <div class="col-12 col-md-10">
        <hr class="text-white">
      </div>
      <div class="col-12 col-md-1"></div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="text-center text-white fw-500">Copyright © 2021 Bit Software Solutions, All rights reserved</p>
      </div>
    </div>
  </div>
</div>
