import { createAction, props } from "@ngrx/store";
import { TableResponse } from "src/app/model/Table";

export const cleanTablesStore = createAction("[Table] Clean Tables Store");

export const getTablesStatus = createAction(
  '[TABLES] Get Tables'
);
export const getTablesStatusSuccess = createAction(
  '[TABLES] Get Tables Success',
  props<{tableResponse: TableResponse}>()
);
export const getTablesStatusFailed = createAction(
  '[TABLES] Get Tables Failed',
  props<{error: Error}>()
);

export const getTablesByTableStatus = createAction(
  '[ORDERS] Get Tables By Table Status',
  props<{tableStatusId: string | undefined}>()
);
export const getTablesByTableStatusSuccess = createAction(
  '[ORDERS] Get Tables By Table Status Success',
  props<{tables: TableResponse}>()
);
export const getTablesByTableStatusFailed = createAction(
  '[ORDERS] Get Tables By Table Status Failed',
  props<{error: Error}>()
);

export const enableTable = createAction(
  '[TABLES] Enable Table',
  props<{tableId: string, statusId: string}>()
);
export const enableTableSuccess = createAction(
  '[TABLES] Enable Table Success',
  props<{tableId: string, statusId: string}>()
);
export const enableTableFailed = createAction(
  '[TABLES] Enable Table Failed',
  props<{error: Error}>()
);

export const unableTable = createAction(
  '[TABLES] Unable Table',
  props<{tableId: string, statusId: string}>()
);
export const unableTableSuccess = createAction(
  '[TABLES] Unable Table Success',
  props<{tableId: string, statusId: string}>()
);
export const unableTableFailed = createAction(
  '[TABLES] Unable Table Failed',
  props<{error: Error}>()
);


export const attentionTable = createAction(
  '[TABLES] Complete Table',
  props<{tableId: string}>()
);
export const attentionTableSuccess = createAction(
  '[TABLES] Complete Table Success',
  props<{tableId: string}>()
);
export const attentionTableFailed = createAction(
  '[TABLES] Complete Table Failed',
  props<{error: Error}>()
);

export const createTables = createAction(
  '[TABLES] Create Tables',
  props<{rangeOfTables: {
    from: number,
    to: number
  }[], customers: number}>()
);
export const createTablesSuccess = createAction(
  '[TABLES] Create Tables Success',
  props<{customers: number}>()
);
export const createTablesFailed = createAction(
  '[TABLES] Create Tables Failed',
  props<{error: Error}>()
);