import { createAction, props } from "@ngrx/store";
import { BookingResponse, CreateBooking, StatusBooking, UpdateBooking } from "src/app/model/Booking";

export const cleanBookings = createAction("[Bookings] Clean Bookings");

export const getBookings = createAction(
    '[BOOKINGS] Get Bookings'
);
export const getBookingsSuccess = createAction(
    '[BOOKINGS] Get Bookings Success',
    props<{bookingResponse: BookingResponse}>()
);
export const getBookingsFailed = createAction(
    '[BOOKINGS] Get Bookings Failed',
    props<{error: Error}>()
);

export const createBooking = createAction(
    '[BOOKINGS] Create Booking',
    props<{payload: CreateBooking}>()
);
export const createBookingSuccess = createAction(
    '[BOOKINGS] Create Booking Success'
);
export const createBookingFailed = createAction(
    '[BOOKINGS] Create Bookings Failed',
    props<{error: Error}>()
);

export const updateBooking = createAction(
    '[BOOKINGS] Update Booking',
    props<{bookingId: string, payload: UpdateBooking}>()
);
export const updateBookingSuccess = createAction(
    '[BOOKINGS] Update Booking Success',
    props<{bookingId: string, statusBooking: StatusBooking}>()
);
export const updateBookingFailed = createAction(
    '[BOOKINGS] Update Bookings Failed',
    props<{error: Error}>()
);

export const updateBookingStatus = createAction(
    '[BOOKINGS] Update Booking Status',
    props<{bookingId: string, statusBookingId: string}>()
);
export const updateBookingStatusSuccess = createAction(
    '[BOOKINGS] Update Booking Status Success',
    props<{bookingId: string, statusBookingId: string}>()
);
export const updateBookingStatusFailed = createAction(
    '[BOOKINGS] Update Booking Status Failed',
    props<{error: Error}>()
);

export const getBookingsByDate = createAction(
    '[BOOKINGS] Get Bookings By Date',
    props<{startDate: string, endDate: string}>()
);
export const getBookingsByDateSuccess = createAction(
    '[BOOKINGS] Get Bookings By Date Success',
    props<{bookingResponse: BookingResponse}>()
);
export const getBookingsByDateFailed = createAction(
    '[BOOKINGS] Get Bookings By Date Failed',
    props<{error: Error}>()
);

export const getBookingsByBookingStatus = createAction(
    '[BOOKINGS] Get Bookings By Booking Status',
    props<{bookingStatus: string}>()
);
export const getBookingsByBookingStatusSuccess = createAction(
    '[BOOKINGS] Get Bookings By Booking Status Success',
    props<{bookingResponse: BookingResponse}>()
);
export const getBookingsByBookingStatusFailed = createAction(
    '[BOOKINGS] Get Bookings By Booking Status Failed',
    props<{error: Error}>()
);

export const getBookingsByPage = createAction(
    '[BOOKINGS] Get Bookings By Page',
    props<{page:number}>()
);
export const getBookingsByPageSuccess = createAction(
    '[BOOKINGS] Get Bookings By Page Success',
    props<{bookingResponse: BookingResponse}>()
);
export const getBookingsByPageFailed = createAction(
    '[BOOKINGS] Get Bookings By Page Failed',
    props<{error: Error}>()
);