import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Hamburger } from 'src/app/model/LandingPage';
import { PageType } from '../../../utils/common-functions';
import { TopNavConfiguration, topNavContentConfiguration } from '../top-nav/top-nav.content-configuration';
import * as AuthActions from '../../../guard/store/auth.actions';
import { Store } from '@ngrx/store';
import * as RestaurantSelectors from '../../../store/selectors/restaurants.selectors';
import * as ConfigurationSelectors from '../../../store/selectors/configuration.selectors';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/store';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-navbar-res',
  templateUrl: './navbar-res.component.html',
  styleUrls: ['./navbar-res.component.scss'],
  animations: []
})
export class NavbarResComponent implements OnInit {

  @Input() pageType!: PageType;
  public topNavConfiguration!: TopNavConfiguration;
  hamburgerTop!: Hamburger;
  hamburgerBot!: Hamburger;
  isPlatformEnabled!: boolean;

  constructor(private router: Router, private store: Store<AppState>) {
    
  }

  restaurantStage$ = this.store.select(RestaurantSelectors.getRestaurantStage);
  
  getStageName(stageId: string): Observable<string | undefined> {
    return this.store.select(ConfigurationSelectors.getRestaurantStageNameById({ stageId }));
  }


  avatarBannerOpened = () => (
    this.topNavConfiguration.toLetRestaurantEnter ?
      'avatar-banner-r-rest-responsive-opened'
      : 'avatar-banner-r-client-responsive-opened'
  )

  ngOnInit(): void {
    this.isPlatformEnabled = environment.isPlatformEnabled;
    this.topNavConfiguration = topNavContentConfiguration.get(this.pageType) as TopNavConfiguration;
    this.hamburgerTop = {
      img: 'assets/img/qr-menu/LP-Restaurant/hamburgerTop.svg',
      title: 'lp-r-what-is-section-hamburgerTop',
    };

    this.hamburgerBot = {
      img: 'assets/img/qr-menu/LP-Restaurant/hamburgerBot.svg',
      title: 'lp-r-what-is-section-hamburgerBot',
    };

  }

  scrollToElement(link: string): void {
    this.topNavConfiguration.toggleMenu = false;
    this.topNavConfiguration.dirtyMenuClasses = this.topNavConfiguration.dirtyMenuClassClosing;
    this.topNavConfiguration.dirtyMenuBanner = this.topNavConfiguration.dirtyMenuBannerClosing;
    if (link === 'aboutus') {
      this.router.navigateByUrl('/aboutus');
      window.scrollTo(0,0);
    }
    if ( link === 'home') {
      this.router.navigateByUrl('/restaurant');
      window.scrollTo(0,0);
    }
    if ( link === 'hello') {
      this.router.navigateByUrl('/hello');
      window.scrollTo(0,0);
    }
    const element = document.querySelector(`#${link}`);
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }
}
