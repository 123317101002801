import {PageType} from '../../../utils/common-functions';
import { MockupInfo } from '../../../model/LandingPage';

export interface MockupSectionConfiguration {
  mockupInfo: MockupInfo;
}

export const mockupSectionContentConfiguration: Map<PageType, MockupSectionConfiguration> = new Map([

  ['RESTAURANT', {
    mockupInfo: {
      title: 'mockupsSection.lp-r-mockup-section-title',
      par: 'mockupsSection.lp-r-mockup-section-subtitle',
      cta: 'mockupsSection.lp-r-mockup-section-cta',
      img: 'assets/img/qr-menu/LP-Restaurant/screenshot.png',
      colorGreen: '#5b8c2a', /* lp-client - '#F25116' */
    }
  }],

  ['CLIENT', {
    mockupInfo: {
      title: 'mockupsSection.lp-c-mockup-section-title',
      par: 'mockupsSection.lp-c-mockup-section-subtitle',
      cta: 'mockupsSection.lp-c-mockup-section-cta',
      img: 'assets/img/qr-menu/LP-Client/iphone-mockup.png',
      colorGreen: '#F25116', /* lp-client - '#F25116' */
    }
  }]

]);

