import {PageType} from '../../../utils/common-functions';
import { NavBar, WhatIs } from '../../../model/LandingPage';

export interface TopNavConfiguration {
  navbar: NavBar;
  navTitle: { name: string, link: string }[];
  toggleMenu: boolean;
  toLetRestaurantEnter: boolean;
  whatIsParam: WhatIs;
  avatarStyle: {background: string; border: string; color: string};
  dirtyMenuBanner: string;
  dirtyMenuClasses: string;
  bannerBackground: string;
  dirtyMenuClassClosing: string;
  dirtyMenuClassOpened: string;
  dirtyMenuBannerClosing: string;
  dirtyMenuBannerOpening: string;
  marginAvatarMobile: string;
  navListWrapperOpenTop: string;
  resCircleOpen: string;
  topCliCircleOpen: string;
  topResCircleOpen: string;
}

export const topNavContentConfiguration: Map<PageType, TopNavConfiguration> = new Map([

  ['AUTH', {

    navbar: {
      logo: 'navigation.lp-r-navbar-logo',
      signup: 'navigation.lp-r-navbar-signup',
      login: 'navigation.lp-r-navbar-login',
      color: '#5b8c2a',
      logout: 'navigation.lp-r-navbar-logout',
      dashboard: 'navigation.lp-r-navbar-dashboard',
      configurator: 'navigation.lp-r-navbar-configurator',
      presentation: 'navigation.lp-r-navbar-presentation',
    },
    navTitle: [{ name: 'navigation.lp-r-navbar-title1', link: 'home' }, { name: 'navigation.lp-r-navbar-title5', link: 'aboutus' }],
    toggleMenu: false,
    toLetRestaurantEnter: true,
    whatIsParam: {
      title: 'whatIsSection.lp-r-what-section-title',
      btn: 'navigation.lp-r-avatar-restaurant-btn',
      img: 'assets/img/qr-menu/ChoosePage/cook2.png', /* lp-client - 'assets/img/qr-menu/Choose Page/client2.png' */
      par: 'whatIsSection.lp-r-what-section-par',
      rowDirection: 'row-reverse',  /* lp-client - row-reverse */
      txtColor: '#5b8c2a', /* lp-client - '#F25116' */
      classBanner: 'avatar-banner-r',   /* lp-client - "-l" */
      avatar: 'avatar-r',   /* lp-client - "-l" */
      classBtn: 'avatar-btn-r', /* lp-client - "-l" */
      animationContent: 'fade-left',
    },
    avatarStyle: {
      background: 'radial-gradient(103.87% 103.87% at 50% 50%, #ffda17 0%, #5b8c2a 100%)',
      border: '8px solid #d3d930',
      color: '#f96332'
    },
    dirtyMenuBanner: 'avatar-circle-responsive',
    dirtyMenuClasses: 'avatar-banner-r-responsive',
    bannerBackground: '#d3d930',
    dirtyMenuClassClosing: 'avatar-banner-r-responsive res-closing-menu',
    dirtyMenuClassOpened: 'avatar-banner-r-responsive-opened',
    dirtyMenuBannerClosing: 'avatar-circle-responsive avatar-restaurant-closed',
    dirtyMenuBannerOpening: 'avatar-circle-responsive-opened avatar-res-circle-responsive-opened',
    marginAvatarMobile: '2rem',
    navListWrapperOpenTop: '20px',
    resCircleOpen: '22rem',
    topCliCircleOpen: '5rem',
    topResCircleOpen: '21rem'
  }],

  ['RESTAURANT', {
    navbar: {
      logo: 'navigation.lp-r-navbar-logo',
      signup: 'navigation.lp-r-navbar-signup',
      login: 'navigation.lp-r-navbar-login',
      color: '#5b8c2a',
      logout: 'navigation.lp-r-navbar-logout',
      dashboard: 'navigation.lp-r-navbar-dashboard',
      configurator: 'navigation.lp-r-navbar-configurator',
      presentation: 'navigation.lp-r-navbar-presentation',
    },
    bannerBackground: '#d3d930',
    toLetRestaurantEnter: true,
    avatarStyle: {
      background: 'radial-gradient(103.87% 103.87% at 50% 50%, #ffda17 0%, #5b8c2a 100%)',
      border: '8px solid #d3d930',
      color: '#f96332'
    },
    navTitle: [{ name: 'navigation.lp-r-navbar-title2', link: 'cosa-fa' }, { name: 'navigation.lp-r-navbar-title3', link: 'come-funziona' }, { name: 'navigation.lp-r-navbar-title4', link: 'piani' }, { name: 'navigation.lp-r-navbar-title5', link: 'aboutus' }],
    whatIsParam: {
      title: 'whatIsSection.lp-r-what-section-title',
      btn: 'navigation.lp-r-avatar-restaurant-btn',
      img: 'assets/img/qr-menu/ChoosePage/cook2.png', /* lp-client - 'assets/img/qr-menu/Choose Page/client2.png' */
      par: 'whatIsSection.lp-r-what-section-par',
      rowDirection: 'row-reverse',  /* lp-client - row-reverse */
      txtColor: '#5b8c2a', /* lp-client - '#F25116' */
      classBanner: 'avatar-banner-r',   /* lp-client - "-l" */
      avatar: 'avatar-r',   /* lp-client - "-l" */
      classBtn: 'avatar-btn-r', /* lp-client - "-l" */
      animationContent: 'fade-left',
    },
    toggleMenu: false,
    dirtyMenuClasses: 'avatar-banner-r-responsive',
    dirtyMenuBanner: 'avatar-circle-responsive',
    dirtyMenuClassClosing: 'avatar-banner-r-responsive res-closing-menu',
    dirtyMenuClassOpened: 'avatar-banner-r-responsive-opened',
    dirtyMenuBannerClosing: 'avatar-circle-responsive avatar-restaurant-closed',
    dirtyMenuBannerOpening: 'avatar-circle-responsive-opened avatar-res-circle-responsive-opened',
    marginAvatarMobile: '2rem',
    navListWrapperOpenTop: '20px',
    resCircleOpen: '22rem',
    topCliCircleOpen: '15rem',
    topResCircleOpen: '26rem'
  }],

  ['CLIENT', {
    navbar: {
      logo: 'navigation.lp-r-navbar-logo',
      signup: 'navigation.lp-r-navbar-signup',
      login: 'navigation.lp-r-navbar-login',
      color: '#F25116',
      logout: 'navigation.lp-r-navbar-logout',
      dashboard: 'navigation.lp-r-navbar-dashboard',
      configurator: 'navigation.lp-r-navbar-configurator',
      presentation: 'navigation.lp-r-navbar-presentation',
    },
    bannerBackground: '#ff8551',
    avatarStyle: {
      background: 'radial-gradient(96.13% 96.13% at 50% 50%, #ffda17 0%, #f25116 100%)',
      border: '8px solid #ff8551',
      color: '#f96332'
    },
    navTitle: [{ name: 'navigation.lp-r-navbar-title2', link: 'cosa-fa' }, { name: 'navigation.lp-r-navbar-title3', link: 'come-funziona' }, { name: 'navigation.lp-r-navbar-title5', link: 'aboutus' }],
    whatIsParam: {
      title: 'whatIsSection.lp-r-what-section-title',
      btn: 'navigation.lp-r-avatar-restaurant-btn',
      img: 'assets/img/qr-menu/ChoosePage/client2.png' /* lp-client - 'assets/img/qr-menu/Choose Page/client2.png' */,
      par: 'whatIsSection.lp-c-what-section-par',
      rowDirection: 'row' /* lp-client - row-reverse */,
      txtColor: '#F25116' /* lp-client - '#F25116' */,
      classBanner: 'avatar-banner-l' /* lp-client - "-l" */,
      avatar: 'avatar-l' /* lp-client - "-l" */,
      classBtn: 'avatar-btn-l' /* lp-client - "-l" */,
      animationContent: 'fade-right'
    },
    toggleMenu: false,
    toLetRestaurantEnter: false,
    dirtyMenuClasses: 'avatar-banner-r-responsive',
    dirtyMenuBanner: 'avatar-circle-responsive',
    dirtyMenuClassClosing: 'avatar-banner-r-responsive cli-closing-menu',
    dirtyMenuClassOpened: 'avatar-banner-r-responsive-opened',
    dirtyMenuBannerClosing: 'avatar-circle-responsive avatar-client-closed',
    dirtyMenuBannerOpening: 'avatar-circle-responsive-opened avatar-cli-circle-responsive-opened',
    marginAvatarMobile: '2rem',
    navListWrapperOpenTop: '20px',
    resCircleOpen: '22rem',
    topCliCircleOpen: '21rem',
    topResCircleOpen: '25rem'
  }]

]);

