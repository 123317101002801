import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductService } from '../../providers/services/product.service';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { MenuActions, ProductActions, ProductImageActions, SystemActions } from '../actions';
import { SystemNotification } from 'src/app/utils/system-notification.class';
import { AppState } from '..';
import { RouterActions } from 'src/app/router/store';

@Injectable()
export class ProductEffects {
  constructor(private actions$: Actions, private productService: ProductService, private store$: Store<AppState>) {}

  getProductsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProducts),
      withLatestFrom(this.store$),
      switchMap(([{ limit, offset }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.productService.getProducts(storeState.restaurantStore.restaurant.id, limit, offset).pipe(
            map(({ products, totalProducts }) => ProductActions.getProductsSuccess({ products, totalProducts })),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[getProductsEffect$ - getProductsEffect] error: ${JSON.stringify(err)}`);
              }
              return of(ProductActions.getProductsFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(ProductActions.getProductsFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  getProductSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductsSuccess),
      map(() => SystemActions.SetNotification(new SystemNotification({ message: 'products-get-success' })))
    )
  );

  getProductFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductsFailed),
      map(({ error }) => {
        if (error.message === 'NOT_FOUND') {
          return ProductActions.getProductsSuccess({ products: [], totalProducts: 0 });
        }
        return SystemActions.SetNotification(new SystemNotification({ error, message: 'products-get-failed' }));
      })
    )
  );

  createProductEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.createProduct),
      withLatestFrom(this.store$),
      switchMap(([{ product, images }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.productService.createProduct(storeState.restaurantStore.restaurant.id, product).pipe(
            switchMap(({ productid: productId }) => {
              const actions: Array<any> = [ProductActions.createProductSuccess(), ProductActions.getProducts({ limit: 20, offset: 0 })];
              if (images?.length) {
                actions.push(ProductImageActions.createProductImages({ productId, images: images }));
              }

              return actions;
            }),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[createProductEffect$ - createProductEffect] error: ${JSON.stringify(err)}`);
              }
              return of(ProductActions.createProductFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(ProductActions.createProductFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  createProductSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.createProductSuccess),
      map(() => SystemActions.SetNotification(new SystemNotification({ message: 'product-create-success' })))
    )
  );

  createProductFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.createProductFailed),
      map(({ error }) => SystemActions.SetNotification(new SystemNotification({ error, message: 'product-create-failed' })))
    )
  );

  updateProductEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.updateProduct),
      withLatestFrom(this.store$),
      switchMap(([{ productId, product, images }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.productService.updateProduct(storeState.restaurantStore.restaurant.id, productId, product).pipe(
            switchMap(() => {
              const actions: Array<any> = [ProductActions.updateProductSuccess(), ProductActions.getProducts({ limit: 20, offset: 0 })];
              if (images?.length) {
                actions.push(ProductImageActions.createProductImages({ productId, images }));
              }

              return actions;
            }),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[updateProductEffect$ - updateProductEffect] error: ${JSON.stringify(err)}`);
              }
              return of(ProductActions.updateProductFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(ProductActions.updateProductFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  updateProductSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.updateProductSuccess),
      map(() => SystemActions.SetNotification(new SystemNotification({ message: 'product-update-success' })))
    )
  );

  updateProductFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.updateProductFailed),
      map(({ error }) => SystemActions.SetNotification(new SystemNotification({ error, message: 'product-update-failed' })))
    )
  );

  deleteProductEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.deleteProduct),
      withLatestFrom(this.store$),
      switchMap(([{ productId }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.productService.deleteProduct(storeState.restaurantStore.restaurant.id, productId).pipe(
            map(() => ProductActions.deleteProductSuccess({ productId })),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[deleteProductEffect$ - deleteProductEffect] error: ${JSON.stringify(err)}`);
              }
              return of(ProductActions.deleteProductFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(ProductActions.deleteProductFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  deleteProductSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.deleteProductSuccess),
      map(() => SystemActions.SetNotification(new SystemNotification({ message: 'product-delete-success' })))
    )
  );

  deleteProductFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.deleteProductFailed),
      map(({ error }) => SystemActions.SetNotification(new SystemNotification({ error, message: 'product-delete-failed' })))
    )
  );

  associateProductEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.associateProduct),
      withLatestFrom(this.store$),
      switchMap(([{ menuId, productId }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.productService
            .associateProduct(
              storeState.restaurantStore.restaurant.id,
              productId,
              { menu_id: menuId }
            )
            .pipe(
              map(() => ProductActions.associateProductSuccess({ menuId, productId })),
              catchError((err) => {
                if (environment.env === 'DEV' || environment.env === 'INT') {
                  console.error(`[associateProductEffect$ - associateProductEffect] error: ${JSON.stringify(err)}`);
                }
                return of(ProductActions.associateProductFailed({ error: new Error(err.error.name) }));
              })
            );
        } else {
          return of(ProductActions.associateProductFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );

  associateProductSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.associateProductSuccess),
      map(() => SystemActions.SetNotification(new SystemNotification({ message: 'product-associate-success' })))
    )
  );

  associateProductFailedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.associateProductFailed),
      map(({ error }) => SystemActions.SetNotification(new SystemNotification({ error, message: 'product-associate-failed' })))
    )
  );

  getAssociatedProductsOfMenu = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.loadMenuIntoMenuBuilder),
      withLatestFrom(this.store$),
      switchMap(([{ menuId }, storeState]) => {
        if (storeState.auth.accessToken && storeState.restaurantStore.restaurant) {
          return this.productService.getProducts(storeState.restaurantStore.restaurant.id, 10000, 0, menuId).pipe(
            map(({ products }) => ProductActions.loadAssociatedProductsOfMenuSuccess({ products })),
            catchError((err) => {
              if (environment.env === 'DEV' || environment.env === 'INT') {
                console.error(`[getAssociatedProductsOfMenuEffect$ - getAssociatedProductsOfMenuEffect] error: ${JSON.stringify(err)}`);
              }
              return of(ProductActions.loadAssociatedProductsOfMenuFailed({ error: new Error(err.error.name) }));
            })
          );
        } else {
          return of(ProductActions.loadAssociatedProductsOfMenuFailed({ error: new Error('GenericError') }));
        }
      })
    )
  );


  loadAssociatedProductsOfMenuSuccessEffect$ = createEffect(() => 
    this.actions$.pipe(
      ofType(ProductActions.loadAssociatedProductsOfMenuSuccess),
      map(() => RouterActions.go({ path: ['menu-builder'] }))
    )
  )

  startLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MenuActions.loadMenuIntoMenuBuilder,
        ProductActions.associateProduct,
        ProductActions.createProduct,
        ProductActions.updateProduct,
        ProductActions.deleteProduct,
        ProductActions.getProducts
      ),
      map(() => SystemActions.StartLoading())
    )
  );

  stopLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProductActions.associateProductSuccess,
        ProductActions.associateProductFailed,
        ProductActions.loadAssociatedProductsOfMenuFailed,
        ProductActions.loadAssociatedProductsOfMenuSuccess,
        ProductActions.createProductFailed,
        ProductActions.createProductSuccess,
        ProductActions.updateProductSuccess,
        ProductActions.updateProductFailed,
        ProductActions.deleteProductSuccess,
        ProductActions.deleteProductFailed,
        ProductActions.getProductsSuccess,
        ProductActions.getProductsFailed
      ),
      map(() => SystemActions.StopLoading())
    )
  );
}
