import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderResponse, OrderCreateRequest, OrderCreateResponse } from 'src/app/model/Order';
import { environment } from 'src/environments/environment';
import packageInfo from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  getOrders(restaurantId: string, page: number, orderStatusId?: string, productCategory?: string): Observable<OrderResponse> {
    let params: HttpParams = new HttpParams();
    if (orderStatusId) {
      params = params.append('statusid', orderStatusId);
    }

    if (page !== 0) {
      params = params.append('limit', 20).append('offset', (page - 1) * 20);
    }

    if (productCategory) {
      params = params.append('categoryid', productCategory);
    }

    return this.http.get<OrderResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/orders`, { params });
  }

  createOrder(restaurantId: string, payload: OrderCreateRequest): Observable<OrderCreateResponse> {
    return this.http.post<OrderCreateResponse>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/orders`, payload);
  }

  payOrder(restaurantId: string, orderId: string): Observable<{ orderid: string }> {
    return this.http.patch<{ orderid: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/orders/${orderId}/paid`, { paid: true });
  }

  updateStatusOrders(restaurantId: string, order_ids: string[], status_id: string): Observable<{ orderid: string }> {
    return this.http.patch<{ orderid: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/orders/status`, { status_id, order_ids });
  }

  updateWaitressComment(restaurantId: string, orderId: string, waitress_comment: string): Observable<{ orderid: string }> {
    return this.http.patch<{ orderid: string }>(`${environment.backendHost}/v1/dashboard/restaurants/${restaurantId}/orders/${orderId}/waitress-comment`, {
      waitress_comment,
    });
  }
}
